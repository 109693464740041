import React from 'react';

const VariantsComponentGuide = () => {
  return (
    <div className="doc-container">
      <h1 className="doc-title">Guide to Setting Product Variants Using the Variants Component</h1>
      <p className="doc-intro">
        The <strong>Variants</strong> component in your product management UI is a powerful tool to add and customize product variants such as color, size, or custom options. This guide will help you understand how to effectively use this component to set up different product variants.
      </p>

      <h2 className="doc-step h2">Step-by-Step Guide to Setting Product Variants</h2>
      <ol className="doc-steps">
        <li className="doc-step">
          <h2>Select Variant Types</h2>
          <p>The first step is to determine which types of variants your product has. Options include <strong>Color</strong>, <strong>Size</strong>, or a <strong>Custom Variant</strong>.</p>
          <p>To select variant types, use the toggle switches labeled <strong>Color</strong>, <strong>Size</strong>, and <strong>Custom</strong>.</p>
        </li>

        <li className="doc-step">
          <h2>Enter Variant Options</h2>
          <p>Once the variant types are selected, fill in the details of each variant option:</p>
          <ul>
            <li><strong>Color</strong>: Add multiple color options by entering a name and selecting the corresponding color using a color picker.</li>
            <li><strong>Size</strong>: Enter multiple sizes separated by commas (e.g., "small, medium, large").</li>
            <li><strong>Custom Variant</strong>: Specify a custom variant title (e.g., "Edition", "Material") and provide the options as comma-separated values.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Generate Product Variants</h2>
          <p>After specifying the options for each variant type, click the <strong>Generate Variants</strong> button.</p>
          <p>The system will automatically create combinations of the selected variant types (e.g., combining colors and sizes) to generate a list of possible variants.</p>
        </li>

        <li className="doc-step">
          <h2>Modify Generated Variants</h2>
          <p>Once variants are generated, they are displayed in an accordion format. Click <strong>Expand</strong> on each variant to modify its details.</p>
          <ul>
            <li><strong>SKU</strong>: Enter a unique SKU for each variant.</li>
            <li><strong>Price</strong>: Set the price for the variant (if it differs from the base product price). If no price it set the base product price will be set as default</li>
            <li><strong>Stock</strong>: Enter the stock quantity for each variant.</li>
            <li><strong>Weight and Dimensions</strong>: Optionally, enable custom weight and dimensions for each variant. If disabled, the general product weight and dimensions will be applied.</li>
            <li><strong>Description</strong>: Provide a unique description for each variant.</li>
            <li><strong>Image</strong>: Upload a specific image for each variant using the file upload input.</li>
          </ul>
        </li>

        <li className="doc-step">
          <h2>Custom Weight and Dimensions</h2>
          <p>Toggle the checkbox to use custom weight and dimensions for each variant if they differ from the base product settings.</p>
          <p>You can specify the <strong>weight</strong> and <strong>dimensions</strong> (length, width, height) along with their respective units of measurement.</p>
        </li>

        <li className="doc-step">
          <h2>Adding Images for Variants</h2>
          <p>To add an image to a variant, click the <strong>Image</strong> upload field and select an image from your computer.</p>
          <p>The image cropper will open if cropping is needed. After cropping, the image will be assigned to the selected variant.</p>
        </li>

        <li className="doc-step">
          <h2>Remove Variant</h2>
          <p>If a particular variant is not needed, click the <strong>Remove Variant</strong> button to delete it.</p>
        </li>
      </ol>

      <div className="doc-additional">
        <h2>Example Use Cases</h2>
        <ul>
          <li><strong>Color and Size</strong>: If you have a t-shirt available in different colors and sizes, you can add colors (e.g., red, blue) and sizes (e.g., small, medium). Click <strong>Generate Variants</strong> to create all combinations.</li>
          <li><strong>Custom Material</strong>: For a product with unique editions, such as a book with different bindings, select <strong>Custom</strong> as the variant type, enter the title as "Binding", and add values like "Hardcover" and "Paperback".</li>
        </ul>
      </div>

      <div className="doc-additional">
        <h2>Important Tips</h2>
        <ul>
          <li><strong>Keep SKU Unique</strong>: Ensure that each variant has a unique SKU, as this is crucial for inventory management.</li>
          <li><strong>Default Weight and Dimensions</strong>: By default, each variant will use the base product's weight and dimensions unless custom values are enabled.</li>
          <li><strong>Custom Options</strong>: Custom options can represent anything from different editions to specialized features of a product, providing greater flexibility in creating variants.</li>
        </ul>
      </div>

      <p className="doc-intro">
        Using this <strong>Variants</strong> component will help ensure all possible versions of a product are represented effectively, making it easier for customers to find exactly what they're looking for.
      </p>
    </div>
  );
};

export default VariantsComponentGuide;

