import { createReducer } from "@reduxjs/toolkit";

// 1) Default to "Guest" instead of "User" if sessionStorage is missing "currentProfile"
const getCurrentProfile = () => sessionStorage.getItem("currentProfile") || "Guest";

const getInitialCartState = () => {
  const profileType = getCurrentProfile();
  // 2) Read from localStorage using the "cartItems_{profileType}" key
  const cartData = localStorage.getItem(`cartItems_${profileType}`);
  return {
    carts: {
      // 3) If there's stored data, parse it; otherwise, default to an empty array
      [profileType]: cartData ? JSON.parse(cartData) : [],
    },
  };
};

// 4) Build the initial state using the logic above
const initialState = getInitialCartState();

export const cartReducer = createReducer(initialState, {
  addTocart: (state, action) => {
    // action.profileType = "User", "Shop", or "Guest"
    state.carts[action.profileType] = action.payload;
  },

  removeFromCart: (state, action) => {
    const { profileType, payload } = action;
    state.carts[profileType] = payload; 
  },

  clearCart: (state, action) => {
    state.carts[action.profileType] = [];
  },
});


