import React, { useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import NotificationFeed from './NotificationFeed';
import { useSelector, useDispatch } from "react-redux";
import { loadSeller } from "../redux/actions/user";
import { setNotificationSidebarOpen } from "../state/uiSlice";

export default function NotificationSidebar() {
  const isNotificationSidebarOpen = useSelector((state) => state.ui.isNotificationSidebarOpen);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { user } = useSelector((state) => state.user);
  const seller = useSelector((state) => state.seller.seller);
  const userId = user?._id;
  const sellerId = seller?._id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSeller());
  }, [dispatch]);

  const getCurrentProfileType = () => {
    return currentProfile === 'User' ? 'User' : 'Shop';
  };

  const currentProfileType = getCurrentProfileType();
  const profileId = currentProfile === 'User' ? userId : sellerId;

  const handleClose = () => {
    dispatch(setNotificationSidebarOpen(false));
  };

  // If the sidebar is not open, don't render anything
  if (!isNotificationSidebarOpen) {
    return null;
  }

  return (
    <div 
      className="notification-sidebar-dialog" 
      // If you want an overlay effect, uncomment below:
      // style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0,0,0,0.5)' }}
      aria-hidden={!isNotificationSidebarOpen}
    >
      {/* Optional overlay div, if needed */}
      <div className="notification-sidebar-overlay" onClick={handleClose} />

      <div className="notification-sidebar-container">
        <div className="notification-sidebar-inner-container">
          <div className="notification-sidebar-panel-container">
            <div className="notification-sidebar-panel">
              <div className="notification-sidebar-panel-content">
                <div className="notification-sidebar-header">
                  <div className="notification-sidebar-header-inner">
                    <h2 className="notification-sidebar-title">Notifications</h2>
                    <div className="notification-sidebar-close-container">
                      <button
                        type="button"
                        className="notification-sidebar-close-button"
                        onClick={handleClose}
                      >
                        <span className="notification-sidebar-close-overlay" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="notification-sidebar-close-icon" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="notification-sidebar-header-spacer"></div>
                </div>
                <div className="notification-sidebar-body">
                  <NotificationFeed profileId={profileId} profileType={currentProfileType} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
}




