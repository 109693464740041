import React, { useState } from 'react';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Footer from '../Layout/Footer';


const VerificationTOS = ({ nextStep, prevStep }) => {
  const [error, setError] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);

  const handleAgree = () => {
    // Validate the checkbox
    if (!isAgreed) {
      setError('You must agree to these Verification Terms before proceeding.');
    } else {
      setError('');
      nextStep();
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="legal-docs-container">
        <h1 className="legal-docs-title">VERIFICATION TERMS OF SERVICE</h1>

        <p className="legal-docs-text">
          Effective Date: <strong>May 1, 2024</strong>
        </p>

        <p className="legal-docs-text">
          Thank you for choosing to verify your identity (“Verification”) with 
          <strong> BuzzVibe LLC</strong> (“BuzzVibe,” “we,” “us,” or “our”). By proceeding with this 
          Verification, you acknowledge and agree to the following terms (these “Verification Terms”), 
          which are incorporated into and form a part of our general 
          <a href="/terms-of-service" className="legal-docs-link"> Terms of Service</a>. Any capitalized 
          terms not defined in these Verification Terms have the meaning given to them in our general 
          Terms of Service.
        </p>

        <h2 className="legal-docs-subtitle">1. Purpose of Verification</h2>
        <p className="legal-docs-text">
          Our Verification process is designed to confirm and validate the authenticity of your identity 
          and certain personal information, including photos, social media handles, and other supporting 
          documentation you may provide (“Verification Data”). Verification is intended to provide a safer 
          environment on BuzzVibe and build trust between our users.
        </p>

        <h2 className="legal-docs-subtitle">2. Eligibility</h2>
        <p className="legal-docs-text">
          You must be at least 18 years of age to complete our Verification process. By submitting your 
          Verification Data, you represent and warrant that you meet the minimum age requirement and that 
          all the information you provide is accurate, complete, and truthful to the best of your knowledge.
        </p>

        <h2 className="legal-docs-subtitle">3. Information Collected</h2>
        <p className="legal-docs-text">
          As part of your Verification, we may collect personal information such as:
        </p>
        <ul className="legal-docs-list">
          <li>Full legal name</li>
          <li>Date of birth</li>
          <li>Photographs or selfies</li>
          <li>Government-issued identification documents (where permitted by law)</li>
          <li>Social media handles or links</li>
          <li>Other supporting information to validate your identity</li>
        </ul>
        <p className="legal-docs-text">
          We will process your personal information in accordance with our 
          <a href="/privacy-policy" className="legal-docs-link"> Privacy Policy</a>.
        </p>

        <h2 className="legal-docs-subtitle">4. Use of Verification Data</h2>
        <p className="legal-docs-text">
          Your Verification Data will be used strictly for:
        </p>
        <ul className="legal-docs-list">
          <li>Confirming your identity and preventing fraudulent activity</li>
          <li>Cross-referencing your social media or other profiles for authenticity</li>
          <li>Improving overall trust and safety on our platform</li>
        </ul>
        <p className="legal-docs-text">
          Verification does not constitute an endorsement, guarantee, or certification of your identity 
          beyond what is reasonably verifiable based on the information you provide.
        </p>

        <h2 className="legal-docs-subtitle">5. Accuracy and Responsibility</h2>
        <p className="legal-docs-text">
          You are solely responsible for ensuring that all Verification Data you provide is accurate and 
          up-to-date. Misrepresenting your identity or submitting false, altered, or misleading information 
          may result in suspension or termination of your account and potential legal consequences under 
          applicable law.
        </p>

        <h2 className="legal-docs-subtitle">6. Data Sharing and Retention</h2>
        <p className="legal-docs-text">
          We will share your Verification Data only as necessary to complete the Verification process 
          (e.g., with third-party identity verification service providers) or as otherwise described in 
          our <a href="/privacy-policy" className="legal-docs-link">Privacy Policy</a>. Your Verification 
          Data may be retained for as long as needed to provide Verification services, comply with legal 
          obligations, or enforce our agreements.
        </p>

        <h2 className="legal-docs-subtitle">7. No Guarantee of Approval</h2>
        <p className="legal-docs-text">
          Submission of Verification Data does not guarantee that your Verification request will be 
          approved or that your account will be granted any additional privileges. We reserve the right 
          to deny Verification requests at our sole discretion.
        </p>

        <h2 className="legal-docs-subtitle">8. Termination</h2>
        <p className="legal-docs-text">
          We reserve the right to discontinue or modify the Verification process at any time, and to 
          revoke your Verification status if you violate these Verification Terms, our 
          <a href="/terms-of-service" className="legal-docs-link">Terms of Service</a>, or any other 
          applicable policy or legal requirement.
        </p>

        <h2 className="legal-docs-subtitle">9. Limitation of Liability</h2>
        <p className="legal-docs-text">
          To the fullest extent permitted by law, BuzzVibe shall not be liable for any direct, indirect, 
          incidental, consequential, or punitive damages arising out of or related to the Verification 
          process, including delays or failures to verify your identity or the actions of any third party 
          involved in verification.
        </p>

        <h2 className="legal-docs-subtitle">10. Changes to These Verification Terms</h2>
        <p className="legal-docs-text">
          We may update or revise these Verification Terms at any time. We will notify you of any material 
          changes through our website or other communication methods. By continuing with or completing the 
          Verification process after changes become effective, you accept the updated Verification Terms.
        </p>

        <h2 className="legal-docs-subtitle">11. Contact Information</h2>
        <p className="legal-docs-text">
          If you have questions regarding these Verification Terms or the Verification process, please 
          contact us at <a href="mailto:info@buzzvibe.com" className="legal-docs-link">info@buzzvibe.com</a>.
        </p>

        {/* Display any validation error */}
        {error && <p className="legal-docs-error">{error}</p>}

        {/* Agreement Checkbox */}
        <div className="membership-agreement-checkbox">
          <label>
            <input
              type="checkbox"
              checked={isAgreed}
              onChange={(e) => setIsAgreed(e.target.checked)}
            />
            I agree to the Verification Terms of Service
          </label>
        </div>

        {/* Action buttons */}
        <div className="verification-tos-buttons">
          <button onClick={prevStep}>Back</button>
          <button onClick={handleAgree}>I Agree</button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VerificationTOS;


