import React, { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import ReactDOM from "react-dom"; 
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  likeOrUnlikePost,
  editPost,
  deletePost,
  resharePost,
  fetchSinglePostLikes,
  reportPost,
  fetchSinglePost,
} from '../redux/actions/post';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Box, IconButton, Typography, TextField, Button, MenuItem } from "@mui/material";
import { Popover, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import {
  ChatBubbleOutlineOutlined,
  ChatBubble
} from "@mui/icons-material";
import FlexBetween from "../components/FlexBetween";
import Friend from "../components/Friend";
import WidgetWrapper from "../components/WidgetWrapper";
import VideoPlayer from "../components/Content/VideoPlayer";
import Comments from "../components/Comments/Comments";
import AuthenticationPrompt from "../components/Profile/AuthenticationPrompt"; 
import PropTypes from 'prop-types';

const SinglePostWidgetRedux = ({
  postId,
  postUserId,
  owner,
  name,
  description,
  profileType,
  location,
  images = [],
  videos = [],
  avatar,
  likes = {},
  createdAt,
  resharedBy,
  reshareDescription,
  reshareAvatar,
  link,
  initialIsComments = false,
  initialShowReshareInput = false,
}) => {
  const [isComments, setIsComments] = useState(initialIsComments);
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [reportDescription, setReportDescription] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Access Redux states for user & seller
  const { user } = useSelector((state) => state.user);
  const seller = useSelector((state) => state.seller.activeSeller);
  const isLoggedIn = Boolean(user?._id || seller?._id);

  // Prompt states
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);

  // The rest of your local states ...
  const loggedInUserId = user?._id;
  const loggedInSellerId = seller?._id;
  const currentProfileType = sessionStorage.getItem("currentProfile");
  const currentProfileId = useMemo(
    () => (currentProfileType === "User" ? user?._id : seller?._id),
    [currentProfileType, user, seller]
  );

  const [scrollPosition, setScrollPosition] = useState(0);
  const [showReshareInput, setShowReshareInput] = useState(initialShowReshareInput);
  const [reshareInput, setReshareInput] = useState('');
  const [isReshare] = useState(!!resharedBy);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedReshareDescription, setEditedReshareDescription] = useState(reshareDescription);
  const formattedDate = useMemo(() => format(new Date(createdAt), "PPP"), [createdAt]);

  const [totalComments, setTotalComments] = useState(0);

  // Image navigation
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    setCurrentImageIndex(0);
  }, [images]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Grab single post data from Redux
  const postData = useSelector((state) => state.posts.singlePost);
  const comments = postData?.comments || [];

  const [isLiked, setIsLiked] = useState(postData?.isLikedByCurrentUser || false);
  const [likeCount, setLikeCount] = useState(postData?.likeCount || 0);

  useEffect(() => {
    setIsLiked(postData?.isLikedByCurrentUser || false);
    setLikeCount(postData?.likeCount || 0);
  }, [postData]);

  useEffect(() => {
    setTotalComments(postData?.totalComments || 0);
  }, [postData]);

  useEffect(() => {
    dispatch(fetchSinglePost(postId)).catch((error) => {
      console.error('Error fetching single post:', error.message);
      toast.error('Failed to fetch post: ' + error.message);
    });
  }, [dispatch, postId]);

  useEffect(() => {
    dispatch(fetchSinglePostLikes(postId));
  }, [dispatch, postId]);

  //-------------------------------
  //   HELPER: Require Login
  //-------------------------------
  const requireLogin = (action) => {
    if (!isLoggedIn) {
      setShowAuthPrompt(true);
      return;
    }
    action();
  };

  //-------------------------------
  //   CRUD & POST ACTIONS
  //-------------------------------
  const toggleEditMode = useCallback(
    (e) => {
      if (e) e.preventDefault();
      if (!isEditMode) {
        setScrollPosition(window.scrollY);
      } else {
        window.scrollTo(0, scrollPosition);
      }
      setIsEditMode(!isEditMode);
      if (isEditMode) {
        setEditedDescription(description);
      }
    },
    [description, isEditMode, scrollPosition]
  );

  const handleCancelReshare = () => {
    setShowReshareInput(false);
    setReshareInput('');
  };

  const handleShowReshareInput = () => {
    requireLogin(() => {
      setReshareInput('');
      setShowReshareInput(true);
    });
  };

  const handleCancelEdit = () => {
    setEditedDescription(description);
    setEditedReshareDescription(reshareDescription);
    setIsEditMode(false);
  };

  const handleLikeOrUnlikePost = () => {
    requireLogin(() => {
      const wasLiked = isLiked;
      const updatedLikeCount = wasLiked ? likeCount - 1 : likeCount + 1;
      setIsLiked(!wasLiked);
      setLikeCount(updatedLikeCount);
      dispatch(likeOrUnlikePost(postId, currentProfileType)).catch((error) => {
        // revert if error
        setIsLiked(wasLiked);
        setLikeCount(likeCount);
        toast.error('Failed to update like status: ' + error.message);
      });
    });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(editPost(postId, editedDescription, reshareDescription)).catch(() => {
      toast.error('Failed to edit post');
    });
    setIsEditMode(false);
  };

  const handleDeletePost = (e) => {
    e.preventDefault();
    dispatch(deletePost(postId)).catch(() => {
      toast.error('Failed to delete post');
    });
  };

  const handleSharePost = () => {
    const reshareData = {
      profileType: currentProfileType,
      reshareDescription: reshareInput,
    };
    dispatch(resharePost(postId, reshareData))
      .then(() => {
        setShowReshareInput(false);
        setReshareInput('');
        toast.success('Post reshared successfully');
      })
      .catch((error) => {
        toast.error('Failed to reshare post');
      });
  };

  const handleReportPost = () => {
    if (!reportReason || !reportDescription) {
      toast.error('Please provide both a reason and a description.');
      return;
    }
    const reportData = {
      postId,
      reason: reportReason,
      description: reportDescription,
    };
    dispatch(reportPost(reportData))
      .then(() => {
        setShowReportModal(false);
        setReportReason('');
        setReportDescription('');
        toast.success('Post reported successfully.');
      })
      .catch((error) => {
        toast.error('Failed to report post');
      });
  };

  //-------------------------------
  //   RENDER UTILS
  //-------------------------------
  const renderEditForm = () => (
    <form onSubmit={handleEditSubmit} style={{ width: '100%' }}>
      <textarea
        autoFocus
        className="edit-description-textarea"
        value={isReshare ? editedReshareDescription : editedDescription}
        onChange={(e) =>
          isReshare
            ? setEditedReshareDescription(e.target.value)
            : setEditedDescription(e.target.value)
        }
        rows={3}
        style={{
          width: '100%',
          background: 'none',
          border: 'none',
          fontSize: 'inherit',
          color: 'inherit',
          lineHeight: 'inherit',
          resize: 'none',
          outline: 'none',
        }}
      />
      <div className="flex justify-end space-x-2 mt-3">
        <button
          type="submit"
          className="inline-flex items-center justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-700 transition duration-200 ease-in-out"
        >
          Save Changes
        </button>
        <button
          type="button"
          onClick={handleCancelEdit}
          className="inline-flex items-center justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-400 transition duration-200 ease-in-out"
        >
          Cancel
        </button>
      </div>
    </form>
  );

  const parseHashtags = useCallback(
    (text) => {
      if (!text) return null;
      const words = text.split(' ');
      return words.map((word, index) => {
        if (word.startsWith('#')) {
          const hashtag = word.substring(1);
          return (
            <span
              key={index}
              style={{ color: 'green', cursor: 'pointer' }}
              onClick={() => navigate(`/hashtag/${hashtag}`)}
            >
              {word}{' '}
            </span>
          );
        }
        return word + ' ';
      });
    },
    [navigate]
  );

  const renderLinkPreview = (link) => {
    if (!link || !link.url) return null;
    return (
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        className="link-post-preview-link"
      >
        <Box className="link-post-preview-container">
          {link.image && (
            <img
              src={link.image}
              alt={link.title}
              className="link-post-preview-image"
            />
          )}
          <Box className="link-post-preview-content">
            <Typography
              variant="h6"
              component="div"
              className="link-post-preview-title"
            >
              {link.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className="link-post-preview-description"
            >
              {link.description}
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              className="link-post-preview-url"
            >
              {link.url}
            </Typography>
          </Box>
        </Box>
      </a>
    );
  };

  //-------------------------------
  //   PORTAL RENDERS
  //-------------------------------
  /**
   * Simple HTML/CSS-based modal for Reporting
   */
  const reportModal = showReportModal
    ? ReactDOM.createPortal(
        <div
          className="auth-modal-backdrop"
          onClick={() => setShowReportModal(false)}
        >
          <div className="auth-modal-content" onClick={(e) => e.stopPropagation()}>
            <button
              className="auth-modal-close"
              onClick={() => setShowReportModal(false)}
            >
              &times;
            </button>
            <h3>Report Post</h3>
            <TextField
              select
              label="Reason"
              value={reportReason}
              onChange={(e) => setReportReason(e.target.value)}
              fullWidth
              margin="normal"
              sx={{ marginTop: '1rem' }}
            >
              <MenuItem value="Spam">Spam</MenuItem>
              <MenuItem value="Harassment">Harassment</MenuItem>
              <MenuItem value="Inappropriate Content">
                Inappropriate Content
              </MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>

            <TextField
              label="Description"
              value={reportDescription}
              onChange={(e) => setReportDescription(e.target.value)}
              fullWidth
              multiline
              rows={4}
              margin="normal"
              sx={{ marginTop: '1rem' }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleReportPost}
              fullWidth
              sx={{ marginTop: '1rem' }}
            >
              Submit Report
            </Button>
          </div>
        </div>,
        document.body // Portal root
      )
    : null;

  /**
   * Simple HTML/CSS-based modal for AuthenticationPrompt
   */
  const authPromptModal = showAuthPrompt
    ? ReactDOM.createPortal(
        <div className="auth-modal-backdrop" onClick={() => setShowAuthPrompt(false)}>
          <div className="auth-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="auth-modal-close" onClick={() => setShowAuthPrompt(false)}>
              &times;
            </button>
            <AuthenticationPrompt />
          </div>
        </div>,
        document.body 
      )
    : null;

  //-------------------------------
  //   MAIN RENDER
  //-------------------------------
  return (
    <>
      <ToastContainer />

      <WidgetWrapper m="2rem 0" className="post-widget-wrapper">
        {/* Post Options for Owners */}
        {(loggedInUserId === owner._id || loggedInSellerId === owner._id) && (
          <Popover className="post-widget-popover">
            <Popover.Button className="post-widget-popover-button">
              <EllipsisHorizontalIcon
                className="post-widget-icon"
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="post-widget-transition-enter"
              enterFrom="post-widget-transition-enter-from"
              enterTo="post-widget-transition-enter-to"
              leave="post-widget-transition-leave"
              leaveFrom="post-widget-transition-leave-from"
              leaveTo="post-widget-transition-leave-to"
            >
              <Popover.Panel className="post-widget-popover-panel">
                <div className="post-widget-popover-content">
                  <a
                    href="#"
                    onClick={(e) => toggleEditMode(e)}
                    className="post-widget-popover-item"
                  >
                    Edit
                  </a>
                  <a
                    href="#"
                    onClick={(e) => handleDeletePost(e)}
                    className="post-widget-popover-item"
                  >
                    Delete
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}

        {/* Post Options for Non-Owners */}
        {(loggedInUserId !== owner._id && loggedInSellerId !== owner._id) && (
          <Popover className="post-widget-popover">
            <Popover.Button className="post-widget-popover-button">
              <EllipsisHorizontalIcon
                className="post-widget-icon"
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="post-widget-transition-enter"
              enterFrom="post-widget-transition-enter-from"
              enterTo="post-widget-transition-enter-to"
              leave="post-widget-transition-leave"
              leaveFrom="post-widget-transition-leave-from"
              leaveTo="post-widget-transition-leave-to"
            >
              <Popover.Panel className="post-widget-popover-panel">
                <div className="post-widget-popover-content">
                  <a
                    href="#"
                    onClick={() => setShowReportModal(true)}
                    className="post-widget-popover-item"
                  >
                    Report
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}

        {/* Reshare Information */}
        {resharedBy && (
          <div className="post-widget-reshare">
            <div className="post-widget-reshare-header">
              <img
                src={reshareAvatar || 'default-avatar-url'}
                alt={resharedBy}
                className="post-widget-reshare-avatar"
              />
              <span className="post-widget-reshare-by">
                Reshared by {resharedBy}
              </span>
            </div>
            {reshareDescription && (
              <div className="post-widget-reshare-description">
                {isEditMode && isReshare ? renderEditForm() : reshareDescription}
              </div>
            )}
          </div>
        )}

        {/* Reshare Input */}
        {showReshareInput && (
          <div className="post-widget-reshare-container">
            <textarea
              className="post-widget-reshare-textarea"
              value={reshareInput}
              onChange={(e) => setReshareInput(e.target.value)}
              placeholder="Add a description to your reshare..."
            />
            <div className="post-widget-reshare-buttons-container">
              <button
                className="post-widget-reshare-button post-widget-cancel-button"
                onClick={handleCancelReshare}
              >
                Cancel
              </button>
              <button className="post-widget-reshare-button" onClick={handleSharePost}>
                Share
              </button>
            </div>
          </div>
        )}

        {/* Post Header (Friend) */}
        <Friend
          friendId={isReshare ? postUserId : owner._id}
          friendType={profileType}
          name={owner?.name}
          subtitle={location}
          avatar={owner?.avatar?.url}
        />
        <div className="post-widget-date">{formattedDate}</div>

        {/* Post Description or Edit Form */}
        <div>
          {isEditMode && !isReshare ? (
            renderEditForm()
          ) : (
            <Typography color="#1f9c29" sx={{ mt: "1rem" }}>
              {parseHashtags(description)}
            </Typography>
          )}
        </div>

        {/* Link Preview */}
        {link && renderLinkPreview(link)}

        {/* Media Content */}
        <div className="post-widget-media-container">
          {images.length > 0 && (
            <div className="post-widget-image-gallery">
              {images.length > 1 ? (
                <div className="post-widget-slideshow-container">
                  <div className="post-widget-slideshow">
                    <button onClick={prevImage} className="post-widget-prev-button">
                      &#10094;
                    </button>
                    <LazyLoadImage
                      src={images[currentImageIndex].url}
                      alt={`Slide ${currentImageIndex + 1}`}
                      className="post-widget-slideshow-image"
                    />
                    <button onClick={nextImage} className="post-widget-next-button">
                      &#10095;
                    </button>
                  </div>
                  <div className="post-widget-thumbnails-container">
                    {images.map((image, index) => (
                      <LazyLoadImage
                        key={index}
                        src={image.url}
                        alt={`Thumbnail ${index + 1}`}
                        className={`post-widget-thumbnail-image ${
                          index === currentImageIndex ? 'active' : ''
                        }`}
                        onClick={() => setCurrentImageIndex(index)}
                        width={60}
                        height={60}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <LazyLoadImage
                  key={0}
                  width="100%"
                  height="auto"
                  alt="post image 1"
                  className="post-widget-image"
                  src={images[0].url}
                />
              )}
            </div>
          )}

          {videos.length > 0 && (
            <div className="post-widget-video-gallery">
              {videos.map((video, index) => (
                <div key={index} className="post-widget-video-container">
                  <VideoPlayer videoUrl={video.key || video.url} />
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Post Actions: Like, Comment, Share */}
        <FlexBetween mt="0.25rem">
          <FlexBetween gap="1rem">
            <FlexBetween gap="0.3rem">
              <IconButton onClick={handleLikeOrUnlikePost}>
                {isLiked ? (
                  <img
                    src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/b0d6f7b5-d727-4f1d-cda5-a1cf207eae00/public"
                    alt="Liked"
                    className="post-widget-like-icon"
                  />
                ) : (
                  <img
                    src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/1d169027-443a-4f85-8c7d-707000933f00/public"
                    alt="Not Liked"
                    className="post-widget-like-icon"
                  />
                )}
              </IconButton>
              <Typography>{likeCount}</Typography>
            </FlexBetween>

            <FlexBetween gap="0.3rem">
              <IconButton onClick={() => requireLogin(() => setIsComments(!isComments))}>
                {totalComments > 0 ? (
                  <ChatBubble style={{ color: 'white' }} />
                ) : (
                  <ChatBubbleOutlineOutlined style={{ color: 'white' }} />
                )}
              </IconButton>
              <Typography>{totalComments}</Typography>
            </FlexBetween>
          </FlexBetween>

          <IconButton onClick={handleShowReshareInput} title="Share Post">
            <img
              src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/91301ff3-393c-42b0-6a3f-f4790206be00/public"
              style={{ color: 'white' }}
              className="post-widget-like-icon"
              alt="Share"
            />
          </IconButton>
        </FlexBetween>

        {/* Comments Section */}
        {isComments && (
          <Comments
            postId={postId}
            totalComments={totalComments}
            setTotalComments={setTotalComments}
            comments={comments}
            loggedInUserId={loggedInUserId}
            loggedInSellerId={loggedInSellerId}
            commentProfileType={currentProfileType}
            currentProfileId={currentProfileId}
          />
        )}
      </WidgetWrapper>

      {/* Portals for modals */}
      {reportModal}
      {authPromptModal}
    </>
  );
};

SinglePostWidgetRedux.propTypes = {
  postId: PropTypes.string.isRequired,
  postUserId: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.shape({
      url: PropTypes.string,
    }),
    location: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  profileType: PropTypes.string.isRequired,
  location: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    })
  ),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    })
  ),
  avatar: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  resharedBy: PropTypes.string,
  reshareDescription: PropTypes.string,
  reshareAvatar: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }),
  initialIsComments: PropTypes.bool,
  initialShowReshareInput: PropTypes.bool,
};

export default React.memo(SinglePostWidgetRedux);






