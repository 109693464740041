import React from "react";

const SearchIcon = (props) => (
    <div className="explore-icon" >
     <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
    {...props}
  >
    <defs>
      <style>{"\n      .cls-1 {\n        fill: #fff;\n      }\n    "}</style>
    </defs>
    <path
      className="cls-1"
      d="M774.08,142.1c-174.52-174.52-457.46-174.52-631.98,0-174.52,174.52-174.52,457.46,0,631.98,157.79,157.79,404.21,172.9,579,45.36l228.99,228.99c27.16,27.16,71.18,27.16,98.34,0h0c27.16-27.16,27.16-71.18,0-98.34l-228.99-228.99c127.54-174.78,112.43-421.21-45.36-578.99ZM677.68,238.5c121.28,121.28,121.28,317.9,0,439.18h0c-121.28,121.28-317.9,121.28-439.18,0h0c-121.27-121.28-121.27-317.9,0-439.17h0c121.27-121.28,317.9-121.28,439.17,0h0Z"
    />
  </svg>
  </div>
);
export default SearchIcon;