import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid'; 

const CreateMemePrompt = ({ onCreate }) => {
  return (
    <div className="create-product-prompt">
      <img
        src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/21d6be78-3d83-4d55-c7e6-3878fe63be00/public"
        alt="Custom Icon"
        className="create-meme-prompt__icon"
      />
      
      <div className="create-product-prompt__button-container">
        <button
          type="button"
          className="create-product-prompt__button"
          onClick={onCreate} 
        >
          <PlusIcon aria-hidden="true" className="create-product-prompt__button-icon" />
          Create meme
        </button>
      </div>
    </div>
  );
};

export default CreateMemePrompt;

