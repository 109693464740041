import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FixedSizeList as List } from 'react-window';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { Popover, Transition } from '@headlessui/react';
import {
  postComment,
  fetchComments,
  deleteComment,
  editComment,
} from '../../redux/actions/post';
import Avatar from '../Layout/Avatar';
import axios from 'axios';
import { server } from '../../server';

const Comments = ({
  postId,
  totalComments,
  setTotalComments,
  comments = [],
  loggedInUserId,
  loggedInSellerId,
  commentProfileType,
  currentProfileId,
}) => {
  const dispatch = useDispatch();
  const [commentText, setCommentText] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [newCommentText, setNewCommentText] = useState('');
  const [currentCommentPage, setCommentCurrentPage] = useState(2);
  const [hasMoreComments, setHasMoreComments] = useState(
    comments.length < totalComments
  );
  const [newComments, setNewComments] = useState([]);
  const [likedComments, setLikedComments] = useState({});
  const { user, seller } = useSelector((state) => ({
    user: state.user,
    seller: state.seller.seller,
  }));

  useEffect(() => {
    if (comments.length > 0) {
      const initialLikedComments = {};
      comments.forEach((c) => {
        if (c._id && c.isLikedByCurrentUser) {
          initialLikedComments[c._id] = true;
        } else {
          initialLikedComments[c._id] = false;
        }
      });
      setLikedComments(initialLikedComments);
    }
  }, [comments]);

  // --- Like/unlike comment function ---
  const likeComment = async (commentId, likedBy, profileType, action) => {
    try {
      const response = await axios.patch(
        `${server}/like/comments/${commentId}/like?action=${action}`,
        { likedBy, profileType }
      );
      return response.data;
    } catch (error) {
      console.error(`Error ${action} comment:`, error);
      throw error;
    }
  };

  const handleLikeComment = async (commentId) => {
    const likedBy = currentProfileId;
    const profileType = commentProfileType;
    const isAlreadyLiked = likedComments[commentId] || false;

    const action = isAlreadyLiked ? 'unlike' : 'like';

    // Optimistically update UI
    setLikedComments((prev) => ({
      ...prev,
      [commentId]: !isAlreadyLiked,
    }));

    try {
      await likeComment(commentId, likedBy, profileType, action);
    } catch (error) {
      // Revert UI change in case of an error
      setLikedComments((prev) => ({
        ...prev,
        [commentId]: isAlreadyLiked,
      }));
      toast.error(`Failed to ${action} comment`);
    }
  };

  // --- Fetch older comments (pagination) ---
  const loadMoreComments = useCallback(() => {
    if (!hasMoreComments) return;

    const nextPage = currentCommentPage;
    dispatch(fetchComments(postId, nextPage))
      .then((response) => {
        if (!response || !response.data || !response.data.comments) {
          throw new Error('Invalid response from server');
        }
        const moreComments = response.data.comments;
        if (moreComments.length === 0) {
          setHasMoreComments(false);
        } else {
          setNewComments((prev) => [...prev, ...moreComments]);
          setCommentCurrentPage((prev) => prev + 1);
        }
      })
      .catch((error) => {
        console.error('Error loading more comments:', error);
        toast.error('Failed to load more comments');
      });
  }, [currentCommentPage, dispatch, postId, hasMoreComments]);

  // --- Post a new comment ---
  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (!commentText.trim()) {
      toast.error('Comment cannot be empty');
      return;
    }

    const newComment = {
      _id: Math.random().toString(36).substr(2, 9),
      commentText: commentText,
      profileType: commentProfileType,
      userId:
        commentProfileType === 'User'
          ? {
              _id: user.user?._id,
              name: user.user?.name,
              avatar: user.user?.avatar,
            }
          : null,
      shopId:
        commentProfileType === 'Shop'
          ? {
              _id: seller?._id,
              name: seller?.name,
              avatar: seller?.avatar,
            }
          : null,
      createdAt: new Date().toISOString(),
      likeCount: 0,
      isLikedByCurrentUser: false,
    };

    // Optimistically add to local state
    setNewComments((prev) => [...prev, newComment]);
    setCommentText('');

    // Dispatch to server
    dispatch(postComment(postId, commentText, commentProfileType))
      .then((serverComment) => {
        // Merge server response with our local optimistic comment
        const updatedComment = {
          ...newComment,
          ...serverComment,

          // If server returned userId/shopId objects, override
          userId:
            serverComment.userId && typeof serverComment.userId === 'object'
              ? serverComment.userId
              : newComment.userId,
          shopId:
            serverComment.shopId && typeof serverComment.shopId === 'object'
              ? serverComment.shopId
              : newComment.shopId,
        };

        setNewComments((prev) =>
          prev.map((c) => (c._id === newComment._id ? updatedComment : c))
        );
        setTotalComments((prevCount) => prevCount + 1);
      })
      .catch(() => {
        // Remove the optimistic comment if request fails
        setNewComments((prev) => prev.filter((c) => c._id !== newComment._id));
        toast.error('Failed to post comment');
      });
  };

  // --- Delete a comment ---
  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment(postId, commentId)).catch(() => {
      toast.error('Failed to delete comment');
    });
  };

  // --- Edit a comment ---
  const handleEditComment = (commentId, newText) => {
    dispatch(editComment(postId, commentId, newText)).catch(() => {
      toast.error('Failed to edit comment');
    });
    setEditingCommentId(null);
    setNewCommentText('');
  };

  // --- Rendering the list of comments ---
  return (
    <>
      <List
        height={Math.min((comments.length + newComments.length) * 80, 740)}
        itemCount={comments.length + newComments.length}
        itemSize={70}
        width="100%"
        className="post-widget-comments-list"
      >
        {({ index, style }) => {
          const comment =
            index < comments.length
              ? comments[index]
              : newComments[index - comments.length];

          // The server may return a field 'commenter' 
          // or it may return userId/shopId.
          // We'll check comment.commenter first:
          const directCommenter = comment?.commenter;
          
          // Or fall back to logic with shopId/userId:
          let profileType = comment?.profileType || '';
          if (!profileType) {
            profileType = comment?.shopId ? 'Shop' : 'User';
          }

          const fallbackCommenter =
            profileType === 'Shop' ? comment?.shopId : comment?.userId;

          // If directCommenter exists, use it, else fallback
          const commenter = directCommenter || fallbackCommenter;

          // Safely get name & avatar
          const commenterName = commenter?.name || 'Name';
          let commenterAvatarUrl = '/default-avatar.png';
          if (commenter) {
            // If it's a string
            if (typeof commenter.avatar === 'string') {
              commenterAvatarUrl = commenter.avatar;
            } else {
              // If it's an object: { url: "..." }
              commenterAvatarUrl = commenter.avatar?.url || '/default-avatar.png';
            }
          }

          return (
            <div
              key={comment?._id}
              style={style}
              className="post-widget-comment-container"
            >
              <div className="post-widget-comment-avatar-container">
                <img
                  className="post-widget-comment-avatar"
                  src={commenterAvatarUrl}
                  alt={commenterName}
                />
              </div>

              <div className="post-widget-comment-content">
                <span className="post-widget-comment-author">
                  {commenterName}
                </span>
                <div className="post-widget-comment-box">
                  <div className="post-widget-comment-text-box">
                    {editingCommentId === comment?._id ? (
                      <input
                        type="text"
                        className="post-widget-comment-edit-input"
                        value={newCommentText}
                        onChange={(e) => setNewCommentText(e.target.value)}
                      />
                    ) : (
                      <span className="post-widget-comment-text">
                        {comment?.commentText}
                      </span>
                    )}
                  </div>

                  {/* Like Button */}
                  <button
                    onClick={() => handleLikeComment(comment?._id)}
                    className="like-button"
                  >
                    {likedComments[comment?._id] ? (
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724845531/icon-BuzzBolt-02_ernlpk.png"
                        alt="Liked"
                        className="post-widget-like-icon"
                      />
                    ) : (
                      <img
                        src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724845557/icon-BuzzBolt-01_qhtx6n.png"
                        alt="Not Liked"
                        className="post-widget-like-icon"
                      />
                    )}
                    <span className="like-count">
                      {comment.likeCount || 0}
                    </span>
                  </button>
                </div>

                {/* Edit/Delete Options for Owner of this comment */}
                {commenter &&
                  ((comment?.profileType === 'User' &&
                    loggedInUserId === commenter._id) ||
                    (comment?.profileType === 'Shop' &&
                      loggedInSellerId === commenter._id)) && (
                    <Popover className="post-widget-comment-popover">
                      <Popover.Button>
                        <EllipsisHorizontalIcon
                          className="post-widget-comment-options-icon"
                          aria-hidden="true"
                        />
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter="post-widget-transition-enter"
                        enterFrom="post-widget-transition-enter-from"
                        enterTo="post-widget-transition-enter-to"
                        leave="post-widget-transition-leave"
                        leaveFrom="post-widget-transition-leave-from"
                        leaveTo="post-widget-transition-leave-to"
                      >
                        <Popover.Panel className="post-widget-comment-options-panel">
                          <div className="post-widget-comment-options-container">
                            <button
                              onClick={() => {
                                setEditingCommentId(comment?._id);
                                setNewCommentText(comment?.commentText);
                              }}
                              className="post-widget-comment-option-button"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDeleteComment(comment?._id)}
                              className="post-widget-comment-option-button"
                            >
                              Delete
                            </button>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  )}

                {/* Save button for editing */}
                {editingCommentId === comment?._id && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditComment(editingCommentId, newCommentText);
                    }}
                    className="post-widget-comment-save-button"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          );
        }}
      </List>

      {/* Load more button if pagination is available */}
      {hasMoreComments && (
        <button onClick={loadMoreComments} className="load-more-comments-button">
          Load More Comments
        </button>
      )}

      {/* Form for new comment */}
      <div className="post-widget-comment-input-container">
        <div className="post-widget-comment-input-flex">
          <Avatar />
          <div className="post-widget-comment-input-flex-1">
            <form
              className="post-widget-comment-input-form"
              onSubmit={handleCommentSubmit}
            >
              <textarea
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                rows={3}
                className="post-widget-comment-input"
                placeholder="Add your comment..."
              />
              <div className="post-widget-comment-submit-container">
                <button type="submit" className="post-widget-comment-submit-button">
                  POST
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comments;



