import React, { useState, useEffect } from 'react';

const CustomCalendar = ({ selectedDate, onDateChange }) => {
  const [currentDate, setCurrentDate] = useState(selectedDate || new Date());

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  // Create a range of years (e.g., from 1900 to 2100)
  const yearsRange = [];
  for (let y = 1900; y <= 2100; y++) {
    yearsRange.push(y);
  }

  /** Month & Year Navigation **/
  const goToPreviousMonth = () => {
    setCurrentDate(new Date(year, month - 1, 1));
  };
  const goToNextMonth = () => {
    setCurrentDate(new Date(year, month + 1, 1));
  };
  const goToPreviousYear = () => {
    setCurrentDate(new Date(year - 1, month, 1));
  };
  const goToNextYear = () => {
    setCurrentDate(new Date(year + 1, month, 1));
  };

  /** Handlers for changing the month or year via dropdown **/
  const handleMonthChange = (e) => {
    setCurrentDate(new Date(year, parseInt(e.target.value, 10), 1));
  };
  const handleYearChange = (e) => {
    setCurrentDate(new Date(parseInt(e.target.value, 10), month, 1));
  };

  /** Quickly jump to today's date **/
  const goToToday = () => {
    const today = new Date();
    setCurrentDate(today);
    onDateChange(today);
  };

  /** Select a specific date **/
  const selectDate = (day) => {
    const newDate = new Date(year, month, day);
    onDateChange(newDate);
  };

  /** Prepare the days in the current month view **/
  const startOfMonth = new Date(year, month, 1);
  const endOfMonth = new Date(year, month + 1, 0);
  const startDay = startOfMonth.getDay();
  const daysInMonth = endOfMonth.getDate();

  // Today's date for highlighting
  const today = new Date();
  const isTodayInCurrentMonth = 
    today.getFullYear() === year && today.getMonth() === month;

  const renderDays = () => {
    const daysArray = [];

    // Empty slots for days before the first of the month
    for (let i = 0; i < startDay; i++) {
      daysArray.push(<div key={`empty-${i}`} className="calendar-empty" />);
    }

    // Actual days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const isSelected =
        selectedDate &&
        selectedDate.getDate() === day &&
        selectedDate.getMonth() === month &&
        selectedDate.getFullYear() === year;

      const isToday = isTodayInCurrentMonth && today.getDate() === day;

      daysArray.push(
        <div
          key={day}
          className={`calendar-day 
            ${isToday ? 'today' : ''} 
            ${isSelected ? 'selected' : ''}`}
          onClick={() => selectDate(day)}
        >
          {day}
        </div>
      );
    }

    return daysArray;
  };

  return (
    <div className="custom-calendar">
      <div className="calendar-header">
        <div className="calendar-nav">
          <button 
            type="button" 
            onClick={goToPreviousYear} 
            className="nav-button"
          >
            &laquo;
          </button>
          <button 
            type="button" 
            onClick={goToPreviousMonth} 
            className="nav-button"
          >
            &lsaquo;
          </button>
        </div>

        <div className="calendar-selectors">
          {/* Month Dropdown */}
          <select
            className="calendar-select month-dropdown"
            value={month}
            onChange={handleMonthChange}
          >
            {months.map((m, idx) => (
              <option key={m} value={idx}>
                {m}
              </option>
            ))}
          </select>

          {/* Year Dropdown */}
          <select
            className="calendar-select year-dropdown"
            value={year}
            onChange={handleYearChange}
          >
            {yearsRange.map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>

        <div className="calendar-nav">
          <button 
            type="button" 
            onClick={goToNextMonth} 
            className="nav-button"
          >
            &rsaquo;
          </button>
          <button 
            type="button" 
            onClick={goToNextYear} 
            className="nav-button"
          >
            &raquo;
          </button>
        </div>
      </div>

      <div className="calendar-grid">
        {daysOfWeek.map((day) => (
          <div key={day} className="calendar-day-header">
            {day}
          </div>
        ))}
        {renderDays()}
      </div>

      <button 
        type="button" 
        className="today-button" 
        onClick={goToToday}
      >
        Today
      </button>
    </div>
  );
};

export default CustomCalendar;

