import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { switchProfile, setActiveSeller, loadSellers } from '../redux/actions/user';
import { CheckCircleIcon, UserCircleIcon, BuildingStorefrontIcon } from '@heroicons/react/24/outline'; 
import { Link, useNavigate } from "react-router-dom"; 
import { XMarkIcon } from '@heroicons/react/20/solid';
import Navbar from './Layout/Navbar';

const ProfileSwitchButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [openModal, setOpenModal] = useState(false);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const userProfile = useSelector((state) => state.user.user);
  const sellerProfiles = useSelector((state) => state.seller.sellers);

  useEffect(() => {
    dispatch(loadSellers());
  }, [dispatch]);

  useEffect(() => {
    console.log('Current Profile has changed to:', currentProfile);
  }, [currentProfile]);

  const currentProfileAvatarUrl = currentProfile === 'User' ? 
    userProfile?.avatar?.url : 
    sellerProfiles.find(seller => seller.name === currentProfile)?.avatar?.url;

  const handleSwitchProfile = (index) => {
    if (index === undefined) {
      dispatch(switchProfile());
      sessionStorage.setItem('currentProfile', 'User');
      navigate(`/my-profile/${userProfile?.handle}`);
    } else {
      const selectedSellerProfile = sellerProfiles[index];
      dispatch(setActiveSeller(selectedSellerProfile));
      sessionStorage.setItem('currentProfile', selectedSellerProfile.name);
      navigate(`/shop/preview/${selectedSellerProfile.handle}`);
    }
    setOpenModal(false);
  };

  const hasBothProfiles = userProfile && sellerProfiles.length > 0;

  const modalContent = (
    <div className="switcheroo-modal-overlay" onClick={() => setOpenModal(false)}>
      <div className="switcheroo-modal-box" onClick={(e) => e.stopPropagation()}>
        <button
          aria-label="close"
          onClick={() => setOpenModal(false)}
          className="switcheroo-close-icon"
        >
          <XMarkIcon aria-hidden="true" />
        </button>

        <h2 id="modal-modal-title">Switch Profile</h2>

        {sellerProfiles.map((seller, index) => (
          <div key={index} className="switcheroo-modal-item" onClick={() => handleSwitchProfile(index)}>
            <img src={seller.avatar.url} alt={`${seller.name} Avatar`} className="switcheroo-modal-item-image" />
            {seller.name}
            <BuildingStorefrontIcon className="switcheroo-shop-icon" />
            {currentProfile === seller.name && <CheckCircleIcon className="switcheroo-check-icon" />}
          </div>
        ))}

        <div className="switcheroo-modal-item" onClick={() => handleSwitchProfile(undefined)}>
          <img src={userProfile?.avatar?.url} alt="User Avatar" className="switcheroo-modal-item-image" />
          {userProfile?.name}
          {currentProfile === 'User' && <CheckCircleIcon className="switcheroo-check-icon" />}
        </div>
        <div className="mobile-navbar-wrapper">
          <Navbar />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {userProfile ? (
        <>
          <button onClick={() => setOpenModal(true)} className="switcheroo-avatar-button">
            <img
              src={currentProfileAvatarUrl}
              alt={`${currentProfile} Avatar`}
              className="switcheroo-current-profile-avatar"
              id="current-profile-avatar"
            />
            {hasBothProfiles && <span id="switcheroo-icon" className="switcheroo-swap-icon">↔</span>}
          </button>

          {openModal && ReactDOM.createPortal(modalContent, document.body)}
        </>
      ) : (
        <Link to="/login"> 
          <UserCircleIcon className="h-12 w-12 text-gray-400" aria-hidden="true" />
        </Link>
      )}
    </div>
  );
};

export default ProfileSwitchButton;



