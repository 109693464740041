import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import FlexBetween from "../components/FlexBetween";
import UserImage from "../components/UserImage";
import axios from 'axios';
import Cookie from "js-cookie";
import { server } from "../server";

const token = Cookie.get('token');

const Friend = ({ 
  friendId,      
  name, 
  subtitle, 
  avatar,
  friendType,    
  friends   
}) => {
  const navigate = useNavigate();
  const [profileHandle, setProfileHandle] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
   
    const fetchHandle = async () => {
      setIsLoading(true);

      try {
        if (friendType === 'Shop') {
          const res = await axios.get(`${server}/shop/get-shop-info/${friendId}`);
          if (res.data && res.data.shop) {
            setProfileHandle(res.data.shop.handle);
          } else {
            console.error('Shop data is missing in the response');
          }
        } else if (friendType === 'User') {
          const res = await axios.get(`${server}/user/user-info/${friendId}`);
          if (res.data && res.data.user) {
            setProfileHandle(res.data.user.handle);
          } else {
            console.error('User data is missing in the response');
          }
        }
      } catch (error) {
        console.error('Error fetching handle info:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // Only fetch if we actually need the handle (friendId is provided, friendType is known)
    if (friendId && friendType) {
      fetchHandle();
    }
  }, [friendId, friendType]);

  const handleProfileClick = () => {
    // Don't navigate if we're still loading the handle
    if (isLoading) return;

    if (!profileHandle) {
      console.error('Handle is not defined or still loading');
      return;
    }

    if (friendType === 'User') {
      navigate(`/profile/${profileHandle}`);
    } else if (friendType === 'Shop') {
      navigate(`/shop/${profileHandle}`);
    }
  };
  
  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        <UserImage image={avatar} size="55px" />
        <div onClick={handleProfileClick} style={{ cursor: "pointer" }}>
          <h5 className="friend-name">{name}</h5>
          <p className="friend-subtitle">{subtitle}</p>
        </div>
      </FlexBetween>
      {/* Optionally display a loading indicator if desired */}
    </FlexBetween>
  );
};

export default Friend;






