import React, { useState, useEffect } from 'react';
import ImageCropperModal from '../Image/ImageCropperModal';
import { Switch } from '@headlessui/react';
import ReactDOM from 'react-dom';
import StandardPackageSelector from '../Shipping/StandardPackageSelector';

const Variants = ({
  variants,
  setVariants,
  isVariableProduct,
  productWeight,
  productDimensions,
  weightUnitOfMeasurement,
  dimensionUnitOfMeasurement,
  reportErrors,
  formErrors,
  hasSubmitted,
}) => {
  const [selectedVariantTypes, setSelectedVariantTypes] = useState([]);

  // For color/size/custom variant generation
  const [variantOptions, setVariantOptions] = useState({
    colors: [],
    size: '',
    custom: { title: '', values: '' },
  });
  const [variantsGenerated, setVariantsGenerated] = useState(false);

  // Accordion open/close states
  const [isAccordionOpen, setIsAccordionOpen] = useState([]);

  // Image crop states
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [selectedImageForCrop, setSelectedImageForCrop] = useState(null);
  const [currentVariantIndex, setCurrentVariantIndex] = useState(null);

  // Toggle arrays for each variant
  // 1) "Use Variant Specific Weight & Dimensions" => useVariantSpecificDims
  // 2) "Use Custom Dimension Fields" => useCustomDimensionFields
  const [useVariantSpecificDims, setUseVariantSpecificDims] = useState([]);
  const [useCustomDimensionFields, setUseCustomDimensionFields] = useState([]);

  // Prevent scroll wheel from changing numeric inputs
  const preventScrollChange = (event) => {
    event.target.blur();
  };

  // -----------------------------
  // Initialize from existing variants
  // -----------------------------
  useEffect(() => {
    if (variants && variants.length > 0) {
      const types = [];
      const colorsSet = new Set();
      const sizesSet = new Set();
      const customValuesSet = new Set();
      let customTitle = '';

      // Identify which variant types are used (color, size, custom)
      variants.forEach((variant) => {
        if (variant.color) {
          if (!types.includes('color')) {
            types.push('color');
          }
          colorsSet.add(JSON.stringify({ name: variant.color, hex: variant.colorSwatch }));
        }
        if (variant.size) {
          if (!types.includes('size')) {
            types.push('size');
          }
          sizesSet.add(variant.size);
        }
        if (variant.customTitle && variant.customValue) {
          if (!types.includes('custom')) {
            types.push('custom');
          }
          customTitle = variant.customTitle;
          customValuesSet.add(variant.customValue);
        }
      });

      setSelectedVariantTypes(types);

      setVariantOptions({
        colors: Array.from(colorsSet).map((item) => JSON.parse(item)),
        size: Array.from(sizesSet).join(','), // comma-separated sizes
        custom: { title: customTitle, values: Array.from(customValuesSet).join(',') },
      });

      setVariantsGenerated(true);

      // Initialize the accordion states (collapsed by default)
      setIsAccordionOpen(variants.map(() => false));

      // For each variant, decide if it's using "variant specific dims" 
      // based on whether it already has weight/dimensions.
      const variantSpecificArray = variants.map((v) => {
        const hasCustomDims =
          (v.weight && v.weight !== '') ||
          (v.dimensions?.length && v.dimensions.length !== '') ||
          (v.dimensions?.width && v.dimensions.width !== '') ||
          (v.dimensions?.height && v.dimensions.height !== '');
        return hasCustomDims; 
      });
      setUseVariantSpecificDims(variantSpecificArray);

      // For each variant, decide if it's using "custom dimension fields" 
      // or a "standard package" if variant dims are on. 
      // We'll default to "true" if they've manually entered dims before.
      const customDimArray = variants.map((v) => {
        // Heuristic: if user previously had dimensionUnitOfMeasurement, 
        // we assume they used custom dimension fields
        return !!v.dimensionUnitOfMeasurement; 
      });
      setUseCustomDimensionFields(customDimArray);
    }
  }, [variants.length]);

  // -----------------------------
  // Validate variants if needed
  // -----------------------------
  useEffect(() => {
    if (isVariableProduct && hasSubmitted) {
      // Build array of errors
      const errors = variants
        .map((variant) => {
          const variantError = {};
          if (!variant.sku.trim()) {
            variantError.sku = 'SKU is required for variant.';
          }
          if (!variant.stock || parseInt(variant.stock) < 0) {
            variantError.stock = 'Stock must be 0 or more for variant.';
          }
          return Object.keys(variantError).length > 0 ? variantError : null;
        })
        .filter((error) => error !== null);

      reportErrors(errors);
    }
  }, [variants, isVariableProduct, reportErrors, hasSubmitted]);

  // -----------------------------
  // Variant Type Toggles (color, size, custom)
  // -----------------------------
  const handleVariantTypeToggle = (value) => {
    setSelectedVariantTypes((prev) =>
      prev.includes(value) ? prev.filter((type) => type !== value) : [...prev, value]
    );
  };

  // Renders the top-level toggle switches for color/size/custom
  const renderToggleSwitch = (label, value) => (
    <div
      className={`create-product-component-switch-group ${
        selectedVariantTypes.includes(value)
          ? 'create-product-component-switch-group-active'
          : ''
      }`}
    >
      <label className="create-product-component-switch-label">{label}</label>
      <Switch
        checked={selectedVariantTypes.includes(value)}
        onChange={() => handleVariantTypeToggle(value)}
        className={`create-product-component-switch ${
          selectedVariantTypes.includes(value)
            ? 'create-product-component-switch-active'
            : 'create-product-component-switch-inactive'
        }`}
      >
        <span
          className={`create-product-component-switch-toggle ${
            selectedVariantTypes.includes(value)
              ? 'create-product-component-switch-toggle-active'
              : 'create-product-component-switch-toggle-inactive'
          }`}
        />
      </Switch>
    </div>
  );

  // -----------------------------
  // Color Option Handlers
  // -----------------------------
  const handleColorNameChange = (index, colorName) => {
    const updatedColors = [...variantOptions.colors];
    updatedColors[index] = { ...updatedColors[index], name: colorName };
    setVariantOptions((prev) => ({ ...prev, colors: updatedColors }));
  };

  const handleColorHexChange = (index, hexValue) => {
    const updatedColors = [...variantOptions.colors];
    updatedColors[index] = { ...updatedColors[index], hex: hexValue };
    setVariantOptions((prev) => ({ ...prev, colors: updatedColors }));
  };

  const addColorOption = () => {
    setVariantOptions((prev) => ({
      ...prev,
      colors: [...prev.colors, { name: '', hex: '#ffffff' }],
    }));
  };

  // -----------------------------
  // Variant Field Changes
  // -----------------------------
  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...variants];
    if (field.includes('.')) {
      // Nested e.g. 'dimensions.length'
      const [parentField, childField] = field.split('.');
      updatedVariants[index][parentField][childField] = value;
    } else {
      updatedVariants[index][field] = value;
    }
    setVariants(updatedVariants);
  };

  // -----------------------------
  // Image Cropping
  // -----------------------------
  const handleImageChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImageForCrop(file);
      setCurrentVariantIndex(index);
      setShowImageCropper(true);
    }
  };

  const handleImageCropCompletion = (croppedImage) => {
    const updatedVariants = [...variants];
    updatedVariants[currentVariantIndex].image = {
      file: croppedImage,
      url: URL.createObjectURL(croppedImage),
    };
    setVariants(updatedVariants);
    setShowImageCropper(false);
    setSelectedImageForCrop(null);
    setCurrentVariantIndex(null);
  };

  // -----------------------------
  // Removing a variant
  // -----------------------------
  const handleRemoveVariant = (index) => {
    const updatedVariants = variants.filter((_, i) => i !== index);
    setVariants(updatedVariants);
  };

  // -----------------------------
  // Additional Options (size, custom)
  // -----------------------------
  const handleOptionsChange = (e, optionType) => {
    if (optionType === 'customTitle') {
      setVariantOptions({
        ...variantOptions,
        custom: { ...variantOptions.custom, title: e.target.value },
      });
    } else if (optionType === 'customValues') {
      setVariantOptions({
        ...variantOptions,
        custom: { ...variantOptions.custom, values: e.target.value.split(',') },
      });
    } else {
      setVariantOptions({
        ...variantOptions,
        [optionType]: e.target.value.split(','),
      });
    }
  };

  // -----------------------------
  // Generating new variants
  // -----------------------------
  const generateVariants = () => {
    if (variants && variants.length > 0) {
      if (!window.confirm('This will overwrite existing variants. Do you want to continue?')) {
        return;
      }
    }

    const { colors, size, custom } = variantOptions;
    let generatedVariants = [];

    // Helper to combine arrays
    const combinations = (a, b) => a.flatMap((d) => b.map((e) => [d, e]));

    const validColors = Array.isArray(colors) ? colors : [];
    const validSizes = Array.isArray(size) ? size : [];

    // Combine color & size if both are selected
    if (selectedVariantTypes.includes('color') && selectedVariantTypes.includes('size')) {
      generatedVariants = combinations(validColors, validSizes);
    } else if (selectedVariantTypes.includes('color')) {
      generatedVariants = validColors.map((c) => [c]);
    } else if (selectedVariantTypes.includes('size')) {
      generatedVariants = validSizes.map((s) => [s]);
    }

    // Combine any custom variant
    if (
      selectedVariantTypes.includes('custom') &&
      custom.title &&
      Array.isArray(custom.values) &&
      custom.values.length
    ) {
      const customValues = custom.values.map((v) => v.trim());

      if (generatedVariants.length > 0) {
        generatedVariants = combinations(generatedVariants, customValues).map((arr) =>
          arr.flat()
        );
      } else {
        // Only custom combos
        generatedVariants = customValues.map((val) => [val]);
      }
    }

    // Build final variant objects
    const finalVariants = generatedVariants.map((combination) => {
      let variantObj = {
        sku: '',
        price: '',
        stock: '',
        weight: '',
        weightUnitOfMeasurement: '',
        dimensionUnitOfMeasurement: '',
        dimensions: { length: '', width: '', height: '' },
        image: null,
        description: '',
      };

      if (selectedVariantTypes.includes('color')) {
        const color = combination.find((val) =>
          validColors.some((c) => c.name === val.name)
        );
        variantObj.color = color?.name;
        variantObj.colorSwatch = color?.hex || '#ffffff';
      }
      if (selectedVariantTypes.includes('size')) {
        variantObj.size = combination.find((val) => validSizes.includes(val));
      }
      if (selectedVariantTypes.includes('custom') && custom.title) {
        variantObj.customTitle = custom.title.toLowerCase();
        variantObj.customValue = combination.find((val) => custom.values.includes(val));
      }

      return variantObj;
    });

    setVariants(finalVariants);
    setVariantsGenerated(true);
  };

  // -----------------------------
  // Accordion
  // -----------------------------
  const handleAccordionToggle = (index) => {
    setIsAccordionOpen((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = !updatedState[index];
      return updatedState;
    });
  };

  // -----------------------------
  // Render
  // -----------------------------
  return (
    <>
      {isVariableProduct && (
        <div className="create-product-component-variants-section">
          <h6 className="create-product-component-variants-title">Product Variants</h6>

          {/* Variant Type Selection */}
          <div className="create-product-variant-form-group">
            <label htmlFor="variant-type" className="create-product-variant-form-label">
              Select Variant Types
            </label>
            <div className="create-product-variant-dropdown-menu">
              {renderToggleSwitch('Color', 'color')}
              {renderToggleSwitch('Size', 'size')}
              {renderToggleSwitch('Custom', 'custom')}
            </div>
          </div>

          {/* Conditionally render fields based on selected variant types */}
          {selectedVariantTypes.includes('color') && (
            <div className="create-product-component-form-group">
              <label htmlFor="variant-colors" className="create-product-component-form-label">
                Colors
              </label>
              {variantOptions.colors.map((color, index) => (
                <div key={index} className="color-swatch-picker">
                  <input
                    type="text"
                    value={color.name}
                    onChange={(e) => handleColorNameChange(index, e.target.value)}
                    className="create-product-component-form-input"
                    placeholder="Color name e.g., red"
                  />
                  <input
                    type="color"
                    value={color.hex}
                    onChange={(e) => handleColorHexChange(index, e.target.value)}
                    style={{ backgroundColor: color.hex }}
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={addColorOption}
                className="color-swatch-picker-button"
              >
                Add Color Option
              </button>
            </div>
          )}

          {selectedVariantTypes.includes('size') && (
            <div className="create-product-component-form-group">
              <label htmlFor="variant-sizes" className="create-product-component-form-label">
                Sizes (comma separated)
              </label>
              <input
                type="text"
                id="variant-sizes"
                value={variantOptions.size}
                onChange={(e) => handleOptionsChange(e, 'size')}
                className="create-product-component-form-input"
                placeholder="e.g., small, medium, large"
              />
            </div>
          )}

          {selectedVariantTypes.includes('custom') && (
            <>
              <div className="create-product-component-form-group">
                <label htmlFor="custom-variant-title" className="create-product-component-form-label">
                  Custom Variant Title
                </label>
                <input
                  type="text"
                  id="custom-variant-title"
                  value={variantOptions.custom.title}
                  onChange={(e) => handleOptionsChange(e, 'customTitle')}
                  className="create-product-component-form-input"
                  placeholder="e.g., Edition, Material"
                />
              </div>
              <div className="create-product-component-form-group">
                <label
                  htmlFor="custom-variant-values"
                  className="create-product-component-form-label"
                >
                  Custom Values (comma separated)
                </label>
                <input
                  type="text"
                  id="custom-variant-values"
                  value={variantOptions.custom.values}
                  onChange={(e) => handleOptionsChange(e, 'customValues')}
                  className="create-product-component-form-input"
                  placeholder="e.g., First Edition, Second Edition"
                />
              </div>
            </>
          )}

          <button
            type="button"
            onClick={generateVariants}
            className="create-product-component-add-variant-button"
          >
            Generate Variants
          </button>

          {/* Display existing or newly generated variants */}
          {variantsGenerated && (
            <>
              {variants.map((variant, index) => (
                <div key={index} className="create-product-component-variant-accordion">
                  <div
                    className={`create-product-component-accordion-header ${
                      isAccordionOpen[index] ? 'open' : ''
                    }`}
                    onClick={() => handleAccordionToggle(index)}
                  >
                    <div className="create-product-component-accordion-title">
                      <h6>
                        {`Variant ${index + 1} `}
                        {variant.size && `- Size: ${variant.size} `}
                        {variant.customTitle && variant.customValue && (
                          `- ${variant.customTitle}: ${variant.customValue} `
                        )}
                        {variant.color && `- Color: ${variant.color}`}
                      </h6>
                      {variant.color && (
                        <div
                          className="color-swatch-preview"
                          style={{
                            backgroundColor: variant.colorSwatch,
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            border: '1px solid #000',
                            display: 'inline-block',
                            marginLeft: '10px',
                          }}
                        />
                      )}
                    </div>
                    <button
                      type="button"
                      className="create-product-component-accordion-toggle"
                    >
                      {isAccordionOpen[index] ? 'Collapse' : 'Expand'}
                    </button>
                  </div>

                  {isAccordionOpen[index] && (
                    <div className="create-product-component-accordion-content">
                      {/* SKU Field */}
                      <div className="create-product-component-form-group">
                        <label
                          htmlFor={`variant-sku-${index}`}
                          className="create-product-component-form-label"
                        >
                          SKU <span className="create-product-component-form-required">*</span>
                        </label>
                        <input
                          type="text"
                          id={`variant-sku-${index}`}
                          name="sku"
                          data-variant-index={index}
                          value={variant.sku}
                          onChange={(e) => handleVariantChange(index, 'sku', e.target.value)}
                          className={`create-product-component-form-input ${
                            formErrors.variants && formErrors.variants[index]?.sku
                              ? 'input-error'
                              : ''
                          }`}
                          placeholder="Enter variant SKU..."
                        />
                        {hasSubmitted && formErrors.variants && formErrors.variants[index]?.sku && (
                          <div className="form-error-message">
                            Please provide "SKU" for variant {index + 1}.
                          </div>
                        )}
                      </div>

                      {/* Price Field */}
                      <div className="create-product-component-form-group">
                        <label
                          htmlFor={`variant-price-${index}`}
                          className="create-product-component-form-label"
                        >
                          Price
                        </label>
                        <input
                          type="number"
                          id={`variant-price-${index}`}
                          name="price"
                          data-variant-index={index}
                          value={variant.price}
                          onChange={(e) => handleVariantChange(index, 'price', e.target.value)}
                          onWheel={preventScrollChange}
                          className="create-product-component-form-input"
                          placeholder="Enter variant price..."
                        />
                      </div>

                      {/* Stock Field */}
                      <div className="create-product-component-form-group">
                        <label
                          htmlFor={`variant-stock-${index}`}
                          className="create-product-component-form-label"
                        >
                          Stock <span className="create-product-component-form-required">*</span>
                        </label>
                        <input
                          type="number"
                          id={`variant-stock-${index}`}
                          name="stock"
                          data-variant-index={index}
                          value={variant.stock}
                          onChange={(e) => handleVariantChange(index, 'stock', e.target.value)}
                          onWheel={preventScrollChange}
                          className={`create-product-component-form-input ${
                            formErrors.variants && formErrors.variants[index]?.stock
                              ? 'input-error'
                              : ''
                          }`}
                          placeholder="Enter variant stock..."
                        />
                        {hasSubmitted && formErrors.variants && formErrors.variants[index]?.stock && (
                          <div className="form-error-message">
                            Please provide "Stock" for variant {index + 1}.
                          </div>
                        )}
                      </div>

                      {/* 
                        FIRST TOGGLE: 
                        "Use Variant Specific Weight & Dimensions"
                      */}
                      <div
                        className={`create-product-component-switch-group ${
                          useVariantSpecificDims[index]
                            ? 'create-product-component-switch-group-active'
                            : ''
                        }`}
                      >
                        <label className="create-product-component-switch-label">
                          Use Variant Specific Weight & Dimensions
                        </label>
                        <Switch
                          checked={useVariantSpecificDims[index]}
                          onChange={() => {
                            const updated = [...useVariantSpecificDims];
                            updated[index] = !updated[index];
                            setUseVariantSpecificDims(updated);
                          }}
                          className={`create-product-component-switch ${
                            useVariantSpecificDims[index]
                              ? 'create-product-component-switch-active'
                              : 'create-product-component-switch-inactive'
                          }`}
                        >
                          <span
                            className={`create-product-component-switch-toggle ${
                              useVariantSpecificDims[index]
                                ? 'create-product-component-switch-toggle-active'
                                : 'create-product-component-switch-toggle-inactive'
                            }`}
                          />
                        </Switch>
                      </div>

                      {/* If "Use Variant Specific" is OFF => use parent's data */}
                      {!useVariantSpecificDims[index] && (
                        <div className="create-product-component-weight-dimensions-container">
                          <div className="create-product-component-weight-dimensions">
                            <div className="create-product-component-weight">
                              <span className="create-product-component-weight-label">
                                Weight:
                              </span>
                              <span className="create-product-component-weight-value">
                                {productWeight} {weightUnitOfMeasurement}
                              </span>
                            </div>
                            <div className="create-product-component-dimensions">
                              <span className="create-product-component-dimensions-label">
                                Dimensions:
                              </span>
                              <span className="create-product-component-dimensions-value">
                                {productDimensions.length} x {productDimensions.width} x{' '}
                                {productDimensions.height} {dimensionUnitOfMeasurement}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* If "Use Variant Specific" is ON => show second toggle + either StandardPackageSelector or custom fields */}
                      {useVariantSpecificDims[index] && (
                        <>
                          {/* 
                            SECOND TOGGLE:
                            "Use Custom Dimension Fields"
                            If OFF => show StandardPackageSelector
                            If ON => show dimension fields
                          */}
                          <div
                            className={`create-product-component-switch-group ${
                              useCustomDimensionFields[index]
                                ? 'create-product-component-switch-group-active'
                                : ''
                            }`}
                          >
                            <label className="create-product-component-switch-label">
                              Use Custom Dimension Fields
                            </label>
                            <Switch
                              checked={useCustomDimensionFields[index]}
                              onChange={() => {
                                const updated = [...useCustomDimensionFields];
                                updated[index] = !updated[index];
                                setUseCustomDimensionFields(updated);
                              }}
                              className={`create-product-component-switch ${
                                useCustomDimensionFields[index]
                                  ? 'create-product-component-switch-active'
                                  : 'create-product-component-switch-inactive'
                              }`}
                            >
                              <span
                                className={`create-product-component-switch-toggle ${
                                  useCustomDimensionFields[index]
                                    ? 'create-product-component-switch-toggle-active'
                                    : 'create-product-component-switch-toggle-inactive'
                                }`}
                              />
                            </Switch>
                          </div>

                          {/* If second toggle is OFF => show StandardPackageSelector for this variant */}
                          {!useCustomDimensionFields[index] && (
                            <StandardPackageSelector
                              // If you have variant-level carriers, pass them in
                              // or just pass the same carriers the parent has
                              carriers={[]} // or your dynamic carriers
                              setLength={(val) =>
                                handleVariantChange(index, 'dimensions.length', val)
                              }
                              setWidth={(val) =>
                                handleVariantChange(index, 'dimensions.width', val)
                              }
                              setHeight={(val) =>
                                handleVariantChange(index, 'dimensions.height', val)
                              }
                              setDimensionUnitOfMeasurement={(val) =>
                                handleVariantChange(index, 'dimensionUnitOfMeasurement', val)
                              }
                            />
                          )}

                          {/* If second toggle is ON => show the dimension fields for this variant */}
                          {useCustomDimensionFields[index] && (
                            <>
                              {/* Weight Unit of Measurement */}
                              <div className="create-product-component-form-section">
                                <label
                                  htmlFor={`variant-weight-unit-of-measurement-${index}`}
                                  className="create-product-component-form-label"
                                >
                                  Weight Unit of Measurement
                                </label>
                                <select
                                  id={`variant-weight-unit-of-measurement-${index}`}
                                  name="weightUnitOfMeasurement"
                                  data-variant-index={index}
                                  value={variant.weightUnitOfMeasurement}
                                  onChange={(e) =>
                                    handleVariantChange(
                                      index,
                                      'weightUnitOfMeasurement',
                                      e.target.value
                                    )
                                  }
                                  className="create-product-component-select"
                                >
                                  <option value="">Select Weight Unit</option>
                                  <option value="g">Grams (g)</option>
                                  <option value="lbs">Pounds (lbs)</option>
                                  <option value="kg">Kilograms (kg)</option>
                                </select>
                              </div>

                              {/* Weight Field */}
                              <div className="create-product-component-form-section">
                                <label
                                  htmlFor={`variant-weight-${index}`}
                                  className="create-product-component-form-label"
                                >
                                  Weight
                                </label>
                                <input
                                  type="number"
                                  id={`variant-weight-${index}`}
                                  name="weight"
                                  data-variant-index={index}
                                  value={variant.weight}
                                  onChange={(e) =>
                                    handleVariantChange(index, 'weight', e.target.value)
                                  }
                                  onWheel={preventScrollChange}
                                  className="create-product-component-form-input"
                                  placeholder="Enter variant weight..."
                                />
                              </div>

                              {/* Dimension Unit of Measurement */}
                              <div className="create-product-component-form-section">
                                <label
                                  htmlFor={`variant-dimension-unit-of-measurement-${index}`}
                                  className="create-product-component-form-label"
                                >
                                  Dimension Unit of Measurement
                                </label>
                                <select
                                  id={`variant-dimension-unit-of-measurement-${index}`}
                                  name="dimensionUnitOfMeasurement"
                                  data-variant-index={index}
                                  value={variant.dimensionUnitOfMeasurement}
                                  onChange={(e) =>
                                    handleVariantChange(
                                      index,
                                      'dimensionUnitOfMeasurement',
                                      e.target.value
                                    )
                                  }
                                  className="create-product-component-select"
                                >
                                  <option value="">Select Dimension Unit</option>
                                  <option value="cm">Centimeters (cm)</option>
                                  <option value="in">Inches (in)</option>
                                  <option value="m">Meters (m)</option>
                                </select>
                              </div>

                              {/* Dimension Fields */}
                              <div className="create-product-component-measurement-section">
                                <div className="create-product-component-form-section">
                                  <label
                                    htmlFor={`variant-length-${index}`}
                                    className="create-product-component-form-label"
                                  >
                                    Length
                                  </label>
                                  <input
                                    type="number"
                                    id={`variant-length-${index}`}
                                    name="length"
                                    data-variant-index={index}
                                    value={variant.dimensions.length}
                                    onChange={(e) =>
                                      handleVariantChange(
                                        index,
                                        'dimensions.length',
                                        e.target.value
                                      )
                                    }
                                    onWheel={preventScrollChange}
                                    className="create-product-component-form-input"
                                    placeholder="Enter variant length..."
                                  />
                                </div>
                                <div className="create-product-component-form-section">
                                  <label
                                    htmlFor={`variant-width-${index}`}
                                    className="create-product-component-form-label"
                                  >
                                    Width
                                  </label>
                                  <input
                                    type="number"
                                    id={`variant-width-${index}`}
                                    name="width"
                                    data-variant-index={index}
                                    value={variant.dimensions.width}
                                    onChange={(e) =>
                                      handleVariantChange(
                                        index,
                                        'dimensions.width',
                                        e.target.value
                                      )
                                    }
                                    onWheel={preventScrollChange}
                                    className="create-product-component-form-input"
                                    placeholder="Enter variant width..."
                                  />
                                </div>
                                <div className="create-product-component-form-section">
                                  <label
                                    htmlFor={`variant-height-${index}`}
                                    className="create-product-component-form-label"
                                  >
                                    Height
                                  </label>
                                  <input
                                    type="number"
                                    id={`variant-height-${index}`}
                                    name="height"
                                    data-variant-index={index}
                                    value={variant.dimensions.height}
                                    onChange={(e) =>
                                      handleVariantChange(
                                        index,
                                        'dimensions.height',
                                        e.target.value
                                      )
                                    }
                                    onWheel={preventScrollChange}
                                    className="create-product-component-form-input"
                                    placeholder="Enter variant height..."
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {/* Description Field */}
                      <div className="create-product-component-form-group">
                        <label
                          htmlFor={`variant-description-${index}`}
                          className="create-product-component-form-label"
                        >
                          Description
                        </label>
                        <textarea
                          id={`variant-description-${index}`}
                          name="description"
                          data-variant-index={index}
                          value={variant.description}
                          onChange={(e) =>
                            handleVariantChange(index, 'description', e.target.value)
                          }
                          className="create-product-component-form-textarea"
                          placeholder="Enter variant description..."
                        />
                      </div>

                      {/* Image Upload Field */}
                      <div className="create-product-component-form-group">
                        <label
                          htmlFor={`variant-image-${index}`}
                          className="create-product-component-form-label"
                        >
                          Image
                        </label>
                        <input
                          type="file"
                          id={`variant-image-${index}`}
                          name="image"
                          data-variant-index={index}
                          onChange={(e) => handleImageChange(index, e)}
                          className="create-product-component-form-input"
                          accept="image/*"
                        />
                        {variant.image && (
                          <div className="create-product-component-variant-image-preview">
                            <img
                              src={
                                variant.image.url
                                  ? variant.image.url
                                  : URL.createObjectURL(variant.image.file)
                              }
                              alt="Variant"
                              className="create-product-component-variant-image"
                            />
                            <button
                              type="button"
                              onClick={() => handleVariantChange(index, 'image', null)}
                              className="create-product-component-remove-variant-image"
                            >
                              Remove Image
                            </button>
                          </div>
                        )}
                      </div>

                      {/* Remove Variant Button */}
                      <button
                        type="button"
                        onClick={() => handleRemoveVariant(index)}
                        className="create-product-component-remove-variant"
                      >
                        Remove Variant
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}

          {/* Image Cropper Modal if user is cropping an image */}
          {showImageCropper &&
            selectedImageForCrop &&
            ReactDOM.createPortal(
              <ImageCropperModal
                updateAvatar={handleImageCropCompletion}
                closeModal={() => {
                  setShowImageCropper(false);
                  setSelectedImageForCrop(null);
                  setCurrentVariantIndex(null);
                }}
                initialImage={selectedImageForCrop}
                aspectRatio={1}
                minWidth={400}
                isAvatar={false}
                isPost={false}
                isLogo={false}
              />,
              document.body
            )}
        </div>
      )}
    </>
  );
};

export default Variants;



