import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import MemeGallery from '../Meme/MemeGallery';
import MemeCreator from '../Meme/MemeCreator';
import { server } from '../../server';
import LifeEvents from '../LifeEvent/LifeEvents';
import CreateMemePrompt from '../Meme/CreateMemePrompt';


const MyContentCarousel = ({ ownerId, profileType }) => { 
  const items = [
    
    {
      type: 'meme', 
    },
    {
      type: 'lifeEvent', 
    },
    {
      type: 'video',
      videoSrc: "https://player.vimeo.com/video/1003216849?autoplay=0&loop=1&title=0&byline=0&portrait=0"
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [memes, setMemes] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch memes
  const fetchMemes = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${server}/meme/user-memes`, {
        params: { ownerId, profileType }, 
        withCredentials: true,
      });
      setMemes(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching memes:', error);
      setError('Failed to fetch memes. Please try again.');
      setLoading(false);
    }
  };

  // Fetch memes on component mount and when ownerId or profileType changes
  useEffect(() => {
    if (ownerId && profileType) {
      fetchMemes();
    }
  }, [ownerId, profileType]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    fetchMemes(); // Refetch memes in case a new meme was created
  };

  // Determine if a meme exists
  const hasMeme = memes.length > 0;

  // Adjust font size based on image width
  const adjustFontSize = useCallback((img, meme) => {
    if (img) {
      const fontSizeInPixels = (meme.fontSize / 100) * img.offsetWidth;
      if (img.nextSibling) {
        img.nextSibling.style.fontSize = `${fontSizeInPixels}px`;
      }
    }
  }, []);

  // Handle loading and error states
  if (loading) {
    return <div className="carousel-loading">Loading content...</div>;
  }

  if (error) {
    return <div className="carousel-error">{error}</div>;
  }

  return (
    <div className="my-content-carousel-container">
      {/* Carousel Controls */}
      <button onClick={handlePrev} className="my-content-carousel-control left">
        &#10094;
      </button>
      <div className="my-content-carousel-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {items.map((item, index) => (
          <div
            key={index}
            className={`my-content-carousel-item ${index === currentIndex ? 'active' : ''}`}
          >
            {/* Video Slide */}
            {item.type === 'video' ? (
              <div className="my-content-carousel-video-container">
                <iframe 
                  src={item.videoSrc}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title="Video"
                ></iframe>
                 <div className="streaming-coming-soon-overlay">
                 <span>Streaming <br /> Coming Soon</span>
                </div>
              </div>
            ) : item.type === 'meme' ? (
              /* Meme Slide */
              <div className="meme-slide">
                {hasMeme ? (
                  <>
                    <MemeGallery memes={memes} adjustFontSize={adjustFontSize} />
                    <div className="meme-slide-button-container">
                      <button onClick={handleOpenModal} className="meme-plus-button" aria-label="Add Meme">
                      +
                    </button>
                    </div>
                  </>
                ) : (
                  <CreateMemePrompt onCreate={handleOpenModal} />
                )}
              </div>
            ) : item.type === 'lifeEvent' ? (
              /* Life Events Slide */
              <div className="life-events-slide">
                <LifeEvents ownerId={ownerId} profileType={profileType} />
              </div>
            ) : (
              /* Image Slide */
              <div 
                className="my-content-carousel-image-container"
                style={{
                  backgroundImage: `url(${item.backgroundImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <div className="my-content-carousel-overlay">
                  <h2 className="my-content-carousel-title">{item.name}</h2>
                  <p className="my-content-carousel-description">{item.description}</p>
                  <a href={item.buttonLink} className="my-content-carousel-button">
                    {item.buttonText}
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <button onClick={handleNext} className="my-content-carousel-control right">
        &#10095;
      </button>

      {/* Meme Creator Modal */}
      {isModalOpen &&
        ReactDOM.createPortal(
          <div className="meme-modal" onClick={handleCloseModal}>
            <div className="meme-modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="meme-close" onClick={handleCloseModal}>
                &times;
              </span>
              <MemeCreator onClose={handleCloseModal} />
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default MyContentCarousel;








