import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { PersonAddOutlined, PersonRemoveOutlined } from "@mui/icons-material";
import {
  followUnfollow,
  fetchIsFollowing,
  fetchConnections,
} from "../../redux/actions/connections";

// -- Import your Authentication Prompt component --
import AuthenticationPrompt from "../Profile/AuthenticationPrompt";

const FollowButton = ({ connectionId, connectionType }) => {
  const dispatch = useDispatch();

  // Selectors for the current profile and authentication
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);

  // If user OR seller is logged in, we consider the user "logged in"
  const isLoggedIn = Boolean(user?._id || seller?._id);

  // Determine the ID and type of the current profile
  const currentProfileId =
    currentProfile === "User" ? user?._id : seller?._id;
  const currentProfileType = currentProfile === "User" ? "User" : "Shop";

  // Track `isFollowing` status for this connectionId
  const isFollowing = useSelector(
    (state) => state.connections.isFollowingById[connectionId]
  );

  // Local state for showing the authentication prompt
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);

  // On mount or when dependencies change, check if the current profile is following the target
  useEffect(() => {
    if (currentProfileId && connectionId) {
      dispatch(
        fetchIsFollowing(currentProfileId, connectionId, currentProfileType, connectionType)
      );
    }
  }, [dispatch, currentProfileId, connectionId, currentProfileType, connectionType]);

  /**
   * requireLogin helper
   * If not logged in, show auth prompt; otherwise execute the callback.
   */
  const requireLogin = (action) => {
    if (!isLoggedIn) {
      setShowAuthPrompt(true);
      return;
    }
    action();
  };

  /**
   * Actual follow/unfollow logic.
   * Wrapped in requireLogin so it only triggers if logged in.
   */
  const handleFollowUnfollow = () => {
    dispatch(followUnfollow(connectionId, currentProfileType, connectionType)).then(() => {
      // After follow/unfollow, recheck status
      dispatch(
        fetchIsFollowing(currentProfileId, connectionId, currentProfileType, connectionType)
      );
      dispatch(fetchConnections(connectionId, connectionType, "followers"));
    });
  };

  // -- Modal for Auth Prompt (via React portal) --
  const authPromptModal = showAuthPrompt
    ? ReactDOM.createPortal(
        <div className="auth-modal-backdrop" onClick={() => setShowAuthPrompt(false)}>
          <div
            className="auth-modal-content"
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
          >
            <AuthenticationPrompt />
          </div>
        </div>,
        document.body
      )
    : null;

  return (
    <>
      <button
        onClick={() => requireLogin(handleFollowUnfollow)}
        className={`add-friend-button-button ${isFollowing ? "following" : "follow"}`}
      >
        {isFollowing ? (
          <>
            <PersonRemoveOutlined className="icon" />
            Following
          </>
        ) : (
          <>
            <PersonAddOutlined className="icon" />
            Follow
          </>
        )}
      </button>

      {/* Render Auth Prompt Modal if user/shop is not logged in */}
      {authPromptModal}
    </>
  );
};

export default FollowButton;





