import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const UserProtectedRoute = ({ children }) => {
  // 1) Rename `id` to `handle`:
  const { handle } = useParams();
  const navigate = useNavigate();

  // 2) Get user data from Redux
  const { isLoading, user } = useSelector((state) => state.user);

  useEffect(() => {
    // Only perform the check once user data has finished loading
    if (!isLoading) {
      // 3) If there's no user or the user.handle doesn't match our URL handle, redirect
      if (!user || user.handle !== handle) {
        navigate(`/profile/${handle}`, { replace: true });
      }
    }
  }, [isLoading, user, handle, navigate]);

  // 4) While loading or if the user isn't authorized, don't render the children
  if (!user || user.handle !== handle) {
    return null; // Or a spinner, or an error component...
  }

  // 5) If the user is authorized, render the child components
  return children;
};

export default UserProtectedRoute;


