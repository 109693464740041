import React, { useState, Fragment, useCallback, useMemo, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  likeOrUnlikePost,
  editPost,
  deletePost,
  deleteComment,
  editComment,
  resharePost,
  fetchLikes,
  reportPost 
} from "../redux/actions/post";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  Modal,
  Backdrop,
  Fade,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import { Popover, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import {
  ChatBubbleOutlineOutlined,
  ChatBubble,
} from "@mui/icons-material";
import FlexBetween from "../components/FlexBetween";
import Friend from "../components/Friend";
import WidgetWrapper from "../components/WidgetWrapper";
import VideoPlayer from "../components/Content/VideoPlayer";
import Comments from "../components/Comments/Comments";

// -- Import the Authentication Prompt Component --
import AuthenticationPrompt from "../components/Profile/AuthenticationPrompt";

const PostWidgetRedux = ({
  postId,
  postUserId,
  owner,
  name,
  description,
  profileType,
  location,
  images = [],
  videos = [],
  avatar,
  likes = [],
  comments = [],
  totalComments,
  createdAt,
  onShareSuccess,
  resharedBy,
  reshareDescription,
  reshareAvatar,
  link,
  isLikedByCurrentUser,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // -- Grab user & seller from Redux to check logged-in status --
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const isLoggedIn = Boolean(user?._id || seller?._id);

  // -- Local States --
  const loggedInUserId = user?._id;
  const loggedInSellerId = seller?._id;

  const [isComments, setIsComments] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [reportDescription, setReportDescription] = useState("");

  // For sharing
  const [showReshareInput, setShowReshareInput] = useState(false);
  const [reshareInput, setReshareInput] = useState("");
  const [isReshare, setIsReshare] = useState(!!resharedBy);

  // For editing
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedReshareDescription, setEditedReshareDescription] =
    useState(reshareDescription);

  const formattedDate = format(new Date(createdAt), "PPP");

  // For comment
  const [commentText, setCommentText] = useState("");
  const [newComments, setNewComments] = useState([]);

  // For like
  const [likeCount, setLikeCount] = useState(Array.isArray(likes) ? likes.length : 0);

  // Convert array of likes to see if current user has liked
  const currentProfileType = sessionStorage.getItem("currentProfile");
  const currentProfileId = useMemo(() => {
    return currentProfileType === "User" ? user?._id : seller?._id;
  }, [user, seller, currentProfileType]);

  const isInitiallyLiked = useMemo(() => {
    return (
      Array.isArray(likes) &&
      likes.some((like) => like.likedBy === currentProfileId)
    );
  }, [likes, currentProfileId]);

  const [isLiked, setIsLiked] = useState(isInitiallyLiked);
  const [totalCommentsCount, setTotalCommentsCount] = useState(totalComments);

  // For images
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    setCurrentImageIndex(0);
  }, [images]);
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // For editing scroll position
  const [scrollPosition, setScrollPosition] = useState(0);

  // -- State for showing authentication prompt --
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);

  // Scroll logic for edit mode
  const toggleEditMode = useCallback(
    (e) => {
      if (e) e.preventDefault();
      if (!isEditMode) {
        setScrollPosition(window.scrollY);
      } else {
        window.scrollTo(0, scrollPosition);
      }
      setIsEditMode(!isEditMode);
      if (isEditMode) {
        setEditedDescription(description);
      }
    },
    [description, isEditMode, scrollPosition]
  );

  const handleCancelReshare = () => {
    setShowReshareInput(false);
    setReshareInput("");
  };

  // We can scroll to the reshare input after it’s shown
  const reshareInputRef = useRef(null);
  const handleShowReshareInput = () => {
    requireLogin(() => {
      setReshareInput("");
      setShowReshareInput(true);

      // Slight delay for the UI to update before scrolling
      setTimeout(() => {
        if (reshareInputRef.current) {
          reshareInputRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
    });
  };

  const handleCancelEdit = () => {
    setEditedDescription(description);
    setEditedReshareDescription(reshareDescription);
    setIsEditMode(false);
  };

  // -- HELPER: requireLogin for any action
  const requireLogin = (action) => {
    if (!isLoggedIn) {
      setShowAuthPrompt(true);
      return;
    }
    action();
  };

  // ========== LIKE OR UNLIKE POST ==============
  const handleLikeOrUnlikePost = () => {
    requireLogin(() => {
      const wasLiked = isLiked;
      const updatedLikeCount = wasLiked ? likeCount - 1 : likeCount + 1;

      // Optimistic UI update
      setIsLiked(!wasLiked);
      setLikeCount(updatedLikeCount);

      dispatch(likeOrUnlikePost(postId, currentProfileType)).catch((error) => {
        // Revert on error
        setIsLiked(wasLiked);
        setLikeCount(likeCount);
        toast.error("Failed to update like status");
      });
    });
  };

  // ========== EDIT POST SUBMIT ==============
  const handleEditSubmit = (e) => {
    e.preventDefault();
    requireLogin(() => {
      dispatch(editPost(postId, editedDescription, reshareDescription)).catch(
        () => {
          toast.error("Failed to edit post");
        }
      );
      setIsEditMode(false);
    });
  };

  // ========== DELETE POST ==============
  const handleDeletePost = (e) => {
    e.preventDefault();
    requireLogin(() => {
      dispatch(deletePost(postId)).catch(() => {
        toast.error("Failed to delete post");
      });
    });
  };

  // ========== SHARE POST ==============
  const handleSharePost = () => {
    requireLogin(() => {
      const reshareData = {
        profileType: currentProfileType,
        reshareDescription: reshareInput,
      };
      dispatch(resharePost(postId, reshareData))
        .then(() => {
          setShowReshareInput(false);
          setReshareInput("");
          toast.success("Post reshared successfully");
        })
        .catch(() => {
          toast.error("Failed to reshare post");
        });
    });
  };

  // ========== REPORT POST ==============
  const handleReportPost = () => {
    requireLogin(() => {
      if (!reportReason || !reportDescription) {
        toast.error("Please provide both a reason and a description.");
        return;
      }
      const reportData = {
        postId,
        reason: reportReason,
        description: reportDescription,
      };
      dispatch(reportPost(reportData))
        .then(() => {
          setShowReportModal(false);
          setReportReason("");
          setReportDescription("");
          toast.success("Post reported successfully.");
        })
        .catch(() => {
          toast.error("Failed to report post");
        });
    });
  };

  // ========== RENDER EDIT FORM ==============
  const renderEditForm = () => (
    <form onSubmit={handleEditSubmit} style={{ width: "100%" }}>
      <textarea
        autoFocus
        className="edit-description-textarea"
        value={isReshare ? editedReshareDescription : editedDescription}
        onChange={(e) =>
          isReshare
            ? setEditedReshareDescription(e.target.value)
            : setEditedDescription(e.target.value)
        }
        rows={3}
        style={{
          width: "100%",
          background: "none",
          border: "none",
          fontSize: "inherit",
          color: "inherit",
          lineHeight: "inherit",
          resize: "none",
          outline: "none",
        }}
      />
      <div className="flex justify-end space-x-2 mt-3">
        <button
          type="submit"
          className="inline-flex items-center justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200 ease-in-out"
        >
          Save Changes
        </button>
        <button
          type="button"
          onClick={handleCancelEdit}
          className="inline-flex items-center justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-200 ease-in-out"
        >
          Cancel
        </button>
      </div>
    </form>
  );

  // ========== PARSE HASHTAGS ==============
  const parseHashtags = useCallback(
    (text) => {
      const words = text.split(" ");
      return words.map((word, index) => {
        if (word.startsWith("#")) {
          const hashtag = word.substring(1);
          return (
            <span
              key={index}
              style={{ color: "green", cursor: "pointer" }}
              onClick={() => navigate(`/hashtag/${hashtag}`)}
            >
              {word + " "}
            </span>
          );
        }
        return word + " ";
      });
    },
    [navigate]
  );

  // ========== RENDER LINK PREVIEW ==============
  const renderLinkPreview = (link) => {
    if (!link || !link.url) return null;
    return (
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        className="link-post-preview-link"
      >
        <Box className="link-post-preview-container">
          {link.image && (
            <img
              src={link.image}
              alt={link.title}
              className="link-post-preview-image"
            />
          )}
          <Box className="link-post-preview-content">
            <Typography
              variant="h6"
              component="div"
              className="link-post-preview-title"
            >
              {link.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className="link-post-preview-description"
            >
              {link.description}
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              className="link-post-preview-url"
            >
              {link.url}
            </Typography>
          </Box>
        </Box>
      </a>
    );
  };

  return (
    <>
      <ToastContainer />
      <WidgetWrapper m="2rem 0" className="post-widget-wrapper">
        {/* Three Dots for Edit/Report Modal */}
{isLoggedIn && (
  <Popover className="post-widget-popover">
    <Popover.Button className="post-widget-popover-button">
      <EllipsisHorizontalIcon
        className="post-widget-icon"
        aria-hidden="true"
      />
    </Popover.Button>
    <Transition
      as={Fragment}
      enter="post-widget-transition-enter"
      enterFrom="post-widget-transition-enter-from"
      enterTo="post-widget-transition-enter-to"
      leave="post-widget-transition-leave"
      leaveFrom="post-widget-transition-leave-from"
      leaveTo="post-widget-transition-leave-to"
    >
      <Popover.Panel className="post-widget-popover-panel">
        <div className="post-widget-popover-content">
          {/* Owner-specific options */}
          {loggedInUserId === owner || loggedInSellerId === owner ? (
            <>
              <a
                href="#"
                onClick={(e) => toggleEditMode(e)}
                className="post-widget-popover-item"
              >
                Edit
              </a>
              <a
                href="#"
                onClick={(e) => handleDeletePost(e)}
                className="post-widget-popover-item"
              >
                Delete
              </a>
            </>
          ) : (
            // Non-owner-specific options (e.g., report)
            <a
              href="#"
              onClick={() => setShowReportModal(true)}
              className="post-widget-popover-item"
            >
              Report
            </a>
          )}
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
)}


        {/* Reshare Information */}
        {resharedBy && (
          <div className="post-widget-reshare">
            <div className="post-widget-reshare-header">
              <img
                src={reshareAvatar || "default-avatar-url"}
                alt={resharedBy}
                className="post-widget-reshare-avatar"
              />
              <span className="post-widget-reshare-by">
                Reshared by {resharedBy}
              </span>
            </div>
            {reshareDescription && (
              <div className="post-widget-reshare-description">
                {isEditMode && isReshare ? renderEditForm() : reshareDescription}
              </div>
            )}
          </div>
        )}

        {/* Reshare Input (requires login, opened with handleShowReshareInput) */}
        {showReshareInput && (
          <div ref={reshareInputRef} className="post-widget-reshare-container">
            <textarea
              className="post-widget-reshare-textarea"
              value={reshareInput}
              onChange={(e) => setReshareInput(e.target.value)}
              placeholder="Add a description to your reshare..."
            />
            <div className="post-widget-reshare-buttons-container">
              <button
                className="post-widget-reshare-button post-widget-cancel-button"
                onClick={handleCancelReshare}
              >
                Cancel
              </button>
              <button className="post-widget-reshare-button" onClick={handleSharePost}>
                Share
              </button>
            </div>
          </div>
        )}

        {/* Post Header: Friend Component */}
        <Friend
          friendId={isReshare ? postUserId : owner?._id}
          friendType={profileType}
          name={owner?.name}
          subtitle={location}
          avatar={owner?.avatar?.url}
        />
        <div className="post-widget-date">{formattedDate}</div>

        {/* Post Description or Edit Form */}
        <div>
          {isEditMode && !isReshare ? (
            renderEditForm()
          ) : (
            <Typography color="#1f9c29" sx={{ mt: "1rem" }}>
              {parseHashtags(description)}
            </Typography>
          )}
        </div>

        {/* Link Preview if link object present */}
        {link && renderLinkPreview(link)}

        {/* Media Content: Images and Videos */}
        <div className="post-widget-media-container">
          {images.length > 0 && (
            <div className="post-widget-image-gallery">
              {images.length > 1 ? (
                <div className="post-widget-slideshow-container">
                  <div className="post-widget-slideshow">
                    <button onClick={prevImage} className="post-widget-prev-button">
                      &#10094;
                    </button>
                    <LazyLoadImage
                      src={images[currentImageIndex].url}
                      alt={`Slide ${currentImageIndex + 1}`}
                      className="post-widget-slideshow-image"
                      placeholderSrc="path/to/placeholder/image.jpg"
                    />
                    <button onClick={nextImage} className="post-widget-next-button">
                      &#10095;
                    </button>
                  </div>
                  <div className="post-widget-thumbnails-container">
                    {images.map((image, index) => (
                      <LazyLoadImage
                        key={index}
                        src={image.url}
                        alt={`Thumbnail ${index + 1}`}
                        className={`post-widget-thumbnail-image ${
                          index === currentImageIndex ? "active" : ""
                        }`}
                        onClick={() => setCurrentImageIndex(index)}
                        width={60}
                        height={60}
                        placeholderSrc="path/to/placeholder/image.jpg"
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <LazyLoadImage
                  key={0}
                  width="100%"
                  height="auto"
                  alt="post image 1"
                  className="post-widget-image"
                  src={images[0].url}
                  placeholderSrc="path/to/placeholder/image.jpg"
                />
              )}
            </div>
          )}

          {videos.length > 0 && (
            <div className="post-widget-video-gallery">
              {videos.map((video, index) => (
                <div key={index} className="post-widget-video-container">
                  <VideoPlayer videoUrl={video.key} />
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Post Actions: Like, Comment, Share */}
        <FlexBetween mt="0.25rem">
          <FlexBetween gap="1rem">
            {/* Like Button */}
            <FlexBetween gap="0.3rem">
              <IconButton
                onClick={() => {
                  handleLikeOrUnlikePost(); // Already wrapped in requireLogin
                }}
              >
                {isLiked ? (
                  <img
                    src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/b0d6f7b5-d727-4f1d-cda5-a1cf207eae00/public"
                    alt="Liked"
                    className="post-widget-like-icon"
                  />
                ) : (
                  <img
                    src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/1d169027-443a-4f85-8c7d-707000933f00/public"
                    alt="Not Liked"
                    className="post-widget-like-icon"
                  />
                )}
              </IconButton>
              <Typography>{likeCount}</Typography>
            </FlexBetween>

            {/* Comment Button */}
            <FlexBetween gap="0.3rem">
              <IconButton
                onClick={() => {
                  requireLogin(() => setIsComments(!isComments));
                }}
              >
                {comments.length + newComments.length > 0 ? (
                  <ChatBubble style={{ color: "white" }} />
                ) : (
                  <ChatBubbleOutlineOutlined style={{ color: "white" }} />
                )}
              </IconButton>
              <Typography>{totalCommentsCount}</Typography>
            </FlexBetween>
          </FlexBetween>

          {/* Share Button */}
          <IconButton onClick={handleShowReshareInput} title="Share Post">
            <img
              src="https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/91301ff3-393c-42b0-6a3f-f4790206be00/public"
              style={{ color: "white" }}
              className="post-widget-like-icon"
              alt="Share"
            />
          </IconButton>
        </FlexBetween>

        {/* Comments Section */}
        {isComments && (
          <Comments
            postId={postId}
            totalComments={totalCommentsCount}
            setTotalComments={setTotalCommentsCount}
            comments={comments}
            loggedInUserId={loggedInUserId}
            loggedInSellerId={loggedInSellerId}
            commentProfileType={currentProfileType}
            currentProfileId={currentProfileId}
          />
        )}
      </WidgetWrapper>

      {/* Report Post Modal */}
      <Modal
        open={showReportModal}
        onClose={() => setShowReportModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showReportModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "#333",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2">
              Report Post
            </Typography>
            <TextField
              select
              label="Reason"
              value={reportReason}
              onChange={(e) => setReportReason(e.target.value)}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Spam">Spam</MenuItem>
              <MenuItem value="Harassment">Harassment</MenuItem>
              <MenuItem value="Inappropriate Content">
                Inappropriate Content
              </MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
            <TextField
              label="Description"
              value={reportDescription}
              onChange={(e) => setReportDescription(e.target.value)}
              fullWidth
              multiline
              rows={4}
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleReportPost} fullWidth>
              Submit Report
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Authentication Prompt if user/seller is not logged in */}
      {showAuthPrompt &&
        ReactDOM.createPortal(
          <div className="auth-modal-backdrop" onClick={() => setShowAuthPrompt(false)}>
            <div
              className="auth-modal-content"
              onClick={(e) => e.stopPropagation()}
              role="dialog"
              aria-modal="true"
            >
              <AuthenticationPrompt />
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default React.memo(PostWidgetRedux);





