import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { FaPencilAlt } from "react-icons/fa";
import { ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import ImageCropperModal from "../Image/ImageCropperModal";
import { loadTheme, updateTheme, uploadThemeLogo } from "../../redux/actions/theme";

const AdminThemeManagement = () => {
  const dispatch = useDispatch();

  // --- Redux Data ---
  const { theme: reduxTheme, loading, error, successMessage, logoUploadLoading } = useSelector((state) => state.theme);

  // --- Local State (Draft) ---
  const [theme, setTheme] = useState({});
  const [saving, setSaving] = useState(false);
  const [localError, setLocalError] = useState(error);
  const [localSuccessMessage, setLocalSuccessMessage] = useState(successMessage);

  // --- Color Picker Modal States ---
  const [modalOpen, setModalOpen] = useState(false);
  const [modalColorKey, setModalColorKey] = useState(null);
  const [modalColorValue, setModalColorValue] = useState("#ffffff");

  // --- Logo Cropper States ---
  const [siteLogoFile, setSiteLogoFile] = useState(null);
  const [siteLogoPreview, setSiteLogoPreview] = useState(null);
  const [showLogoCropper, setShowLogoCropper] = useState(false);

  // 1) Load Theme from Redux on mount
  useEffect(() => {
    dispatch(loadTheme());
  }, [dispatch]);

  // 2) Whenever Redux theme changes, copy to local state
  useEffect(() => {
    setTheme(reduxTheme || {});
  }, [reduxTheme]);

  // 3) Update :root CSS variables whenever theme changes
  useEffect(() => {
    Object.entries(theme).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${toKebabCase(key)}`, value);
    });
  }, [theme]);

  // Helper: Convert camelCase => kebab-case
  const toKebabCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
  };

  // Helper: Check if string is 3- or 6-digit hex color
  const isHexColor = (val) => {
    if (typeof val !== "string") return false;
    return /^#([0-9A-F]{3}){1,2}$/i.test(val);
  };

  // Updated function to treat background keys the same as color keys
  const isColorOrBackgroundKey = (key, val) => {
    const lowerKey = key.toLowerCase();
    return (
      lowerKey.includes("color") ||       // e.g. "primaryColor", "textColor"
      lowerKey.includes("background") ||  // e.g. "backgroundLight", "backgroundDark"
      isHexColor(val)                     // e.g. "#fff", "#36d300"
    );
  };

  // Filter out only color-like or background-like fields
  const colorEntries = Object.entries(theme).filter(([key, val]) =>
    isColorOrBackgroundKey(key, val)
  );

  // Group them into { Light, Dark, General }
  const groupedColors = groupByThemeType(colorEntries);

  // -------------- Color Picker Handlers --------------
  const handleTextChange = (e, key) => {
    setTheme((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const openColorModal = (key) => {
    setModalColorKey(key);
    setModalColorValue(theme[key] || "#ffffff");
    setModalOpen(true);
  };

  const handleColorPickerChange = (color) => {
    setModalColorValue(color.hex);
  };

  const handleModalConfirm = () => {
    if (modalColorKey) {
      setTheme((prev) => ({ ...prev, [modalColorKey]: modalColorValue }));
    }
    closeModal();
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalColorKey(null);
  };

  // -------------- Logo Upload & Crop --------------
  const handleLogoFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSiteLogoFile(file);
      setShowLogoCropper(true);
    }
  };

  const updateSiteLogo = (croppedFile) => {
    setSiteLogoFile(croppedFile);
    const previewUrl = URL.createObjectURL(croppedFile);
    setSiteLogoPreview(previewUrl);
    setShowLogoCropper(false);
  };

  // -------------- Submit / Save Theme --------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      let siteLogoUrl = theme.siteLogoUrl || "";
      if (siteLogoFile) {
        const logoUrl = await dispatch(uploadThemeLogo(siteLogoFile));
        siteLogoUrl = logoUrl;
      }

      const updatedTheme = { ...theme, siteLogoUrl };
      await dispatch(updateTheme(updatedTheme));
      setTheme(updatedTheme);

      alert("Theme updated successfully!");
    } catch (error) {
      console.error("Error updating theme:", error);
      alert("Failed to update theme variables.");
    }
    setSaving(false);
  };

  // Sync localError & localSuccessMessage with Redux
  useEffect(() => {
    setLocalError(error);
    setLocalSuccessMessage(successMessage);
  }, [error, successMessage]);

  const handleCloseMessage = () => {
    setLocalError(null);
    setLocalSuccessMessage(null);
  };

  // -------------- Render --------------
  if (loading) {
    return <div className="admin-theme-loading">Loading theme variables...</div>;
  }

  const colorPickerPortal = modalOpen
    ? ReactDOM.createPortal(
        <div className="admin-theme-modal-overlay" onClick={closeModal}>
          <div className="admin-theme-modal" onClick={(e) => e.stopPropagation()}>
            <ChromePicker color={modalColorValue} onChange={handleColorPickerChange} />
            <div className="admin-theme-modal-buttons">
              <button type="button" className="admin-theme-modal-btn confirm" onClick={handleModalConfirm}>
                OK
              </button>
              <button type="button" className="admin-theme-modal-btn cancel" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>,
        document.body
      )
    : null;

  const logoCropperModal = showLogoCropper && (
    <ImageCropperModal
      updateAvatar={updateSiteLogo}
      closeModal={() => setShowLogoCropper(false)}
      initialImage={siteLogoFile}
      aspectRatio={3 / 1}
      minWidth={200}
      isAvatar={false}
      isPost={false}
      isLogo={true}
    />
  );

  return (
    <>
      <div className="admin-theme-container">
        <h2 className="admin-theme-title">Admin Theme Manager</h2>

        {/* Error Message */}
        {localError && (
          <div className="admin-theme-message admin-theme-message--error">
            <div className="admin-theme-message__icon">⚠️</div>
            <div className="admin-theme-message__text">{localError}</div>
            <button className="admin-theme-message__close" onClick={handleCloseMessage}>
              ✖
            </button>
          </div>
        )}

        {/* Success Message */}
        {localSuccessMessage && (
          <div className="admin-theme-message admin-theme-message--success">
            <div className="admin-theme-message__icon">✅</div>
            <div className="admin-theme-message__text">{localSuccessMessage}</div>
            <button className="admin-theme-message__close" onClick={handleCloseMessage}>
              ✖
            </button>
          </div>
        )}

        <form onSubmit={handleSubmit} className="admin-theme-form-groupings">
          {/* Site Logo */}
          <div className="admin-theme-section">
            <h3 className="admin-theme-section-title">Site Logo</h3>
            <div className="admin-theme-form single-column-layout">
              <div className="admin-logo-field">
                <label className="admin-theme-field-label" htmlFor="site-logo-input">
                  Upload Site Logo
                </label>
                <div className="admin-theme-field-input-wrapper">
                  {siteLogoPreview ? (
                    <img src={siteLogoPreview} alt="Site Logo Preview" className="admin-logo-preview-img" />
                  ) : theme.siteLogoUrl ? (
                    <img src={theme.siteLogoUrl} alt="Current Site Logo" className="admin-logo-preview-img" />
                  ) : (
                    <div className="admin-logo-placeholder">No Logo</div>
                  )}

                  <label htmlFor="site-logo-input" className="admin-theme-color-button">
                    <FaPencilAlt />
                    <input
                      type="file"
                      id="site-logo-input"
                      accept=".jpg,.jpeg,.png"
                      style={{ display: "none" }}
                      onChange={handleLogoFileChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Light Theme */}
          {groupedColors.Light.length > 0 && (
            <div className="admin-theme-section">
              <h3 className="admin-theme-section-title">Light Theme</h3>
              <div className="admin-theme-form two-column-grid">
                {groupedColors.Light.map(([key, value]) => (
                  <ColorField
                    key={key}
                    label={key}
                    value={value}
                    onTextChange={(e) => handleTextChange(e, key)}
                    onOpenPicker={() => openColorModal(key)}
                  />
                ))}
              </div>
            </div>
          )}

          {/* Dark Theme */}
          {groupedColors.Dark.length > 0 && (
            <div className="admin-theme-section">
              <h3 className="admin-theme-section-title">Dark Theme</h3>
              <div className="admin-theme-form two-column-grid">
                {groupedColors.Dark.map(([key, value]) => (
                  <ColorField
                    key={key}
                    label={key}
                    value={value}
                    onTextChange={(e) => handleTextChange(e, key)}
                    onOpenPicker={() => openColorModal(key)}
                  />
                ))}
              </div>
            </div>
          )}

          {/* General Colors */}
          {groupedColors.General.length > 0 && (
            <div className="admin-theme-section">
              <h3 className="admin-theme-section-title">General Colors</h3>
              <div className="admin-theme-form two-column-grid">
                {groupedColors.General.map(([key, value]) => (
                  <ColorField
                    key={key}
                    label={key}
                    value={value}
                    onTextChange={(e) => handleTextChange(e, key)}
                    onOpenPicker={() => openColorModal(key)}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="admin-theme-actions">
            <button type="submit" className="admin-theme-submit-btn" disabled={saving || logoUploadLoading}>
              {saving || logoUploadLoading ? "Saving..." : "Save Theme"}
            </button>
          </div>
        </form>
      </div>

      {colorPickerPortal}
      {logoCropperModal}
    </>
  );
};

export default AdminThemeManagement;

/* Reusable color field sub-component */
const ColorField = ({ label, value, onTextChange, onOpenPicker }) => {
  return (
    <div className="admin-theme-field">
      <label className="admin-theme-field-label" htmlFor={label}>
        {label}
      </label>
      <div className="admin-theme-field-input-wrapper">
        {/* Color Preview */}
        <div
          className="admin-theme-color-preview"
          style={{ backgroundColor: value }}
          title={value}
        />
        {/* Input for hex or RGBA */}
        <input
          id={label}
          type="text"
          className="admin-theme-text-input"
          value={value}
          onChange={(e) => onTextChange(e, label)}
        />
        <button
          type="button"
          className="admin-theme-color-button"
          onClick={onOpenPicker}
        >
          <FaPencilAlt />
        </button>
      </div>
    </div>
  );
};

/**
 * Group color & background fields into Light, Dark, General
 * e.g. "primaryLight", "backgroundLight" => Light
 *      "primaryDark", "backgroundDark" => Dark
 *      everything else => General
 */
function groupByThemeType(colorEntries) {
  const grouped = { Light: [], Dark: [], General: [] };

  colorEntries.forEach(([key, value]) => {
    const lowerKey = key.toLowerCase();

    if (lowerKey.includes("light")) {
      grouped.Light.push([key, value]);
    } else if (lowerKey.includes("dark")) {
      grouped.Dark.push([key, value]);
    } else {
      grouped.General.push([key, value]);
    }
  });

  return grouped;
}








