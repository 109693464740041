import React from 'react';
import { Link } from 'react-router-dom';

const AuthenticationPrompt = () => {
  return (
    <div className="authentification-prompt-container">
      <h2 className="authentification-prompt-title">Welcome to BuzzVibe!</h2>
      <p className="authentification-prompt-message">
        Sign in or sign up to connect with others, follow your favorite creators, and explore the full potential of our community. Your journey starts here!
      </p>
      <div className="authentification-prompt-buttonContainer">
        <Link to="/login" className="authentification-prompt-button">
          Sign In
        </Link>
        <Link to="/signup" className="authentification-prompt-buttonOutline">
          Sign Up
        </Link>
      </div>
    </div>
  );
};

export default AuthenticationPrompt;

