import React from "react";

// Master list of all standard package sizes
const allStandardPackageSizes = [
  // USPS
  {
    carrier: "USPS",
    name: "USPS Flat Rate Envelope",
    dimensions: { length: 12.5, width: 9.5, height: 0.75 },
    unit: "in",
    // No maxWeight needed
  },
  {
    carrier: "USPS",
    name: "USPS Legal Flat Rate Envelope",
    dimensions: { length: 15, width: 9.5, height: 0.75 },
    unit: "in",
  },
  {
    carrier: "USPS",
    name: "USPS Padded Flat Rate Envelope",
    dimensions: { length: 12.5, width: 9.5, height: 1 },
    unit: "in",
  },
  {
    carrier: "USPS",
    name: "USPS Small Flat Rate Box",
    dimensions: { length: 8.625, width: 5.375, height: 1.625 },
    unit: "in",
  },
  {
    carrier: "USPS",
    name: "USPS Medium Flat Rate Box (Top-Loading)",
    dimensions: { length: 13.625, width: 11.875, height: 3.375 },
    unit: "in",
  },
  {
    carrier: "USPS",
    name: "USPS Medium Flat Rate Box (Side-Loading)",
    dimensions: { length: 11, width: 8.5, height: 5.5 },
    unit: "in",
  },
  {
    carrier: "USPS",
    name: "USPS Large Flat Rate Box",
    dimensions: { length: 12.25, width: 12.25, height: 6 },
    unit: "in",
  },

 // UPS Simple Rate
{
  carrier: "UPS",
  name: "UPS Simple Rate Extra Small Box",
  dimensions: { length: 4, width: 4, height: 4 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Extra Small Box",
  dimensions: { length: 6, width: 4, height: 4 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Extra Small Box",
  dimensions: { length: 8, width: 6, height: 2 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Small Box",
  dimensions: { length: 6, width: 6, height: 6 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Small Box",
  dimensions: { length: 8, width: 6, height: 5 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Small Box",
  dimensions: { length: 12, width: 9, height: 2 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Medium Box",
  dimensions: { length: 8, width: 8, height: 8 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Medium Box",
  dimensions: { length: 12, width: 9, height: 6 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Medium Box",
  dimensions: { length: 13, width: 11, height: 2 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Large Box",
  dimensions: { length: 10, width: 10, height: 10 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Large Box",
  dimensions: { length: 12, width: 12, height: 7 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Large Box",
  dimensions: { length: 15, width: 11, height: 6 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Extra Large Box",
  dimensions: { length: 16, width: 16, height: 16 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Extra Large Box",
  dimensions: { length: 16, width: 12, height: 9 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Simple Rate Extra Large Box",
  dimensions: { length: 18, width: 12, height: 6 },
  unit: "in",
},


{
  carrier: "UPS",
  name: "UPS Tube",
  dimensions: { length: 38, width: 6, height: 6 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS 10kg Box",
  dimensions: { length: 16.5, width: 13.25, height: 10.75 },
  unit: "in",
  maxWeight: 10,
  maxWeightUnit: "kg",
},
{
  carrier: "UPS",
  name: "UPS 25kg Box",
  dimensions: { length: 19.375, width: 17.375, height: 14 },
  unit: "in",
  maxWeight: 25,
  maxWeightUnit: "kg",
},
{
  carrier: "UPS",
  name: "UPS Express Envelope",
  dimensions: { length: 12.5, width: 9.5, height: 0.75 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Pak",
  dimensions: { length: 16, width: 12, height: 2 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Express Box Small",
  dimensions: { length: 13, width: 11, height: 2 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Express Box Medium",
  dimensions: { length: 16, width: 11, height: 3 },
  unit: "in",
},
{
  carrier: "UPS",
  name: "UPS Express Box Large",
  dimensions: { length: 18, width: 13, height: 3 },
  unit: "in",
},


  // FedEx
  {
    carrier: "FedEx",
    name: "FedEx Envelope",
    dimensions: { length: 12.5, width: 9.5, height: 0.8 },
    unit: "in",
  },
  {
    carrier: "FedEx",
    name: "FedEx Pak",
    dimensions: { length: 15.5, width: 12, height: 2 },
    unit: "in",
  },
  {
    carrier: "FedEx",
    name: "FedEx Small Box",
    dimensions: { length: 12.375, width: 10.875, height: 1.5 },
    unit: "in",
  },
  {
    carrier: "FedEx",
    name: "FedEx Medium Box",
    dimensions: { length: 13.25, width: 11.5, height: 2.38 },
    unit: "in",
  },
  {
    carrier: "FedEx",
    name: "FedEx Large Box",
    dimensions: { length: 17.5, width: 12.38, height: 3 },
    unit: "in",
  },

  // DHL
  {
    carrier: "DHL",
    name: "DHL Express Document Envelope",
    dimensions: { length: 12.5, width: 9.5, height: 0.75 },
    unit: "in",
  },
  {
    carrier: "DHL",
    name: "DHL Express Flyer (Small)",
    dimensions: { length: 13, width: 10, height: 2 },
    unit: "in",
  },
  {
    carrier: "DHL",
    name: "DHL Express Flyer (Large)",
    dimensions: { length: 17, width: 13, height: 2 },
    unit: "in",
  },
  {
    carrier: "DHL",
    name: "DHL Express Box 2 (Small)",
    dimensions: { length: 13.39, width: 11.42, height: 2.36 },
    unit: "in",
  },
  {
    carrier: "DHL",
    name: "DHL Express Box 3 (Medium)",
    dimensions: { length: 12.99, width: 12.6, height: 3.15 },
    unit: "in",
  },
  {
    carrier: "DHL",
    name: "DHL Express Box 4 (Large)",
    dimensions: { length: 18.11, width: 12.99, height: 3.94 },
    unit: "in",
  },
  {
    carrier: "DHL",
    name: "DHL Express Box 5 (Extra Large)",
    dimensions: { length: 18.11, width: 12.99, height: 7.87 },
    unit: "in",
  },
  {
    carrier: "DHL",
    name: "DHL Express Jumbo Junior Box",
    dimensions: { length: 13.39, width: 11.42, height: 10.24 },
    unit: "in",
  },
  {
    carrier: "DHL",
    name: "DHL Express Jumbo Box",
    dimensions: { length: 19.29, width: 14.57, height: 11.42 },
    unit: "in",
  },
  {
    carrier: "DHL",
    name: "DHL Express Tube",
    dimensions: { length: 37, width: 7, height: 6 },
    unit: "in",
  },
];

// Carrier code to carrier name mapping
const carrierIdentifierMapping = {
  "se-1381891": "USPS",   // Example USPS code
  "se-1381892": "UPS",    // Example UPS code
  "se-1292994": "FedEx",  // Example FedEx code
  "se-1292995": "DHL",    // Example DHL code
  // Add other mappings as needed
};

const StandardPackageSelector = ({
  carriers,
  setLength,
  setWidth,
  setHeight,
  setDimensionUnitOfMeasurement,
  onPackageSelected,  // optional callback (parent can handle weight checks, etc.)
}) => {
  console.log("Received carriers:", carriers);

  // Translate identifiers to carrier names
  const translatedCarriers = (carriers || []).map(
    (id) => carrierIdentifierMapping[id] || id
  );
  console.log("Translated carriers:", translatedCarriers);

  // Normalize carriers to uppercase
  const normalizedCarriers = translatedCarriers.map((c) => c.toUpperCase());
  console.log("Normalized carriers:", normalizedCarriers);

  // Filter the packages based on the shop's supported carriers
  const filteredPackages = allStandardPackageSizes.filter((pkg) =>
    normalizedCarriers.includes(pkg.carrier.toUpperCase())
  );
  console.log("Filtered packages:", filteredPackages);

  const handleChange = (e) => {
    const selectedPackage = filteredPackages.find(
      (pkg) => pkg.name === e.target.value
    );
    if (selectedPackage) {
      // Update dimension fields in parent
      setLength(selectedPackage.dimensions.length);
      setWidth(selectedPackage.dimensions.width);
      setHeight(selectedPackage.dimensions.height);
      setDimensionUnitOfMeasurement(selectedPackage.unit);

      // If parent passed onPackageSelected, call it
      if (onPackageSelected) {
        onPackageSelected(selectedPackage);
      }
    }
  };

  return (
    <div className="create-product-component-form-section">
      <label
        htmlFor="standard-package"
        className="create-product-component-form-label"
      >
        Select a Standard Package Size
      </label>
      <select
        id="standard-package"
        name="standard-package"
        className="create-product-component-select"
        onChange={handleChange}
      >
        <option value="">Choose a package size</option>
        {filteredPackages.map((pkg) => (
          <option key={pkg.name} value={pkg.name}>
            {pkg.carrier} - {pkg.name} (
            {pkg.dimensions.length}x{pkg.dimensions.width}x{pkg.dimensions.height}{" "}
            {pkg.unit}
            {pkg.maxWeight
              ? `, up to ${pkg.maxWeight} ${pkg.maxWeightUnit}`
              : ""}
            )
          </option>
        ))}
      </select>
    </div>
  );
};

export default StandardPackageSelector;


