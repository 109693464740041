import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { server } from "../../server";
import GoogleCalendarButton from './GoogleCalendarButton';
import OutlookCalendarButton from './OutlookCalendarButton';
import DownloadICSButton from './DownloadICSButton';
import { CalendarIcon, MapPinIcon } from '@heroicons/react/20/solid';

const EventDetail = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const currentProfileType = useSelector((state) => state.user.currentProfile);
  const { user } = useSelector((state) => state.user);
  const UserId = user?._id;
  const [isGoing, setIsGoing] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`${server}/event/events/${eventId}`);
        const fetchedEvent = response.data;

        // Log the entire event object
        console.log("Fetched event:", fetchedEvent);

        // Specifically log the attendees array to see what's being returned
        if (fetchedEvent.attendees) {
          console.log("Attendees:", fetchedEvent.attendees);
        }

        setEvent(fetchedEvent);

        // Check if current user is in the attendee list
        const userIsAttending = fetchedEvent?.attendees?.some(
          (attendee) => attendee._id === UserId
        );
        setIsGoing(userIsAttending);
      } catch (error) {
        console.error('Failed to fetch event:', error);
      }
    };

    fetchEvent();
  }, [eventId, UserId]);

  if (!event) {
    return <div className="event-details-loading">Loading...</div>;
  }

  const handleRSVP = async () => {
    if (isGoing) {
      await handleCancelRSVP();
    } else {
      await handleNewRSVP();
    }
  };

  const handleNewRSVP = async () => {
    try {
      const data = { currentProfile: currentProfileType };
      const response = await axios.post(`${server}/event/rsvp/${eventId}`, data, {
        withCredentials: true,
      });
      if (response.data.success) {
        setIsGoing(true);
      }
    } catch (error) {
      console.error('Error RSVPing to event:', error);
      alert('Failed to RSVP: ' + (error.response?.data?.message || 'Error occurred'));
    }
  };

  const handleCancelRSVP = async () => {
    try {
      await axios.delete(`${server}/event/rsvp/${eventId}`, { withCredentials: true });
      setIsGoing(false);
    } catch (error) {
      console.error('Failed to cancel RSVP:', error);
      alert('Failed to cancel RSVP: ' + (error.response?.data?.message || 'Error occurred'));
    }
  };

  return (
    <div className="event-details-container">
      <div className="event-details-card">
        <div className="event-details-image-container">
          <img
            className="event-details-image"
            src={event?.image || "/default-event-image.jpg"}
            alt={event?.title || "Untitled Event"}
          />
          <h1 className="event-details-title">{event?.title || "Untitled Event"}</h1>
        </div>
        <div className="event-details-content">
          <div className="event-details-info">
            <div className="event-details-row">
              <CalendarIcon className="event-details-icon" />
              <span>{new Date(event?.date).toLocaleString()}</span>
            </div>
            <div className="event-details-row">
              <MapPinIcon className="event-details-icon" />
              <a
                href={`https://maps.google.com/?q=${encodeURIComponent(event?.location?.address || "")}`}
                target="_blank"
                rel="noopener noreferrer"
                className="event-details-link"
              >
                {event?.location?.address || "No address provided"}
              </a>
            </div>
          </div>
          <div className="event-details-actions">
            <button className="event-details-button" onClick={handleRSVP}>
              {isGoing ? 'Cancel RSVP' : 'RSVP Now'}
            </button>
            <div className="event-details-calendar-buttons">
              <GoogleCalendarButton event={event} />
              <OutlookCalendarButton event={event} />
              <DownloadICSButton event={event} />
            </div>
          </div>
          <div className="event-details-section">
            <h3>Hosted by</h3>
            <div className="event-details-host">
              <img
                className="event-details-host-avatar"
                src={event?.owner?.avatar?.url || "/default-avatar.png"}
                alt={event?.owner?.name || "Unknown Host"}
              />
              <span>{event?.owner?.name || "Unknown Host"}</span>
            </div>
          </div>
          <div className="event-details-section">
            <h3>Attendees ({event?.attendees?.length || 0})</h3>
            <div className="event-details-attendees">
              {event?.attendees?.slice(0, 5).map((attendee, index) => (
                <img
                  key={index}
                  className="event-details-attendee-avatar"
                  src={attendee?.avatar?.url || "/default-avatar.png"}
                  alt={attendee?.name || "Anonymous"}
                />
              ))}
              {event?.attendees?.length > 5 && (
                <div className="event-details-more-attendees">
                  +{event.attendees.length - 5}
                </div>
              )}
            </div>
          </div>
          <div className="event-details-section">
            <h3>About the Event</h3>
            <p>{event?.description || "No description provided."}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetail;



