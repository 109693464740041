import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import ProductDetails from "../components/Products/ProductDetails";
import SuggestedProduct from "../components/Products/SuggestedProduct";
import { useSelector } from "react-redux";
import Sidebar from "../components/Layout/Sidebar";

const ProductDetailsPage = () => {
  const { allProducts } = useSelector((state) => state.products);
  const { allEvents } = useSelector((state) => state.events);
  const { id } = useParams(); // Dynamically fetch the id from the URL
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  const eventData = searchParams.get("isEvent");

  useEffect(() => {
    if (eventData !== null) {
      const event = allEvents && allEvents.find((i) => i._id === id);
      setData(event);
    } else {
      const product = allProducts && allProducts.find((i) => i._id === id);
      setData(product);
    }
  }, [id, allProducts, allEvents, eventData]); // Re-run this effect when id or data changes

  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page scrolls to the top when navigating
  }, [id]); // Also run this when the id changes

  return (
    <div>
      <Header />
      <Sidebar />
      <ProductDetails data={data} />
      {!eventData && data && <SuggestedProduct data={data} />}
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
