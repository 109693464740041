import React from 'react';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Footer from '../Layout/Footer';

const VerificationStart = ({ nextStep }) => {
  return (
    <>
      <Header />
      <Sidebar />
    <div className="verification-start-container">
      <div className="verification-start-card">
        <h1 className="verification-start-heading">Welcome to the Verification Process</h1>
        <p className="verification-start-text">
          Please follow the steps to verify your account.
        </p>
        <button
          className="verification-start-button"
          onClick={nextStep}
        >
          Begin Verification
        </button>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default VerificationStart;

