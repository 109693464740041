import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AdDetailsModal = ({ ad, onClose, lowestEngagementAd, averageEngagement, onRerunAd }) => {
    if (!ad || !lowestEngagementAd || !averageEngagement) return null;

    // Extract clicks and impressions from metrics
    const adClicks = ad.metrics?.clicks.length || 0;
    const adImpressions = ad.metrics?.viewedImpressions.length || 0;

    const lowestAdClicks = lowestEngagementAd.metrics?.clicks.length || 0;
    const lowestAdImpressions = lowestEngagementAd.metrics?.viewedImpressions.length || 0;

    const avgClicks = averageEngagement.clicks || 0;
    const avgImpressions = averageEngagement.impressions || 0;

    // Prepare data for the chart
    const data = {
        labels: ['Clicks', 'Impressions'],
        datasets: [
            {
                label: 'Selected Ad',
                data: [adClicks, adImpressions],
                backgroundColor: 'var(--primary-color)',
            },
            {
                label: 'Average Engagement',
                data: [avgClicks, avgImpressions],
                backgroundColor: 'rgba(54, 162, 235, 0.7)',
            },
            {
                label: 'Lowest Engagement',
                data: [lowestAdClicks, lowestAdImpressions],
                backgroundColor: 'rgba(255, 99, 132, 0.7)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Ad Performance Comparison',
            },
        },
    };

    return (
        <div className="top-performing-ad-details-modal">
            <div className="top-performing-ad-details-overlay" onClick={onClose}></div>
            <div className="top-performing-ad-details-content">
                <button className="top-performing-ad-details-close" onClick={onClose}>×</button>
                <button className="rerun-ad-button" onClick={() => onRerunAd(ad._id)}>Re-run Ad</button>

                <div className="ad-details-section">
                    <div className="ad-details-item">
                        <dd className="ad-details-value">{ad.product?.name || 'N/A'}</dd>
                        <dt className="ad-details-name">Product</dt>
                    </div>
                    <div className="ad-details-item">
                        <dd className="ad-details-value">{ad.shop?.name || 'N/A'}</dd>
                        <dt className="ad-details-name">Shop</dt>
                    </div>
                    <div className="ad-details-item">
                        <dd className="ad-details-value">{ad.brand?.name || 'N/A'}</dd>
                        <dt className="ad-details-name">Brand</dt>
                    </div>
                    <div className="ad-details-item">
                        <dd className="ad-details-value">${ad.adSpend}</dd>
                        <dt className="ad-details-name">Ad Spend</dt>
                    </div>
                    <div className="ad-details-item">
                        <dd className="ad-details-value">{ad.adDuration} days</dd>
                        <dt className="ad-details-name">Ad Duration</dt>
                    </div>
                    <div className="ad-details-item">
                        <dd className="ad-details-value">{ad.status}</dd>
                        <dt className="ad-details-name">Status</dt>
                    </div>
                    <div className="ad-details-item">
                        <dd className="ad-details-value">{adClicks}</dd>
                        <dt className="ad-details-name">Clicks</dt>
                    </div>
                    <div className="ad-details-item">
                        <dd className="ad-details-value">{adImpressions}</dd>
                        <dt className="ad-details-name">Impressions</dt>
                    </div>
                    <div className="ad-details-item">
                        <dd className="ad-details-value">{ad.invoiceId || 'N/A'}</dd>
                        <dt className="ad-details-name">Invoice ID</dt>
                    </div>
                </div>
                <div className="ad-details-graph">
                    <Bar data={data} options={options} />
                </div>
            </div>
        </div>
    );
};

export default AdDetailsModal;




