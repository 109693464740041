import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { Cropper, CropperPreview } from 'react-advanced-cropper';
import AdjustablePreviewBackground from './AdjustablePreviewBackground';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import 'react-advanced-cropper/dist/style.css';
import './ImageEditor.scss';
import Button from './Button';
import Navigation from './Navigation';
import ResetIcon from './Icons/ResetIcon';
import AdjustableCropperBackground from './AdjustableCropperBackground';
import Slider from './Slider';
import useWindowSize from "../../hooks/useWindowSize";

// The polyfill for Safari browser. The dynamic require is needed to work with SSR
if (typeof window !== 'undefined') {
	require('context-filter-polyfill');
}

const ImageCropper = ({ updateAvatar, closeModal, initialImage, aspectRatio, stencilWidth, stencilHeight, isPost, minWidth,  previousCrop, isLogo}) => {
    const cropperRef = useRef(null);
    const previewRef = useRef(null);

    const [src, setSrc] = useState(null);
    const [mode, setMode] = useState('crop');
    const [adjustments, setAdjustments] = useState({
        brightness: 0,
        hue: 0,
        saturation: 0,
        contrast: 0,
    });
    const [error, setError] = useState("");
    const windowSize = useWindowSize();
    const isMobile = windowSize.width <= 768;
    const [selectedAspectRatio, setSelectedAspectRatio] = useState(
        isLogo ? null : aspectRatio || (isPost ? 4 / 5 : null) 
      );
      const [minHeight, setMinHeight] = useState(null);

      useEffect(() => {
        if (selectedAspectRatio && minWidth) {
          setMinHeight(minWidth / selectedAspectRatio);
        } else {
          setMinHeight(null); // Free aspect ratio
        }
      }, [selectedAspectRatio, minWidth]);

    useEffect(() => {
        if (initialImage instanceof File || initialImage instanceof Blob) {
            setSrc(URL.createObjectURL(initialImage));
        } else if (typeof initialImage === 'string') {
            setSrc(initialImage); // Preset image URL
        } else {
            console.warn("Invalid initialImage: expected a File, Blob, or string URL");
            setError("An invalid image file was provided.");
        }

        // Clean up the object URL when component unmounts
        return () => {
            if (initialImage instanceof File || initialImage instanceof Blob) {
                URL.revokeObjectURL(src);
            }
        };
    }, [initialImage]);

    const onChangeValue = (value) => {
        if (mode in adjustments) {
            setAdjustments((previousValue) => ({
                ...previousValue,
                [mode]: value,
            }));
        }
    };

    const onReset = () => {
        setMode('crop');
        setAdjustments({
            brightness: 0,
            hue: 0,
            saturation: 0,
            contrast: 0,
        });
    };

    const handleCropImage = () => {
        if (cropperRef.current && cropperRef.current.getCanvas) {
            const canvas = cropperRef.current.getCanvas();
            if (canvas) {
                canvas.toBlob((blob) => {
                    if (blob) {
                        const croppedFile = new File([blob], initialImage.name || 'cropped_image', { type: blob.type });
                        // Set the cropped image as the new source for further editing
                        setSrc(URL.createObjectURL(croppedFile));
                    }
                });
            }
        }
    };

    const handleSaveImage = () => {
        if (cropperRef.current && cropperRef.current.getCanvas) {
            const canvas = cropperRef.current.getCanvas();
            if (canvas) {
                canvas.toBlob((blob) => {
                    if (blob) {
                        const editedFile = new File([blob], initialImage.name || 'edited_image', { type: blob.type });
                        // Pass the edited image to the parent
                        updateAvatar(editedFile);
                        // Close the modal after saving
                        closeModal();
                    }
                });
            }
        }
    };

    const onUpdate = (cropper) => {
        previewRef.current?.update(cropper);
    };

    const changed = Object.values(adjustments).some((el) => Math.floor(el * 100));
    const cropperEnabled = mode === 'crop';

    return (
        <div className={'image-editor'}>
            {error && (
                <div className="image-editor__error-container">
                    <div className="image-editor__error-icon">
                        <ExclamationTriangleIcon aria-hidden="true" className="image-editor__error-icon-img" />
                    </div>
                    <div className="image-editor__error-content">
                        <h3 className="image-editor__error-heading">Attention needed</h3>
                        <p className="image-editor__error-message">{error}</p>
                    </div>
                </div>
            )}
            <div className="image-editor__cropper">
                <Cropper
                    src={src}
                    ref={cropperRef}
                    imageRestriction={isMobile ? "none" : "fitArea"}
                    stencilProps={{
                        movable: !isMobile && cropperEnabled,  
                        resizable: !isMobile && cropperEnabled,
                        lines: cropperEnabled,
                        handlers: !isMobile && cropperEnabled,
                        aspectRatio: isLogo ? undefined : selectedAspectRatio, 
                        width: stencilWidth,
                        height: stencilHeight,
                        overlayClassName: cn(
                            'image-editor__cropper-overlay',
                            !cropperEnabled && 'image-editor__cropper-overlay--faded',
                        ),
                    }}
                    backgroundWrapperProps={{
                        scaleImage: cropperEnabled,
                        moveImage: cropperEnabled,
                    }}
                    backgroundComponent={AdjustableCropperBackground}
                    backgroundProps={adjustments}
                    onUpdate={onUpdate}
                />
                {mode !== 'crop' && (
                    <Slider
                        className="image-editor__slider"
                        value={adjustments[mode]}
                        onChange={onChangeValue}
                    />
                )}
                
                <button
                    className={cn('image-editor__reset-button', !changed && 'image-editor__reset-button--hidden')}
                    onClick={onReset}
                >
                    <ResetIcon />
                </button >
            </div>
            {isPost && (
        <div className="image-editor__aspect-ratio-selector">
          
          <button
            onClick={() => setSelectedAspectRatio(1 / 1)}
            className={selectedAspectRatio === 1 / 1 ? 'active' : ''}
          >
            1:1
          </button>
          <button
            onClick={() => setSelectedAspectRatio(4 / 5)}
            className={selectedAspectRatio === 4 / 5 ? 'active' : ''}
          >
            4:5
          </button>
          <button
            onClick={() => setSelectedAspectRatio(16 / 9)}
            className={selectedAspectRatio === 16 / 9 ? 'active' : ''}
          >
            16:9
          </button>
        </div>
      )}
            <div className="image-editor__actions">
                <button
                    type="button" 
                    className="image-cropper__save-button"
                    onClick={handleSaveImage}
                >
                    Save Image
                </button>
            </div>
            <Navigation mode={mode} onChange={setMode} />
        </div>
    );
};

export default ImageCropper;






















