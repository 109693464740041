import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ecommerceServer } from '../../server';
import { Switch } from '@headlessui/react';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ImageCropperModal from '../Image/ImageCropperModal';
import TagSelector from '../Tags/TagSelector';
import TaxCodeSelector from '../Shop/TaxCodeSelector';
import Variants from '../Shop/Variants';
import { toast } from "react-toastify";
import ReactDOM from 'react-dom';
import StandardPackageSelector from '../Shipping/StandardPackageSelector';
import { useSelector } from "react-redux";
import { categoriesData } from '../../static/data';

// Helper function to convert a product's weight to kg
function convertWeightToKg(value, unit) {
  if (!value) return 0;
  const numericVal = parseFloat(value) || 0;
  switch (unit) {
    case 'lbs':
      return numericVal * 0.45359237;
    case 'g':
      return numericVal / 1000;
    case 'kg':
    default:
      return numericVal;
  }
}

const EditProduct = ({ productId, onClose }) => {
  const [productData, setProductData] = useState({
    name: '',
    description: '',
    category: '',
    subCategory: '',  
    tags: [],
    originalPrice: '',
    discountPrice: '',
    productType: ['retail'],
    stock: '',
    availableForPickup: false,
    availableForDelivery: true,
    handle: '',
    brand: '',
    sold_out: 0,
    SKU: '',
    lowInventoryThreshold: '',
    weightUnitOfMeasurement: '',
    dimensionUnitOfMeasurement: '',
    isEdible: false,
    barcodeString: '',
    isOnSale: false,
    taxCode: '',
    weight: '',
    length: '',
    width: '',
    height: '',
  });

  const [availableSubCategories, setAvailableSubCategories] = useState([]);

  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [barcodeImage, setBarcodeImage] = useState([]);
  const [barcodePreviews, setBarcodePreviews] = useState([]);
  const [nutritionInfoImage, setNutritionInfoImage] = useState([]);
  const [nutritionInfoImagePreviews, setNutritionInfoImagePreviews] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [selectedImageForCrop, setSelectedImageForCrop] = useState(null);
  const [remainingImagesToCrop, setRemainingImagesToCrop] = useState([]);
  const [fieldNameBeingCropped, setFieldNameBeingCropped] = useState('');
  const [removedImageIds, setRemovedImageIds] = useState([]);
  const [variants, setVariants] = useState([]); 
  const [isVariableProduct, setIsVariableProduct] = useState(false);

  // NEW: Toggle between using custom dimensions or a StandardPackageSelector
  const [useCustomDimensions, setUseCustomDimensions] = useState(true);

  // NEW: Track which standard package was selected
  const [selectedStandardPackage, setSelectedStandardPackage] = useState(null);

  const currentProfile = sessionStorage.getItem('currentProfile');
  const { seller } = useSelector((state) => state.seller);

  const [formErrors, setFormErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const refs = {
    name: useRef(null),
    description: useRef(null),
    category: useRef(null),
    subCategory: useRef(null),
    SKU: useRef(null),
    brand: useRef(null),
    originalPrice: useRef(null),
    discountPrice: useRef(null),
    stock: useRef(null),
    lowInventoryThreshold: useRef(null),
    taxCode: useRef(null),
    weight: useRef(null),
    weightUnitOfMeasurement: useRef(null),
    dimensionUnitOfMeasurement: useRef(null),
    length: useRef(null),
    width: useRef(null),
    height: useRef(null),
  };

  const getRef = (fieldName) => refs[fieldName];

  const preventScrollChange = (e) => {
    // Prevent mouse scroll from incrementing numeric fields
    e.target.blur();
  };

  // -----------------------------
  // DRAG & DROP for Images
  // -----------------------------
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index) return;

    // Reorder images
    const updatedImages = Array.from(images);
    const [movedImage] = updatedImages.splice(source.index, 1);
    updatedImages.splice(destination.index, 0, movedImage);

    // Reorder image previews
    const updatedImagePreviews = Array.from(imagePreviews);
    const [movedPreview] = updatedImagePreviews.splice(source.index, 1);
    updatedImagePreviews.splice(destination.index, 0, movedPreview);

    setImages(updatedImages);
    setImagePreviews(updatedImagePreviews);
  };

  // -----------------------------
  // Fetch existing product
  // -----------------------------
  useEffect(() => {
    axios
      .get(`${ecommerceServer}/product/get-product/${productId}`)
      .then((response) => {
        const product = response.data.product;
        console.log("Fetched product data:", product);

        let tempSubCategories = [];
        if (product.category) {
          const categoryObj = categoriesData.find(
            (cat) => cat.title === product.category
          );
          if (categoryObj && categoryObj.subCategories) {
            tempSubCategories = categoryObj.subCategories;
          }
        }

        setAvailableSubCategories(tempSubCategories);

        setProductData({
          ...product,
          subCategory: product.subCategory || '',
          length: product.dimensions?.length ?? '',
          width: product.dimensions?.width ?? '',
          height: product.dimensions?.height ?? '',
          tags: product.tags,
          availableForDelivery:
            product.availableForDelivery !== undefined
              ? product.availableForDelivery
              : false,
          availableForPickup:
            product.availableForPickup !== undefined
              ? product.availableForPickup
              : false,
          isOnSale:
            product.isOnSale !== undefined
              ? product.isOnSale
              : false,
          isEdible:
            product.isEdible !== undefined
              ? product.isEdible
              : false,
        });

        // Set images & previews
        setImages(product.images || []);
        setImagePreviews(product.images.map((img) => img.url) || []);

        // Variants
        const updatedVariants = product.variants.map((variant) => ({
          ...variant,
          dimensions: variant.dimensions || { length: '', width: '', height: '' },
          weight: variant.weight || '',
          image: variant.image || null,
        }));
        setVariants(updatedVariants);
        setIsVariableProduct(updatedVariants.length > 0);

        // Barcode images
        setBarcodeImage(product.barcode || []);
        setBarcodePreviews(product.barcode.map((barcode) => barcode.url) || []);

        // Nutrition info images
        setNutritionInfoImage(product.nutritionInfoImages || []);
        setNutritionInfoImagePreviews(
          product.nutritionInfoImages.map((img) => img.url) || []
        );

        // Videos
        setVideos(product.videos || []);
        setVideoPreviews(product.videos?.map((video) => video.url) || []);

        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching product data:', error);
        setLoading(false);
      });
  }, [productId]);

  const validateForm = () => {
    const errors = {};
    const {
      name,
      description,
      category,
      subCategory,
      SKU,
      brand,
      originalPrice,
      discountPrice,
      stock,
      lowInventoryThreshold,
      taxCode,
      productType,
      isOnSale,
      availableForDelivery,
      weight,
      weightUnitOfMeasurement,
      length,
      width,
      height,
      dimensionUnitOfMeasurement,
    } = productData;

    // Required fields
    if (!name?.trim()) {
      errors.name = "Product name is required.";
    }
    if (!description?.trim()) {
      errors.description = "Product description is required.";
    }
    if (!category?.trim()) {
      errors.category = "Category is required.";
    }
    if (availableSubCategories.length > 0 && !subCategory) {
      errors.subCategory = "Subcategory is required.";
    }
    if (!SKU?.trim()) {
      errors.SKU = "SKU is required.";
    }
    if (!brand?.trim()) {
      errors.brand = "Brand is required.";
    }

    // Product Type
    if (!productType?.length) {
      errors.productType = "At least one product type must be selected.";
    }

    // Pricing
    if (!originalPrice || parseFloat(originalPrice) <= 0) {
      errors.originalPrice = "Original price must be greater than 0.";
    }
    if (isOnSale && (!discountPrice || parseFloat(discountPrice) <= 0)) {
      errors.discountPrice = "Discount price must be greater than 0.";
    }

    // Stock
    if (stock === "" || parseInt(stock) < 0) {
      errors.stock = "Stock must be 0 or more.";
    }
    if (lowInventoryThreshold === "" || parseInt(lowInventoryThreshold) < 0) {
      errors.lowInventoryThreshold = "Low inventory threshold must be 0 or more.";
    }

    // Tax
    if (!taxCode) {
      errors.taxCode = "Tax code is required.";
    }

    // Shipping / Delivery
    if (availableForDelivery) {
      if (!weight || parseFloat(weight) <= 0) {
        errors.weight = "Weight must be greater than 0.";
      }
      if (!weightUnitOfMeasurement) {
        errors.weightUnitOfMeasurement = "Weight unit of measurement is required.";
      }
      if (!length || parseFloat(length) <= 0) {
        errors.length = "Length must be greater than 0.";
      }
      if (!width || parseFloat(width) <= 0) {
        errors.width = "Width must be greater than 0.";
      }
      if (!height || parseFloat(height) <= 0) {
        errors.height = "Height must be greater than 0.";
      }
      if (!dimensionUnitOfMeasurement) {
        errors.dimensionUnitOfMeasurement = "Dimension unit of measurement is required.";
      }
    }

    // If user is not using custom but selected a standard package with a maxWeight
    if (
      availableForDelivery &&
      !useCustomDimensions &&
      selectedStandardPackage?.maxWeight
    ) {
      const productWeightInKg = convertWeightToKg(weight, weightUnitOfMeasurement);
      if (productWeightInKg > selectedStandardPackage.maxWeight) {
        errors.weight = `This package has a weight limit of ${selectedStandardPackage.maxWeight} ${selectedStandardPackage.maxWeightUnit}. Your product is ${productWeightInKg.toFixed(2)} kg.`;
      }
    }

    return errors;
  };

  // Validation for Variants
  const handleVariantErrors = (variantErrors) => {
    // variantErrors = array of objects from <Variants />
    if (variantErrors.length > 0) {
      setFormErrors((prev) => ({ ...prev, variants: variantErrors }));
    } else {
      setFormErrors((prev) => {
        const { variants, ...others } = prev;
        return others;
      });
    }
  };

  // -----------------------------
  // Handlers
  // -----------------------------
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // If user changes category, reset subCategory and set subcategory options
    if (name === 'category') {
      const categoryObj = categoriesData.find((cat) => cat.title === value);
      setAvailableSubCategories(categoryObj?.subCategories || []);

      setProductData((prev) => ({
        ...prev,
        category: value,
        subCategory: '', // reset subCategory
      }));
      return;
    }

    // If user picks a subCategory
    if (name === 'subCategory') {
      setProductData((prev) => ({
        ...prev,
        subCategory: value,
      }));
      return;
    }

    // Otherwise, normal handle
    setProductData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setProductData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleProductTypeChange = (type) => {
    setProductData((prevState) => {
      const updatedProductType = prevState.productType.includes(type)
        ? prevState.productType.filter((t) => t !== type)
        : [...prevState.productType, type];
      return { ...prevState, productType: updatedProductType };
    });
  };

  // -----------------------------
  // Image Upload & Crop
  // -----------------------------
  const handleImageChange = (e, fieldName) => {
    e.preventDefault();
    e.stopPropagation();
    const newFiles = Array.from(e.target.files || e.dataTransfer?.files || []);
    if (newFiles.length > 0) {
      setRemainingImagesToCrop(newFiles);
      setSelectedImageForCrop(newFiles[0]);
      setFieldNameBeingCropped(fieldName);
      setShowImageCropper(true);
    }
  };

  const handleImageCropCompletion = (croppedImage) => {
    let updatedFiles = [];
    let updatedPreviews = [];

    switch (fieldNameBeingCropped) {
      case 'images':
        updatedFiles = [...images, croppedImage];
        updatedPreviews = updatedFiles.map((file) => {
          if (file instanceof File || file instanceof Blob) {
            return URL.createObjectURL(file);
          } else if (typeof file === 'object' && file.url) {
            return file.url;
          }
          return file;
        });
        setImages(updatedFiles);
        setImagePreviews(updatedPreviews);
        break;

      case 'barcode':
        updatedFiles = [...barcodeImage, croppedImage];
        updatedPreviews = updatedFiles.map((file) => {
          if (file instanceof File || file instanceof Blob) {
            return URL.createObjectURL(file);
          } else if (typeof file === 'object' && file.url) {
            return file.url;
          }
          return file;
        });
        setBarcodeImage(updatedFiles);
        setBarcodePreviews(updatedPreviews);
        break;

      case 'nutritionInfoImage':
        updatedFiles = [...nutritionInfoImage, croppedImage];
        updatedPreviews = updatedFiles.map((file) => {
          if (file instanceof File || file instanceof Blob) {
            return URL.createObjectURL(file);
          } else if (typeof file === 'object' && file.url) {
            return file.url;
          }
          return file;
        });
        setNutritionInfoImage(updatedFiles);
        setNutritionInfoImagePreviews(updatedPreviews);
        break;

      default:
        return;
    }

    // Move to the next image in the queue
    const newQueue = [...remainingImagesToCrop];
    newQueue.shift();

    if (newQueue.length > 0) {
      setRemainingImagesToCrop(newQueue);
      setSelectedImageForCrop(newQueue[0]);
      setShowImageCropper(true);
    } else {
      setShowImageCropper(false);
      setSelectedImageForCrop(null);
      setFieldNameBeingCropped('');
    }
  };

  const handleRemoveImage = (index, files, setFiles, setPreviews) => {
    const removedFile = files[index];
    if (removedFile && removedFile._id) {
      setRemovedImageIds((prev) => [...prev, removedFile._id]);
    }

    const newFiles = files.filter((_, i) => i !== index);
    const newPreviews = newFiles.map((file) => {
      if (file instanceof File || file instanceof Blob) {
        return URL.createObjectURL(file);
      } else if (typeof file === 'object' && file.url) {
        return file.url;
      }
      return file;
    });

    setFiles(newFiles);
    setPreviews(newPreviews);
  };

  // -----------------------------
  // Video Upload
  // -----------------------------
  const handleVideoChange = (newFiles) => {
    const updatedFiles = [...videos, ...newFiles];
    const updatedPreviews = updatedFiles.map((file) => URL.createObjectURL(file));
    setVideos(updatedFiles);
    setVideoPreviews(updatedPreviews);
  };

  const handleRemoveVideo = (index) => {
    const newVideos = videos.filter((_, i) => i !== index);
    const newPreviews = newVideos.map((file) => URL.createObjectURL(file));
    setVideos(newVideos);
    setVideoPreviews(newPreviews);
  };

  // -----------------------------
  // Toggle for variable product
  // -----------------------------
  const handleProductTypeSwitch = () => {
    setIsVariableProduct(!isVariableProduct);
  };

  // -----------------------------
  // SUBMIT (with weight-limit check)
  // -----------------------------
  const handleSubmit = (event) => {
    event.preventDefault();
    setHasSubmitted(true);

    const errors = validateForm();
    // If there are variants, gather their validations from <Variants/>
    // (We might integrate them directly, or rely on handleVariantErrors from <Variants>)

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      // Scroll to first error
      let firstErrorField = null;
      const orderOfFields = [
        "name",
        "description",
        "category",
        "subCategory",
        "SKU",
        "brand",
        "productType",
        "originalPrice",
        "discountPrice",
        "stock",
        "lowInventoryThreshold",
        "taxCode",
        "weight",
        "weightUnitOfMeasurement",
        "length",
        "width",
        "height",
      ];
      for (const field of orderOfFields) {
        if (errors[field]) {
          firstErrorField = field;
          break;
        }
      }

      // If no main form errors, check variants
      if (!firstErrorField && errors.variants) {
        // errors.variants is an array of objects, each for a variant
        for (let i = 0; i < errors.variants.length; i++) {
          const variantError = errors.variants[i];
          for (const field in variantError) {
            // e.g. 'sku' or 'stock'
            firstErrorField = `variants.${i}.${field}`;
            break;
          }
          if (firstErrorField) break;
        }
      }

      if (firstErrorField) {
        if (firstErrorField.startsWith("variants.")) {
          const [_, index, field] = firstErrorField.split(".");
          // Attempt to find an input with data-variant-index & name
          const variantField = document.querySelector(
            `[data-variant-index="${index}"][name="${field}"]`
          );
          if (variantField) {
            variantField.scrollIntoView({ behavior: "smooth", block: "center" });
            variantField.focus();
          }
        } else {
          const ref = getRef(firstErrorField);
          if (ref?.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
            ref.current.focus();
          }
        }
      }
      return;
    }

    // If the user is using a standard package and we have a maxWeight, do a quick check
    if (
      !useCustomDimensions &&
      productData.availableForDelivery &&
      selectedStandardPackage?.maxWeight
    ) {
      const productWeightInKg = convertWeightToKg(
        productData.weight,
        productData.weightUnitOfMeasurement
      );
      if (productWeightInKg > selectedStandardPackage.maxWeight) {
        toast.error(
          `This package has a weight limit of ${selectedStandardPackage.maxWeight} ${selectedStandardPackage.maxWeightUnit}. 
           Your product's weight is ${productWeightInKg.toFixed(2)} kg.`
        );
        return;
      }
    }

    // Build FormData
    const formData = new FormData();

    // Exclude certain fields from direct append
    for (const key in productData) {
      if (
        key !== 'reviews' &&
        key !== 'tags' &&
        key !== 'length' &&
        key !== 'width' &&
        key !== 'height' &&
        key !== 'dimensions' &&
        key !== 'variants'
      ) {
        if (Array.isArray(productData[key]) || typeof productData[key] === 'object') {
          formData.append(key, JSON.stringify(productData[key]));
        } else {
          formData.append(key, productData[key]);
        }
      }
    }

    // Dimensions as JSON
    formData.append(
      'dimensions',
      JSON.stringify({
        length: productData.length,
        width: productData.width,
        height: productData.height,
      })
    );

    // Tags
    const tagIds = productData.tags.map((tag) => tag._id || tag);
    formData.append('tags', JSON.stringify(tagIds));

    // Images
    images.forEach((image) => {
      if (image instanceof File) {
        formData.append('images', image);
      } else if (image.url) {
        formData.append('existingImageUrls[]', image.url);
      } else if (image._id) {
        formData.append('existingImageIds[]', image._id);
      }
    });

    // Barcode images
    barcodeImage.forEach((barcode) => {
      if (barcode instanceof File) {
        formData.append('barcode', barcode);
      } else if (barcode.url) {
        formData.append('existingBarcodeUrls[]', barcode.url);
      }
    });

    // Nutrition info images
    nutritionInfoImage.forEach((image) => {
      if (image instanceof File) {
        formData.append('nutritionInfoImage', image);
      } else if (image.url) {
        formData.append('existingNutritionInfoUrls[]', image.url);
      }
    });

    // Videos
    videos.forEach((video) => {
      if (video instanceof File) {
        formData.append('videos', video);
      } else if (video.url) {
        formData.append('existingVideoUrls[]', video.url);
      }
    });

    // Track which existing images are removed
    removedImageIds.forEach((id) => {
      formData.append('removedImageIds[]', id);
    });

    // Variants (if variable product)
    if (isVariableProduct) {
      // Assign identifiers to variants
      variants.forEach((variant) => {
        if (!variant._id && !variant.tempId) {
          variant.tempId = `temp_${Date.now()}_${Math.random()}`;
        }
      });

      // Make them serializable
      const serializableVariants = variants.map((variant) => {
        const { image, tempId, ...rest } = variant;
        let imageData = null;
        if (image) {
          if (image._id) {
            imageData = { _id: image._id };
          } else if (image.url) {
            imageData = { url: image.url };
          }
        }
        return {
          ...rest,
          image: imageData,
          _id: variant._id || variant.tempId,
        };
      });

      formData.append('variants', JSON.stringify(serializableVariants));

      // Append variant images
      variants.forEach((variant) => {
        if (variant.image && variant.image.file) {
          const variantId = variant._id || variant.tempId;
          formData.append(`variant_image_${variantId}`, variant.image.file);
        }
      });
    }

    // Submit via axios
    axios
      .put(`${ecommerceServer}/product/edit-product/${productId}`, formData, {
        withCredentials: true,
        headers: {
          'Current-Profile': currentProfile,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Product updated:', response.data);
        toast.success("Product updated successfully!");
        if (typeof onClose === 'function') {
          onClose();
        }
      })
      .catch((error) => {
        console.error('Error updating product:', error);
        toast.error("Failed to update product. Please try again.");
      });
  };

  // -----------------------------
  // RENDER
  // -----------------------------
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!productData) {
    return <div>Product not found</div>;
  }

  return (
    <div className="create-product-component-wrapper">
      <h5 className="create-product-component-title">Edit Product</h5>

      <form onSubmit={handleSubmit} className="create-product-component-form">
        {/* Name */}
        <div className="create-product-component-form-section">
          <div className="create-product-component-form-group">
            <label htmlFor="name" className="create-product-component-form-label">
              Name <span className="create-product-component-form-required">*</span>
            </label>
            <input
              ref={refs.name}
              type="text"
              name="name"
              id="name"
              value={productData.name}
              required
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Enter your product name..."
            />
            {formErrors.name && (
              <p className="form-error-message">{formErrors.name}</p>
            )}
          </div>
        </div>

        {/* Description */}
        <div className="create-product-component-form-section">
          <label htmlFor="description" className="create-product-component-form-label">
            Description <span className="create-product-component-form-required">*</span>
          </label>
          <textarea
            ref={refs.description}
            id="description"
            name="description"
            rows={4}
            required
            value={productData.description}
            onChange={handleInputChange}
            className="create-product-component-form-textarea"
            placeholder="Enter your product description..."
          />
          {formErrors.description && (
            <p className="form-error-message">{formErrors.description}</p>
          )}
        </div>

        {/* IMAGES */}
        <div className="create-product-component-upload-section">
          <label htmlFor="upload" className="create-product-component-upload-label">
            Upload Product Images
          </label>
          <div
            className="create-product-component-dropzone"
            onDrop={(e) => handleImageChange(e, 'images')}
            onDragOver={(e) => e.preventDefault()}
          >
            <div className="create-product-component-dropzone-content">
              <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
              <label htmlFor="upload" className="create-product-component-dropzone-label">
                <span>Click or drag files here to upload</span>
                <input
                  type="file"
                  id="upload"
                  multiple
                  onChange={(e) => handleImageChange(e, 'images')}
                  className="sr-only"
                />
              </label>
              <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
            </div>
          </div>
        </div>

        {/* DRAG & DROP PREVIEWS */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppableImages" direction="horizontal">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="create-product-component-image-previews"
              >
                {imagePreviews.map((imageUrl, index) => (
                  <Draggable key={index} draggableId={`image-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`create-product-component-image-preview ${
                          snapshot.isDragging
                            ? "create-product-component-image-preview-dragging"
                            : ""
                        }`}
                      >
                        <img
                          src={imageUrl}
                          alt={`Uploaded ${index}`}
                          className="create-product-component-image-preview-img"
                        />
                        <div className="create-product-component-image-preview-badge">
                          {index + 1}
                        </div>
                        <button
                          onClick={() =>
                            handleRemoveImage(index, images, setImages, setImagePreviews)
                          }
                          className="create-product-component-image-preview-remove"
                          aria-label="Remove image"
                        >
                          &times;
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {/* VIDEOS */}
        <div className="create-product-component-upload-section">
          <label htmlFor="video-upload" className="create-product-component-upload-label">
            Upload Product Videos
          </label>
          <div
            className="create-product-component-dropzone"
            onDrop={(e) => {
              e.preventDefault();
              const newFiles = Array.from(e.dataTransfer.files).filter(
                (file) => file.type.startsWith('video/')
              );
              handleVideoChange(newFiles);
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            <div className="create-product-component-dropzone-content">
              <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
              <label htmlFor="video-upload" className="create-product-component-dropzone-label">
                <span>Drag and drop your videos here or click to upload</span>
                <input
                  type="file"
                  id="video-upload"
                  multiple
                  accept="video/*"
                  onChange={(e) => handleVideoChange(Array.from(e.target.files))}
                  className="sr-only"
                />
              </label>
              <p className="create-product-component-dropzone-note">MP4 up to 50MB</p>
            </div>
          </div>
        </div>

        {/* VIDEO PREVIEWS */}
        <div className="create-product-component-video-previews">
          {videoPreviews.map((videoUrl, index) => (
            <div key={index} className="create-product-component-video-preview">
              <video
                src={videoUrl}
                controls
                className="create-product-component-video-preview-video"
              />
              <button
                onClick={() => handleRemoveVideo(index)}
                className="create-product-component-video-preview-remove"
                aria-label="Remove video"
              >
                &times;
              </button>
            </div>
          ))}
        </div>

        {/* PRODUCT TYPE */}
        <div className="create-product-component-form-section">
          <label className="create-product-component-form-label">
            Product Type <span className="create-product-component-form-required">*</span>
          </label>
          <div className="create-product-component-product-type-section">
            {/* Retail Switch */}
            <Switch.Group
              as="div"
              className={`create-product-component-switch-group ${
                productData.productType.includes("retail")
                  ? 'create-product-component-switch-group-active'
                  : ''
              }`}
            >
              <span className="create-product-component-switch-label">Retail</span>
              <Switch
                checked={productData.productType.includes("retail")}
                onChange={() => handleProductTypeChange("retail")}
                className={`create-product-component-switch ${
                  productData.productType.includes("retail")
                    ? 'create-product-component-switch-active'
                    : 'create-product-component-switch-inactive'
                }`}
              >
                <span
                  className={`create-product-component-switch-toggle ${
                    productData.productType.includes("retail")
                      ? 'create-product-component-switch-toggle-active'
                      : 'create-product-component-switch-toggle-inactive'
                  }`}
                />
              </Switch>
            </Switch.Group>

            {/* Wholesale Switch */}
            <Switch.Group
              as="div"
              className={`create-product-component-switch-group ${
                productData.productType.includes("wholesale")
                  ? 'create-product-component-switch-group-active'
                  : ''
              }`}
            >
              <span className="create-product-component-switch-label">Wholesale</span>
              <Switch
                checked={productData.productType.includes("wholesale")}
                onChange={() => handleProductTypeChange("wholesale")}
                className={`create-product-component-switch ${
                  productData.productType.includes("wholesale")
                    ? 'create-product-component-switch-active'
                    : 'create-product-component-switch-inactive'
                }`}
              >
                <span
                  className={`create-product-component-switch-toggle ${
                    productData.productType.includes("wholesale")
                      ? 'create-product-component-switch-toggle-active'
                      : 'create-product-component-switch-toggle-inactive'
                  }`}
                />
              </Switch>
            </Switch.Group>
          </div>
          {formErrors.productType && (
            <p className="form-error-message" role="alert">
              {formErrors.productType}
            </p>
          )}
        </div>

        {/* SKU & Brand */}
        <div className="create-product-component-form-section create-product-component-form-group-row">
          <div className="create-product-component-form-group">
            <label htmlFor="sku" className="create-product-component-form-label">
              SKU <span className="create-product-component-form-required">*</span>
            </label>
            <input
              ref={refs.SKU}
              type="text"
              name="SKU"
              id="sku"
              value={productData.SKU}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Enter SKU..."
            />
            {formErrors.SKU && <p className="form-error-message">{formErrors.SKU}</p>}
          </div>
          <div className="create-product-component-form-group">
            <label htmlFor="brand" className="create-product-component-form-label">
              Brand <span className="create-product-component-form-required">*</span>
            </label>
            <input
              ref={refs.brand}
              type="text"
              name="brand"
              id="brand"
              value={productData.brand}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Enter your product brand..."
            />
            {formErrors.brand && <p className="form-error-message">{formErrors.brand}</p>}
          </div>
        </div>

       {/* Category */}
       <div className="create-product-component-form-section">
          <label htmlFor="category" className="create-product-component-form-label">
            Category <span className="create-product-component-form-required">*</span>
          </label>
          <select
            ref={refs.category}
            id="category"
            name="category"
            value={productData.category}
            onChange={handleInputChange}
            className="create-product-component-select"
          >
            <option value="">Choose a category</option>
            {categoriesData.map((cat) => (
              <option key={cat.title} value={cat.title}>
                {cat.title}
              </option>
            ))}
          </select>
          {formErrors.category && (
            <p className="form-error-message">{formErrors.category}</p>
          )}
        </div>

        {/* Subcategory (only if availableSubCategories.length > 0) */}
        {availableSubCategories.length > 0 && (
          <div className="create-product-component-form-section">
            <label htmlFor="subCategory" className="create-product-component-form-label">
              Subcategory <span className="create-product-component-form-required">*</span>
            </label>
            <select
              ref={refs.subCategory}
              id="subCategory"
              name="subCategory"
              value={productData.subCategory}
              onChange={handleInputChange}
              className="create-product-component-select"
            >
              <option value="">Choose a subcategory</option>
              {availableSubCategories.map((subCat) => (
                <option key={subCat.id} value={subCat.title}>
                  {subCat.title}
                </option>
              ))}
            </select>
            {formErrors.subCategory && (
              <p className="form-error-message">{formErrors.subCategory}</p>
            )}
          </div>
        )}

        {/* On Sale Toggle */}
        <div
          className={`create-product-component-switch-group ${
            productData.isOnSale ? 'create-product-component-switch-group-active' : ''
          }`}
        >
          <label className="create-product-component-switch-label">On Sale</label>
          <Switch
            checked={productData.isOnSale}
            onChange={(checked) =>
              setProductData((prev) => ({ ...prev, isOnSale: checked }))
            }
            className={`create-product-component-switch ${
              productData.isOnSale
                ? 'create-product-component-switch-active'
                : 'create-product-component-switch-inactive'
            }`}
          >
            <span
              className={`create-product-component-switch-toggle ${
                productData.isOnSale
                  ? 'create-product-component-switch-toggle-active'
                  : 'create-product-component-switch-toggle-inactive'
              }`}
            />
          </Switch>
        </div>

        {/* Price & Discount */}
        <div className="create-product-component-form-section create-product-component-form-group-row">
          <div className="create-product-component-form-group">
            <label htmlFor="original-price" className="create-product-component-form-label">
              {productData.isOnSale ? 'Original Price' : 'Price'}{' '}
              <span className="create-product-component-form-required">*</span>
            </label>
            <input
              ref={refs.originalPrice}
              type="number"
              name="originalPrice"
              id="original-price"
              value={productData.originalPrice}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder={
                productData.isOnSale ? 'Enter the original price...' : 'Enter the price...'
              }
            />
            {formErrors.originalPrice && (
              <p className="form-error-message">{formErrors.originalPrice}</p>
            )}
          </div>
          {productData.isOnSale && (
            <div className="create-product-component-form-group">
              <label htmlFor="discount-price" className="create-product-component-form-label">
                Discount Price
              </label>
              <input
                ref={refs.discountPrice}
                type="number"
                name="discountPrice"
                id="discount-price"
                value={productData.discountPrice}
                onChange={handleInputChange}
                className="create-product-component-form-input"
                placeholder="Enter the discounted price..."
              />
              {formErrors.discountPrice && (
                <p className="form-error-message">{formErrors.discountPrice}</p>
              )}
            </div>
          )}
        </div>

        {/* Stock & Low Inventory */}
        <div className="create-product-component-form-section create-product-component-form-group-row">
          <div className="create-product-component-form-group">
            <label htmlFor="stock" className="create-product-component-form-label">
              Stock <span className="create-product-component-form-required">*</span>
            </label>
            <input
              ref={refs.stock}
              type="number"
              name="stock"
              id="stock"
              value={productData.stock}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Enter the stock amount..."
            />
            {formErrors.stock && (
              <p className="form-error-message">{formErrors.stock}</p>
            )}
          </div>
          <div className="create-product-component-form-group">
            <label htmlFor="low-inventory-threshold" className="create-product-component-form-label">
              Low Inventory Threshold
            </label>
            <input
              ref={refs.lowInventoryThreshold}
              type="number"
              name="lowInventoryThreshold"
              id="low-inventory-threshold"
              value={productData.lowInventoryThreshold}
              onChange={handleInputChange}
              className="create-product-component-form-input"
              placeholder="Set the low inventory threshold..."
            />
            {formErrors.lowInventoryThreshold && (
              <p className="form-error-message">{formErrors.lowInventoryThreshold}</p>
            )}
          </div>
        </div>

        {/* Tax Code */}
        <div className="create-product-component-form-section">
          <TaxCodeSelector
            selectedTaxCode={productData.taxCode}
            updateTaxCode={(newTaxCode) =>
              setProductData({ ...productData, taxCode: newTaxCode })
            }
            returnOnlyId={true}
          />
          {formErrors.taxCode && (
            <p className="form-error-message">{formErrors.taxCode}</p>
          )}
        </div>

        {/* Tags */}
        <div className="create-product-component-form-section">
          <label htmlFor="tagSelector" className="tag-selector-label">
            Tag Selector <span className="tax-code-selector-required">*</span>
          </label>
          <TagSelector
            selectedTags={productData.tags}
            updateTags={(newTags) => {
              setProductData({ ...productData, tags: newTags });
            }}
          />
        </div>

        {/* Pickup Toggle */}
        <div
          className={`create-product-component-switch-group ${
            productData.availableForPickup
              ? 'create-product-component-switch-group-active'
              : ''
          }`}
        >
          <label className="create-product-component-switch-label">
            Available for Pickup
          </label>
          <Switch
            checked={productData.availableForPickup}
            onChange={(checked) =>
              setProductData((prev) => ({
                ...prev,
                availableForPickup: checked,
              }))
            }
            className={`create-product-component-switch ${
              productData.availableForPickup
                ? 'create-product-component-switch-active'
                : 'create-product-component-switch-inactive'
            }`}
          >
            <span
              className={`create-product-component-switch-toggle ${
                productData.availableForPickup
                  ? 'create-product-component-switch-toggle-active'
                  : 'create-product-component-switch-toggle-inactive'
              }`}
            />
          </Switch>
        </div>

        {/* Delivery Toggle */}
        <div
          className={`create-product-component-switch-group ${
            productData.availableForDelivery
              ? 'create-product-component-switch-group-active'
              : ''
          }`}
        >
          <label className="create-product-component-switch-label">
            Available for Delivery
          </label>
          <Switch
            checked={productData.availableForDelivery}
            onChange={(checked) =>
              setProductData((prev) => ({
                ...prev,
                availableForDelivery: checked,
              }))
            }
            className={`create-product-component-switch ${
              productData.availableForDelivery
                ? 'create-product-component-switch-active'
                : 'create-product-component-switch-inactive'
            }`}
          >
            <span
              className={`create-product-component-switch-toggle ${
                productData.availableForDelivery
                  ? 'create-product-component-switch-toggle-active'
                  : 'create-product-component-switch-toggle-inactive'
              }`}
            />
          </Switch>
        </div>

        {/* Weight Unit & Value */}
        <div className="create-product-component-form-section">
          <label ref={refs.weightUnitOfMeasurement} htmlFor="weight-unit-of-measurement" className="create-product-component-form-label">
            Weight Unit of Measurement
          </label>
          <select
            id="weight-unit-of-measurement"
            name="weight-unit-of-measurement"
            value={productData.weightUnitOfMeasurement}
            onChange={(e) =>
              setProductData({ ...productData, weightUnitOfMeasurement: e.target.value })
            }
            className="create-product-component-select"
          >
            <option value="">Select Weight Unit</option>
            <option value="g">Grams (g)</option>
            <option value="lbs">Pounds (lbs)</option>
            <option value="kg">Kilograms (kg)</option>
          </select>
          {formErrors.weightUnitOfMeasurement && (
                <p className="form-error-message">{formErrors.weightUnitOfMeasurement}</p>
              )}
        </div>
        <div className="create-product-component-form-section">
          <label ref={refs.weight} htmlFor="weight" className="create-product-component-form-label">
            Weight <span className="create-product-component-form-required">*</span>
          </label>
          <input
            type="number"
            name="weight"
            id="weight"
            value={productData.weight}
            onChange={handleInputChange}
            className="create-product-component-form-input"
            placeholder="Enter the product weight..."
            required
          />
          {formErrors.weight && (
                <p className="form-error-message">{formErrors.weight}</p>
              )}
        </div>

        {/* Custom Dimensions vs. Standard Package */}
        <Switch.Group
          as="div"
          className={`create-product-component-switch-group ${
            useCustomDimensions ? 'create-product-component-switch-group-active' : ''
          }`}
        >
          <span className="create-product-component-switch-label">Use Custom Dimensions</span>
          <Switch
            checked={useCustomDimensions}
            onChange={setUseCustomDimensions}
            className={`create-product-component-switch ${
              useCustomDimensions
                ? 'create-product-component-switch-active'
                : 'create-product-component-switch-inactive'
            }`}
          >
            <span
              className={`create-product-component-switch-toggle ${
                useCustomDimensions
                  ? 'create-product-component-switch-toggle-active'
                  : 'create-product-component-switch-toggle-inactive'
              }`}
            />
          </Switch>
        </Switch.Group>

        {useCustomDimensions ? (
          <>
            <div className="create-product-component-form-section">
              <label
                ref={refs.dimensionUnitOfMeasurement}
                htmlFor="dimension-unit-of-measurement"
                className="create-product-component-form-label"
              >
                Dimension Unit of Measurement
              </label>
              <select
                id="dimension-unit-of-measurement"
                name="dimension-unit-of-measurement"
                value={productData.dimensionUnitOfMeasurement}
                onChange={(e) =>
                  setProductData({
                    ...productData,
                    dimensionUnitOfMeasurement: e.target.value
                  })
                }
                className="create-product-component-select"
              >
                <option value="">Select Dimension Unit</option>
                <option value="cm">Centimeters (cm)</option>
                <option value="in">Inches (in)</option>
                <option value="m">Meters (m)</option>
              </select>
              {formErrors.dimensionUnitOfMeasurement && (
                    <p className="form-error-message">{formErrors.dimensionUnitOfMeasurement}</p>
                  )}
            </div>
            <div className="create-product-component-measurement-section">
              <div className="create-product-component-form-section">
                <label htmlFor="length" className="create-product-component-form-label">
                  Length <span className="create-product-component-form-required">*</span>
                </label>
                <input
                  type="number"
                  name="length"
                  id="length"
                  value={productData.length}
                  onChange={handleInputChange}
                  className="create-product-component-form-input"
                  placeholder="Enter the product length..."
                  required
                />
                 {formErrors.length && (
                      <p className="form-error-message">{formErrors.length}</p>
                    )}
              </div>
              <div className="create-product-component-form-section">
                <label ref={refs.width} htmlFor="width" className="create-product-component-form-label">
                  Width <span className="create-product-component-form-required">*</span>
                </label>
                <input
                  type="number"
                  name="width"
                  id="width"
                  value={productData.width}
                  onChange={handleInputChange}
                  className="create-product-component-form-input"
                  placeholder="Enter the product width..."
                  required
                />
                {formErrors.width && (
                      <p className="form-error-message">{formErrors.width}</p>
                    )}
              </div>
              <div className="create-product-component-form-section">
                <label ref={refs.height} htmlFor="height" className="create-product-component-form-label">
                  Height <span className="create-product-component-form-required">*</span>
                </label>
                <input
                  type="number"
                  name="height"
                  id="height"
                  value={productData.height}
                  onChange={handleInputChange}
                  className="create-product-component-form-input"
                  placeholder="Enter the product height..."
                  required
                />
                {formErrors.height && (
                      <p className="form-error-message">{formErrors.height}</p>
                    )}
              </div>
            </div>
          </>
        ) : (
          <StandardPackageSelector
            // If you have dynamic carriers, pass them in from the seller data
            carriers={seller?.carriers}
            setLength={(val) => setProductData((prev) => ({ ...prev, length: val }))}
            setWidth={(val) => setProductData((prev) => ({ ...prev, width: val }))}
            setHeight={(val) => setProductData((prev) => ({ ...prev, height: val }))}
            setDimensionUnitOfMeasurement={(val) =>
              setProductData((prev) => ({
                ...prev,
                dimensionUnitOfMeasurement: val,
              }))
            }
            // New callback to capture the entire package
            onPackageSelected={(pkg) => setSelectedStandardPackage(pkg)}
          />
        )}

        {/* Barcode */}
        <div className="create-product-component-upload-section">
          <label htmlFor="barcode-upload" className="create-product-component-upload-label">
            Upload Barcode
          </label>
          <div
            className="create-product-component-dropzone"
            onDrop={(e) => handleImageChange(e, 'barcode')}
            onDragOver={(e) => e.preventDefault()}
          >
            <div className="create-product-component-dropzone-content">
              <PhotoIcon
                className="create-product-component-dropzone-icon"
                aria-hidden="true"
              />
              <label htmlFor="barcode-upload" className="create-product-component-dropzone-label">
                <span>Drag and drop your barcodes here or click to upload</span>
                <input
                  type="file"
                  id="barcode-upload"
                  multiple
                  onChange={(e) => handleImageChange(e, 'barcode')}
                  className="sr-only"
                />
              </label>
              <p className="create-product-component-dropzone-note">
                PNG, JPG, GIF up to 10MB
              </p>
            </div>
          </div>
          <div className="create-product-component-barcode-previews">
            {barcodePreviews.map((barcodeUrl, index) => (
              <div key={index} className="create-product-component-barcode-preview">
                <img
                  src={barcodeUrl}
                  alt={`Barcode ${index}`}
                  className="create-product-component-barcode-preview-img"
                />
                <button
                  onClick={() =>
                    handleRemoveImage(index, barcodeImage, setBarcodeImage, setBarcodePreviews)
                  }
                  className="create-product-component-barcode-preview-remove"
                  aria-label="Remove barcode"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Is Edible */}
        <div
          className={`create-product-component-switch-group ${
            productData.isEdible ? 'create-product-component-switch-group-active' : ''
          }`}
        >
          <label className="create-product-component-switch-label">Is Edible</label>
          <Switch
            checked={productData.isEdible}
            onChange={(checked) => setProductData((prev) => ({ ...prev, isEdible: checked }))}
            className={`create-product-component-switch ${
              productData.isEdible
                ? 'create-product-component-switch-active'
                : 'create-product-component-switch-inactive'
            }`}
          >
            <span
              className={`create-product-component-switch-toggle ${
                productData.isEdible
                  ? 'create-product-component-switch-toggle-active'
                  : 'create-product-component-switch-toggle-inactive'
              }`}
            />
          </Switch>
        </div>

        {/* Nutrition Info (only if edible) */}
        {productData.isEdible && (
          <div className="create-product-component-upload-section">
            <label htmlFor="nutrition-info-upload" className="create-product-component-upload-label">
              Upload Nutrition Info Image
            </label>
            <div
              className="create-product-component-dropzone"
              onDrop={(e) => handleImageChange(e, 'nutritionInfoImage')}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="create-product-component-dropzone-content">
                <PhotoIcon
                  className="create-product-component-dropzone-icon"
                  aria-hidden="true"
                />
                <label
                  htmlFor="nutrition-info-upload"
                  className="create-product-component-dropzone-label"
                >
                  <span>
                    Drag and drop your nutrition info images here or click to upload
                  </span>
                  <input
                    type="file"
                    id="nutrition-info-upload"
                    multiple
                    onChange={(e) => handleImageChange(e, 'nutritionInfoImage')}
                    className="sr-only"
                  />
                </label>
                <p className="create-product-component-dropzone-note">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
            <div className="create-product-component-image-previews">
              {nutritionInfoImagePreviews.map((imageUrl, index) => (
                <div key={index} className="create-product-component-image-preview">
                  <img
                    src={imageUrl}
                    alt={`Nutrition Info ${index}`}
                    className="create-product-component-image-preview-img"
                  />
                  <button
                    onClick={() =>
                      handleRemoveImage(
                        index,
                        nutritionInfoImage,
                        setNutritionInfoImage,
                        setNutritionInfoImagePreviews
                      )
                    }
                    className="create-product-component-image-preview-remove"
                    aria-label="Remove image"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* VARIABLE PRODUCT SWITCH */}
        <Switch.Group as="div" className="edit-product-component-switch-group">
          <span className="edit-product-component-switch-label">Variable Product</span>
          <Switch
            checked={isVariableProduct}
            onChange={handleProductTypeSwitch}
            className={`edit-product-component-switch ${
              isVariableProduct
                ? 'edit-product-component-switch-active'
                : 'edit-product-component-switch-inactive'
            }`}
          >
            <span
              className={`edit-product-component-switch-toggle ${
                isVariableProduct
                  ? 'edit-product-component-switch-toggle-active'
                  : 'edit-product-component-switch-toggle-inactive'
              }`}
            />
          </Switch>
        </Switch.Group>

        {/* VARIANTS (only if variable) */}
        {isVariableProduct && (
          <Variants
            variants={variants}
            setVariants={setVariants}
            isVariableProduct={isVariableProduct}
            productWeight={productData.weight}
            weightUnitOfMeasurement={productData.weightUnitOfMeasurement}
            productDimensions={{
              length: productData.length,
              width: productData.width,
              height: productData.height,
            }}
            dimensionUnitOfMeasurement={productData.dimensionUnitOfMeasurement}
            reportErrors={handleVariantErrors}
            formErrors={formErrors}
            hasSubmitted={hasSubmitted}
          />
        )}

        {/* SUBMIT BUTTON */}
        <div className="create-product-component-submit">
          <button type="submit" className="create-product-component-submit-button">
            Update Product
          </button>
        </div>
      </form>

      {/* IMAGE CROPPER MODAL */}
      {showImageCropper && selectedImageForCrop &&
        ReactDOM.createPortal(
          <ImageCropperModal
            updateAvatar={handleImageCropCompletion}
            closeModal={() => {
              setShowImageCropper(false);
              setSelectedImageForCrop(null);
              setFieldNameBeingCropped('');
            }}
            initialImage={selectedImageForCrop}
            aspectRatio={1}
            minWidth={400}
            isAvatar={false}
            isPost={false}
            isLogo={false}
          />,
          document.body
        )
      }
    </div>
  );
};

export default EditProduct;



