import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMyConnections } from "../../redux/actions/connections";
import OtherFollowers from './OtherFollowers';
import OtherFollowing from './OtherFollowing';
import { setFriendListPanelOpen } from '../../state/uiSlice';
import { toast } from 'react-toastify';
import { loadSeller } from '../../redux/actions/user';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ConnectionsPanel = () => {
  const isFriendListPanelOpen = useSelector((state) => state.ui.isFriendListPanelOpen);
  const [activeTab, setActiveTab] = useState('Followers');
  const dispatch = useDispatch();

  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { user } = useSelector((state) => state.user);
  const seller = useSelector((state) => state.seller.activeSeller);
  const profileId = currentProfile === 'User' ? user?._id : seller?._id;
  const profileType = currentProfile === 'User' ? 'User' : 'Shop';

  const {
    hasFetchedMyFollowers,
    hasFetchedMyFollowing,
    myFollowers,
    myFollowing,
    isLoadingMyFollowers,
    isLoadingMyFollowing,
    error,
  } = useSelector((state) => state.connections);

  useEffect(() => {
    if (currentProfile !== 'User' && !seller) {
      dispatch(loadSeller());
    }
  }, [dispatch, currentProfile, seller]);

  const memoizedFollowers = useMemo(() => myFollowers, [myFollowers]);
  const memoizedFollowing = useMemo(() => myFollowing, [myFollowing]);

  useEffect(() => {
    if (!profileId || !profileType) return;

    if (activeTab === 'Followers' && !hasFetchedMyFollowers) {
      dispatch(fetchMyConnections(profileId, profileType, 'followers'))
        .catch((err) => {
          console.error('Error fetching followers:', err);
          toast.error('Failed to fetch followers.');
        });
    } else if (activeTab === 'Following' && !hasFetchedMyFollowing) {
      dispatch(fetchMyConnections(profileId, profileType, 'following'))
        .catch((err) => {
          console.error('Error fetching following:', err);
          toast.error('Failed to fetch following.');
        });
    }
  }, [dispatch, profileId, profileType, activeTab, hasFetchedMyFollowers, hasFetchedMyFollowing]);

  const isLoadingData = activeTab === 'Followers' ? isLoadingMyFollowers : isLoadingMyFollowing;
  const hasFetchedData = activeTab === 'Followers' ? hasFetchedMyFollowers : hasFetchedMyFollowing;
  const data = activeTab === 'Followers' ? memoizedFollowers : memoizedFollowing;

  const tabs = [
    { name: 'Followers', current: activeTab === 'Followers' },
    { name: 'Following', current: activeTab === 'Following' },
  ];

  const handleClose = () => {
    dispatch(setFriendListPanelOpen(false));
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // If the panel is not open, render nothing
  if (!isFriendListPanelOpen) {
    return null;
  }

  return (
    <div 
      className="followers-following-panel-container"
      role="dialog" 
      aria-modal="true"
      aria-label="Social Connections"
    >
      <div 
        className="followers-following-panel-overlay" 
        onClick={handleClose} 
        aria-hidden="true"
      />
      <div className="followers-following-panel-wrapper">
        <div className="followers-following-panel-inner">
          <div className="followers-following-panel-content">
            <div className="followers-following-panel-panel">
              <div className="followers-following-panel-body">
                <div className="followers-following-panel-header">
                  <h2 className="followers-following-panel-title">Social Connections</h2>
                  <div className="followers-following-panel-close-button-wrapper">
                    <button
                      type="button"
                      className="followers-following-panel-close-button"
                      onClick={handleClose}
                      aria-label="Close Connections Panel"
                    >
                      <XMarkIcon className="followers-following-panel-icon" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="followers-following-panel-tabs">
                  <nav className="followers-following-panel-tabs-nav" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <button
                        key={tab.name}
                        className={classNames(
                          tab.current ? 'followers-following-panel-tab-active' : 'followers-following-panel-tab-inactive',
                          'followers-following-panel-tab'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                        onClick={() => handleTabClick(tab.name)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') handleTabClick(tab.name);
                        }}
                      >
                        {tab.name}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="followers-following-panel-content-body">
                {isLoadingData && (
                  <div className="followers-following-panel-loading">
                    <p>Loading {activeTab}...</p>
                  </div>
                )}
                {error && (
                  <div className="followers-following-panel-error">
                    <p>Error: {error}</p>
                  </div>
                )}
                {!isLoadingData && !error && hasFetchedData && data.length === 0 && (
                  <div className="followers-following-panel-no-data">
                    <p>No {activeTab.toLowerCase()} found.</p>
                  </div>
                )}
                {!isLoadingData && !error && hasFetchedData && data.length > 0 && (
                  <>
                    {activeTab === 'Followers' && (
                      <OtherFollowers
                        followers={memoizedFollowers}
                        isLoading={isLoadingMyFollowers}
                        error={error}
                      />
                    )}
                    {activeTab === 'Following' && (
                      <OtherFollowing
                        following={memoizedFollowing}
                        isLoading={isLoadingMyFollowing}
                        error={error}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionsPanel;
