import React, { useState, useEffect } from 'react';
import ProductCard from '../Route/ProductCard/ProductCard';

const MarketplaceProductCarousel = ({ data }) => {
  // Ensure data is always defined as an array
  const productData = Array.isArray(data) ? data : [];

  // **State to hold shuffled products**
  const [shuffledProducts, setShuffledProducts] = useState([]);

  // Determine the number of products per slide based on screen width
  const [productsPerSlide, setProductsPerSlide] = useState(getProductsPerSlide());

  // Update productsPerSlide on window resize
  useEffect(() => {
    const handleResize = () => {
      setProductsPerSlide(getProductsPerSlide());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getProductsPerSlide() {
    if (window.innerWidth >= 1024) return 4;
    if (window.innerWidth >= 768) return 2;
    return 1;
  }

  // **Shuffle products when component mounts or when data changes**
  useEffect(() => {
    const shuffleArray = (array) => {
      const shuffled = [...array];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    };

    setShuffledProducts(shuffleArray(productData));
  }, [productData]);

  // **Use shuffledProducts instead of productData**
  // Group products into slides
  const slides = [];
  for (let i = 0; i < shuffledProducts.length; i += productsPerSlide) {
    const slideItems = shuffledProducts.slice(i, i + productsPerSlide);
    // Only include slides that are complete
    if (slideItems.length === productsPerSlide) {
      slides.push(slideItems);
    }
  }

  const [currentSlide, setCurrentSlide] = useState(0);

  const noSlides = slides.length === 0;

  const goToPrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  return noSlides ? (
    <h1 className="marketplace-product-carousel-no-products-text">
      No retail products found!
    </h1>
  ) : (
    <div className="marketplace-product-carousel-container">
      <div className="marketplace-product-carousel-wrapper">
        {slides.length > 1 && (
          <button
            className="marketplace-product-carousel-nav-button prev"
            onClick={goToPrevSlide}
          >
            &#10094;
          </button>
        )}
        <div className="marketplace-product-carousel-slide">
          {slides[currentSlide] &&
            slides[currentSlide].map((product, idx) => (
              <ProductCard data={product} key={product._id || idx} />
            ))}
        </div>
        {slides.length > 1 && (
          <button
            className="marketplace-product-carousel-nav-button next"
            onClick={goToNextSlide}
          >
            &#10095;
          </button>
        )}
      </div>
      
    </div>
  );
};

export default MarketplaceProductCarousel;


