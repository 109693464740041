import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { XMarkIcon } from '@heroicons/react/20/solid';
import AdFormatIconRow from './AdFormatIconRow';
import DraggableImage from './DraggableImage';
import ShopImagesModal from '../Shop/ShopImagesModal';
import ImageCropperModal from '../Image/ImageCropperModal';

const ShopAdSetModal = ({ isOpen, onClose, onSave, adFormats, shopImage, shopName, shopAvatar }) => {
    const [adImages, setAdImages] = useState({});
    const [adFiles, setAdFiles] = useState({});
    const [adPositions, setAdPositions] = useState({});
    const [adScales, setAdScales] = useState({});
    const [editMode, setEditMode] = useState({});
    const [selectedFormat, setSelectedFormat] = useState(adFormats[0]);
    const [adImageNames, setAdImageNames] = useState({});
    const [isUploaded, setIsUploaded] = useState({});
    const [isShopImagesModalOpen, setIsShopImagesModalOpen] = useState(false);
    const [isImageCropperOpen, setIsImageCropperOpen] = useState(false);
    const [originalImages, setOriginalImages] = useState({});
    const [previousCrops, setPreviousCrops] = useState({});

    useEffect(() => {
        if (isOpen) {
            const initialAdImages = {};
            const initialAdFiles = {};
            const initialAdPositions = {};
            const initialAdScales = {};
            const initialAdImageNames = {};
            const initialIsUploaded = {};
            const initialOriginalImages = {}; 
            adFormats.forEach((format) => {
                initialAdImages[format] = adImages[format] || shopImage; 
                initialAdPositions[format] = adPositions[format] || { x: 0, y: 0 };
                initialAdImageNames[format] = adImageNames[format] || '';
                initialAdScales[format] = adScales[format] || 1;
                initialIsUploaded[format] = isUploaded[format] || false;
            });
            setAdImages(initialAdImages);
            setAdFiles(initialAdFiles);
            setAdPositions(initialAdPositions);
            setAdScales(initialAdScales);
            setAdImageNames(initialAdImageNames);
            setIsUploaded(initialIsUploaded);
            setOriginalImages(initialOriginalImages);
            setEditMode(adFormats.reduce((acc, format) => {
                acc[format] = false;
                return acc;
            }, {}));
            setSelectedFormat(adFormats[0]); 
        }
    }, [isOpen, adFormats, shopImage]);

    const handleImageSelect = (selectedImage) => {
        if (selectedImage) {
            setAdImages((prev) => ({
                ...prev,
                [selectedFormat]: selectedImage.url,  
            }));
            setAdFiles((prev) => ({
                ...prev,
                [selectedFormat]: selectedImage.file,  
            }));
            setOriginalImages((prev) => ({
                ...prev,
                [selectedFormat]: selectedImage.url,  
            }));
            setIsShopImagesModalOpen(false);
            setIsImageCropperOpen(true);
        }
    };

    useEffect(() => {
        if (originalImages[selectedFormat]) {
            console.log('Updated Original Images:', originalImages[selectedFormat]);
        }
    }, [originalImages, selectedFormat]);

    const handleSave = () => {
        const adSetImages = { ...adImages };  

        Object.keys(adFiles).forEach((format) => {
            if (adFiles[format]) {
                adSetImages[format] = adFiles[format];  
            }
        });

        onSave({
            images: adSetImages,  
            files: adFiles,  
            positions: adPositions, 
            scales: adScales,
        });

        onClose();
    };

    const handleCroppedImage = (croppedImage, crop) => {

        if (croppedImage instanceof File) {
          setAdImages((prev) => ({
            ...prev,
            [selectedFormat]: URL.createObjectURL(croppedImage), 
          }));
          setAdFiles((prev) => ({
            ...prev,
            [selectedFormat]: croppedImage,
          }));
          setOriginalImages((prev) => ({
            ...prev,
            [selectedFormat]: originalImages[selectedFormat],
        }));
        setPreviousCrops((prev) => ({
            ...prev,
            [selectedFormat]: crop, 
        }));
        } else {
          setAdImages((prev) => ({
            ...prev,
            [selectedFormat]:  URL.createObjectURL(croppedImage), 
          }));
          setAdFiles((prev) => ({
            ...prev,
            [selectedFormat]: null, 
          }));
          setOriginalImages((prev) => ({
            ...prev,
            [selectedFormat]: originalImages[selectedFormat], 
        }));
        setPreviousCrops((prev) => ({
            ...prev,
            [selectedFormat]: crop, 
        }));
        }
        setIsImageCropperOpen(false); 
      };

    if (!isOpen) return null;

    const formatDetails = {
        'Top Banner Ad': {
            label: 'Top Banner',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087610/top_banner_ad-2_hll7nm.svg',
            viewType: 'desktop',
            aspectRatio: 16 / 1,
            minWidth: 1080,
        },
        'Top Banner Mobile Ad': {
            label: 'Top Banner (Mobile)',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087610/top_banner_ad-2_hll7nm.svg',
            viewType: 'mobile',
            aspectRatio: 5 / 1,
            minWidth: 500,
        },
        'Banner Ad': {
            label: 'Bottom Banner',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087984/bottom_banner_ad-2_srdajl.svg',
            viewType: 'desktop',
            aspectRatio: 12 / 1,
            minWidth: 1080,
        },
        'Banner Mobile Ad': {
            label: 'Bottom Banner (Mobile)',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087984/bottom_banner_ad-2_srdajl.svg',
            viewType: 'mobile',
            aspectRatio: 4 / 1,
            minWidth: 500,
        },
        'Sponsored Shops': {
            label: 'Sidebar Ad',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727089158/sidebar_ad-2_flagic.svg',
            viewType: 'both',
            aspectRatio: 4 / 5,
            minWidth: 400,
        },
        'Sponsored Shop Carousel': {
            label: 'Single Carousel',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090221/single_carousel_ad-2_hxmkw6.svg',
            viewType: 'desktop',
            aspectRatio: 3 / 1,
            minWidth: 1080,
        },
       'Dual Carousel': {
            label: 'Dual Carousel',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727089990/dual_carousel_ad-2_qusoev.svg',
            viewType: 'both',
            aspectRatio: 1 / 1,
            minWidth: 500,
        },
        'Quad Carousel': {
            label: 'Quad Carousel',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090138/quad_carousel_ad-2_vmsayt.svg',
            viewType: 'both',
            aspectRatio: 16 / 9,
            minWidth: 500,
        },
        'Sponsored Feed Ads': {
        label: 'Feed Ad',
        icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090568/feed_ad_efwtaf.svg',
        viewType: 'both',
        aspectRatio: 1 / 1,
        minWidth: 500,
    },
    };

    return (
        <div className="ad-set-modal-overlay">
            <div className="ad-set-modal">
                <button onClick={onClose} className="close-modal-button">
                    <AiOutlineClose />
                </button>
                <h2>Customize Ad Set</h2>

                {/* Ad Format Icon Row */}
                <AdFormatIconRow
                    formats={adFormats.map((format) => ({
                        name: format,
                        label: formatDetails[format]?.label || format,
                        icon: formatDetails[format]?.icon || 'default_icon_url',
                        viewType: formatDetails[format]?.viewType || 'desktop',
                    }))}
                    selectedFormat={selectedFormat}
                    onFormatSelect={setSelectedFormat}
                />

                {/* Content based on selected format */}
                <div className="ad-format-section">
                    <h3>{selectedFormat}</h3>
                    <div className="ad-format-preview">
                        <div className="ad-format-content">
                        <button onClick={() => setIsShopImagesModalOpen(true)} className="open-shop-images-modal-button">
                    Select Image from Shop
                </button>
                <div className="ad-format-controls">
                        
                                        <button 
                                            onClick={() => setIsImageCropperOpen(true)} 
                                            
                                            className="ad-set-modal-edit-button"
                                        >
                                            Crop & Position Image
                                        </button>
                                        </div>

                                        {isImageCropperOpen && (
                                            <ImageCropperModal
                                                closeModal={() => setIsImageCropperOpen(false)}
                                                updateAvatar={(croppedImage, crop) => handleCroppedImage(croppedImage, crop)}
                                                initialImage={originalImages[selectedFormat] || adFiles[selectedFormat] || adImages[selectedFormat]}
                                                aspectRatio={formatDetails[selectedFormat]?.aspectRatio} 
                                                minWidth={formatDetails[selectedFormat]?.minWidth}
                                                previousCrop={previousCrops[selectedFormat]}
                                                isAvatar={false}
                                                isPost={false}
                                                isLogo={false} 
                                            />
                                        )}

                {/* Shop Images Modal */}
                <ShopImagesModal
                    isOpen={isShopImagesModalOpen}
                    onClose={() => setIsShopImagesModalOpen(false)}
                    onImageSelect={handleImageSelect}
                />

                        {selectedFormat === 'Top Banner Ad' && (
                            <div className="ad-preview-top-banner-container">
                                <div className="ad-preview-top-banner-wrapper">
                                <div className="ad-preview-top-banner-content">
                                    <div className="ad-preview-top-banner-image-container">
                                    <img
                                        src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                        alt="Product"
                                        className="ad-preview-top-banner-image"
                                    />
                                    </div>
                                    <div className="ad-preview-top-banner-overlay">
                                    <div className="ad-preview-top-banner-text">
                                        <strong className="ad-preview-top-banner-ad-link">{shopName}</strong>
                                        <span className="ad-preview-top-banner-ad-link"> – Explore Now &rarr;</span>
                                    </div>
                                    <button className="ad-preview-top-banner-ad-dismiss">
                                        <XMarkIcon className="ad-preview-top-banner-ad-icon" aria-hidden="true" />
                                        <span className="sr-only">Dismiss</span>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            )}

                                {selectedFormat === 'Top Banner Mobile Ad' && (
                                    <div className="ad-preview-mobile-container">
                                <div className="ad-preview-top-banner-mobile-container">
                                    <div className="ad-preview-top-banner-content-mobile">
                                        <div className="ad-preview-top-banner-image-container">
                                                <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                    alt="Product"
                                                    className="ad-preview-top-banner-image"
                                                />
                                        </div>
                                        <div className="ad-preview-top-banner-text">
                                            <strong className="ad-preview-top-banner-ad-link">{shopName}</strong>
                                            <span className="ad-preview-top-banner-ad-link"> – Explore Now &rarr;</span>
                                        </div>
                                        <button className="ad-preview-top-banner-ad-dismiss">
                                            <XMarkIcon className="ad-preview-top-banner-ad-icon" aria-hidden="true" />
                                            <span className="sr-only">Dismiss</span>
                                        </button>
                                    </div>
                                </div>
                                </div>
                            )}

                            {selectedFormat === 'Banner Ad' && (
                                <div className="ad-preview-banner-ad-container">
                                    <div className="ad-preview-banner-ad-image-container">
                                            <img
                                            src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                alt="Product"
                                                className="ad-preview-banner-ad-image"
                                            />
                                    </div>
                                    <div className="ad-preview-banner-ad-content">
                                        <strong className="ad-preview-banner-ad-link">{shopName}</strong>
                                        <span className="ad-preview-banner-ad-link"> – Explore Now &rarr;</span>
                                        <button className="ad-preview-banner-ad-dismiss">
                                            <XMarkIcon className="ad-preview-banner-ad-icon" aria-hidden="true" />
                                            <span className="sr-only">Dismiss</span>
                                        </button>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Banner Mobile Ad' && (
                                     <div className="ad-preview-mobile-container">
                                    <div className="ad-preview-banner-mobile-ad-container">
                                        <div className="ad-preview-banner-mobile-ad-image-container">
                                                <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                    alt="Product"
                                                    className="ad-preview-banner-mobile-ad-image"
                                                />
                                        </div>
                                        <div className="ad-preview-banner-mobile-ad-content">
                                            <strong className="ad-preview-banner-mobile-ad-link">{shopName}</strong>
                                            <span className="ad-preview-banner-mobile-ad-link"> – Explore Now &rarr;</span>
                                            <button className="ad-preview-banner-mobile-ad-dismiss">
                                                <XMarkIcon className="ad-preview-banner-mobile-ad-icon" aria-hidden="true" />
                                                <span className="sr-only">Dismiss</span>
                                            </button>
                                        </div>
                                    </div>
                                    </div>
                                )}

                            {/* Display content based on the selected format */}
                            {selectedFormat === 'Sponsored Shops' && (
                                <div className="ad-preview-sponsored-shops-container">
                                    <div className="ad-preview-sponsored-shops-list">
                                        <div className="ad-preview-sponsored-shops-item">
                                            <div className="ad-preview-sponsored-shops-image-container">
                                                    <img
                                                        src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                        alt="Shop"
                                                        className="ad-preview-sponsored-shops-image"
                                                    />
                                            </div>
                                            <div className="ad-preview-sponsored-shops-info">
                                            {shopAvatar && (
                                                <img 
                                                    src={shopAvatar} 
                                                    alt={shopName} 
                                                    className="sponsored-shops-widget-avatar"
                                                />
                                            )}
                                                <div className="ad-preview-sponsored-shops-cta">
                                                    <strong className="ad-preview-sponsored-shops-title">{shopName}</strong>
                                                    <span className="ad-preview-sponsored-shops-link"> Visit Store &rarr;</span>
                                                </div>
                                                <button className="ad-preview-sponsored-shops-dismiss">
                                                    <XMarkIcon className="ad-preview-sponsored-shops-icon" aria-hidden="true" />
                                                    <span className="sr-only">Dismiss</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Sponsored Shop Carousel' && (
                                <div className="ad-preview-sponsored-shop-carousel-container">
                                    <div className="ad-preview-sponsored-shop-carousel-wrapper">
                                        <div className="ad-preview-sponsored-shop-carousel-item">
                                            <div className="ad-preview-sponsored-shop-carousel-banner-container">
                                                <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                    alt="Shop Banner"
                                                    className="ad-preview-sponsored-shop-carousel-banner"
                                                />
                                            </div>
                                            <div className="ad-preview-sponsored-shop-carousel-content">
                                                {shopAvatar && (
                                                <img 
                                                    src={shopAvatar} 
                                                    alt={shopName} 
                                                    className="ad-preview-sponsored-shop-carousel-avatar"
                                                />
                                            )}
                                                <h2 className="ad-preview-sponsored-shop-carousel-title">{shopName}</h2>
                                                <span className="ad-preview-sponsored-shop-carousel-button">Visit Store</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}



                            {selectedFormat === 'Dual Carousel' && (
                                <div className="ad-preview-dual-product-carousel-container">
                                    <div className="ad-preview-dual-product-carousel-wrapper">
                                    <div className="ad-preview-dual-product-carousel-item empty-box"></div>
                                        <div className="ad-preview-dual-product-carousel-item">
                                            <div className="ad-preview-dual-product-carousel-image-container">
                                                    <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-dual-product-carousel-image"
                                                    />
                                            </div>
                                            <div className="ad-preview-dual-product-carousel-content">
                                                <h2 className="ad-preview-dual-product-carousel-title">{shopName}</h2>
                                                
                                                <span className="ad-preview-dual-product-carousel-button">Explore Now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Quad Carousel' && (
                                <div className="ad-preview-quad-product-carousel-container">
                                    <div className="ad-preview-quad-product-carousel-wrapper">
                                    <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                            <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                            <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                        <div className="ad-preview-quad-product-carousel-item">
                                            <div className="ad-preview-quad-product-carousel-image-container">
                                                    <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-quad-product-carousel-image"
                                                    />
                                            </div>
                                            <div className="ad-preview-quad-product-carousel-content">
                                                <h2 className="ad-preview-quad-product-carousel-title">{shopName}</h2>
                                                
                                                <span className="ad-preview-quad-product-carousel-button">Explore Now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Sponsored Feed Ads' && (
                                <div className="ad-preview-sponsored-feed-products-container">
                                    <div className="ad-preview-sponsored-feed-products-wrapper">
                                        <div className="ad-preview-sponsored-feed-products-item">
                                            <div className="ad-preview-sponsored-feed-products-image-container">
                                                    <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-sponsored-feed-products-image"
                                                    />
                                            </div>
                                            <div className="ad-preview-sponsored-feed-products-content">
                                                <h2 className="ad-preview-sponsored-feed-products-title">{shopName}</h2>
                                                
                                                <span className="ad-preview-sponsored-feed-products-store-link">Explore Now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                </div>

                <button onClick={handleSave} className="save-ad-set-button">
                    Save Ad Set
                </button>
            </div>
        </div>
    );
};

export default ShopAdSetModal;



