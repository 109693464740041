import { React, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";
import _ from "lodash";
import Loader from "../Layout/Loader";
import OnboardingSteps from "./OnboardingSteps";
import ImageCropperModal from "../Image/ImageCropperModal";
import useWindowSize from "../../hooks/useWindowSize";
import OnboardingStepsMobile from "./OnboardingStepsMobile";
import ReCAPTCHA from "react-google-recaptcha"; 

const stateOptions = [
  { value: '', label: 'Select State' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

const ShopCreate = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [handle, setHandle] = useState("");
  const [handleAvailable, setHandleAvailable] = useState(true);
  const [checkingHandle, setCheckingHandle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [banner, setBanner] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);
  const windowSize = useWindowSize();
  const [successMessage, setSuccessMessage] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [agreedToTOS, setAgreedToTOS] = useState(false);

  // State for Avatar and Banner Croppers
  const [showCropper, setShowCropper] = useState(false); 
  const [showBannerCropper, setShowBannerCropper] = useState(false); 
  const [selectedFile, setSelectedFile] = useState(null); 

  const user = useSelector((state) => state.user.user);
  const userId = user?._id;

  useEffect(() => {
    return () => {
      if (avatarPreview) {
        URL.revokeObjectURL(avatarPreview);
      }
      if (bannerPreview) {
        URL.revokeObjectURL(bannerPreview);
      }
    };
  }, [avatarPreview, bannerPreview]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setShowCropper(true);
    }
  };

  const handleBannerInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setShowBannerCropper(true); 
    }
  };

  const validateHandle = (handle) => {
    const regex = /^[a-zA-Z0-9]*$/;
    return regex.test(handle) && !/\s/.test(handle) && handle.length <= 30;
  };

  const checkHandleAvailability = async (value) => {
    if (validateHandle(value)) {
      setHandle(value);
      setCheckingHandle(true);
      try {
        const response = await axios.post(`${server}/shop/check-handle`, {
          handle: value,
        });
        setHandleAvailable(true);
      } catch (error) {
        setHandleAvailable(false);
      } finally {
        setCheckingHandle(false);
      }
    } else {
      setHandleAvailable(false);
      toast.error(
        "Shop handle should not contain spaces and must be alphanumeric."
      );
    }
  };

  const debouncedCheckHandleAvailability = useCallback(
    _.debounce(checkHandleAvailability, 1000),
    []
  );

  const handleChangeHandle = (e) => {
    let value = e.target.value.toLowerCase();
    value = value.replace(/\s+/g, ""); // Remove spaces
    setHandle(value);
    if (value) {
      debouncedCheckHandleAvailability(value);
    } else {
      setHandleAvailable(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateHandle(handle)) {
      setHandleAvailable(false);
      return;
    }

    if (!handleAvailable) {
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("handle", handle);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("phoneNumber", phoneNumber);
      formData.append("zipCode", zipCode);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("state", stateCode);
      formData.append("owner", userId);
      formData.append("recaptchaToken", recaptchaToken);
      if (avatar) formData.append("avatar", avatar);
      if (banner) formData.append("banner", banner);

      await axios.post(`${server}/shop/create-shop`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      setSuccessMessage(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setName("");
      setHandle("");
      setEmail("");
      setPassword("");
      setPhoneNumber("");
      setZipCode("");
      setAddress("");
      setCity("");
      setStateCode("");
      setAvatar(null);
      setAvatarPreview(null);
      setBanner(null);
      setBannerPreview(null);
      setRecaptchaToken("");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to create shop");
    } finally {
      setIsLoading(false);
    }
  };

  // Update avatar after cropping
  const updateAvatar = (croppedFile) => {
    setAvatar(croppedFile);
    const previewUrl = URL.createObjectURL(croppedFile);
    setAvatarPreview(previewUrl);
  };

  // Update banner after cropping
  const updateBanner = (croppedFile) => {
    setBanner(croppedFile);
    const previewUrl = URL.createObjectURL(croppedFile);
    setBannerPreview(previewUrl);
  };

  const isMobile = windowSize.width <= 768; 

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <div className="shop-create-component-container">
      {isMobile ? (
                  <OnboardingStepsMobile currentStep={1} />
                ) : (
                  <OnboardingSteps currentStep={1} />
                )}

{successMessage && (
            <div className="shop-create-notification-container shop-create-notification-success">
              <div className="shop-create-notification-content">
                <div className="shop-create-notification-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="shop-create-notification-check-icon"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="shop-create-notification-message">
                  <p>
                    Thank you for starting the process of creating your shop
                    with us! To complete your registration, please check your
                    email inbox for an activation link. Simply click the link to
                    verify your information and activate your shop. If you don't
                    see the email, please check your spam or junk folder.
                  </p>
                </div>
                <button
                  className="shop-create-notification-close"
                  aria-label="Dismiss"
                  onClick={() => setSuccessMessage(false)} 
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="shop-create-notification-close-icon"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
      
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="shop-create-component-header">
            <h2>Register as a seller</h2>
          </div>
          <div className="shop-create-component-form-container">
            <div className="shop-create-component-form">
              <form className="shop-create-component-form-elements" onSubmit={handleSubmit}>
                <div className="shop-create-component-form-group">
                  <label htmlFor="name">Shop Name</label>
                  <input
                    type="text"
                    name="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="shop-create-component-input"
                  />
                </div>
                <div className="shop-create-component-form-group">
                  <label htmlFor="handle">Shop Handle</label>
                  <input
                    type="text"
                    name="handle"
                    required
                    maxLength="30"
                    value={handle}
                    onChange={handleChangeHandle}
                    className="shop-create-component-input"
                  />
                  {handle && handleAvailable === false && !checkingHandle && (
                    <p className="shop-create-component-handle-error">
                      <FaTimesCircle className="shop-create-component-icon" /> This
                      handle is already taken. Please choose another.
                    </p>
                  )}
                  {handle && handleAvailable === true && !checkingHandle && (
                    <p className="shop-create-component-handle-success">
                      <FaCheckCircle className="shop-create-component-icon" /> This
                      handle is available.
                    </p>
                  )}
                </div>

                <div className="shop-create-component-form-group">
                  <label htmlFor="phone-number">Phone Number</label>
                  <input
                    type="tel"
                    name="phone-number"
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="shop-create-component-input"
                  />
                </div>

                <div className="shop-create-component-form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="shop-create-component-input"
                  />
                </div>

                <div className="shop-create-component-form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    name="address"
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="shop-create-component-input"
                  />
                </div>

                <div className="shop-create-component-form-group">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    required
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="shop-create-component-input"
                  />
                </div>

                <div className="shop-create-component-form-group">
                  <label htmlFor="state">State</label>
                  <select
                    name="state"
                    required
                    value={stateCode}
                    onChange={(e) => setStateCode(e.target.value)}
                    className="shop-create-component-input"
                  >
                    {stateOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="shop-create-component-form-group">
                  <label htmlFor="zipcode">Zip Code</label>
                  <input
                    type="text"
                    name="zipcode"
                    required
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    className="shop-create-component-input"
                  />
                </div>

                <div className="shop-create-component-form-group">
                  <label htmlFor="password">Password</label>
                  <div className="shop-create-component-password-container">
                    <input
                      type={visible ? "text" : "password"}
                      name="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="shop-create-component-input"
                    />
                    {visible ? (
                      <AiOutlineEye
                        className="shop-create-component-eye-icon"
                        size={25}
                        onClick={() => setVisible(false)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className="shop-create-component-eye-icon"
                        size={25}
                        onClick={() => setVisible(true)}
                      />
                    )}
                  </div>
                </div>

                <div className="shop-create-component-form-group">
                  <label htmlFor="avatar">Avatar</label>
                  <div className="shop-create-component-avatar-container">
                    <span className="shop-create-component-avatar-preview">
                      {avatarPreview ? (
                        <img
                          src={avatarPreview}
                          alt="avatar"
                          className="shop-create-component-avatar-image"
                        />
                      ) : (
                        <RxAvatar className="shop-create-component-avatar-icon" />
                      )}
                    </span>
                    <label
                      htmlFor="file-input"
                      className="shop-create-component-file-label"
                    >
                      <span>Upload an avatar</span>
                      <input
                        type="file"
                        name="avatar"
                        id="file-input"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleFileInputChange}
                        className="shop-create-component-file-input"
                      />
                    </label>
                  </div>
                </div>

                <div className="shop-create-component-form-group">
                  <label htmlFor="banner">Shop Banner</label>
                  <div className="shop-create-component-avatar-container">
                    <span className="shop-create-component-avatar-preview">
                      {bannerPreview ? (
                        <img
                          src={bannerPreview}
                          alt="banner"
                          className="shop-create-component-avatar-image"
                        />
                      ) : (
                        <RxAvatar className="shop-create-component-avatar-icon" />
                      )}
                    </span>
                    <label
                      htmlFor="banner-input"
                      className="shop-create-component-file-label"
                    >
                      <span>Upload a banner</span>
                      <input
                        type="file"
                        name="banner"
                        id="banner-input"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleBannerInputChange}
                        className="shop-create-component-file-input"
                      />
                    </label>
                  </div>
                </div>

                <div className="shop-create-component-form-group">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onRecaptchaChange}
                  />
                </div>

                <div className="user-sign-up-tos-container">
                  <label className="user-sign-up-tos-label">
                    <input
                      type="checkbox"
                      checked={agreedToTOS}
                      onChange={(e) => setAgreedToTOS(e.target.checked)}
                      className="user-sign-up-tos-checkbox"
                    />
                    <span className="user-sign-up-tos-text">
                      I agree to the <Link to="/vendor-terms-and-conditions" className="user-sign-up-tos-link">Vendor Terms & Conditions</Link>
                    </span>
                  </label>
                </div>

                <div className="shop-create-component-form-group">
                  <button 
                    type="submit" 
                    className="shop-create-component-submit"
                    disabled={!agreedToTOS}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}

      {/* Avatar Image Cropper Modal */}
      {showCropper && (
        <ImageCropperModal
          updateAvatar={updateAvatar}
          closeModal={() => setShowCropper(false)}
          initialImage={selectedFile}
          aspectRatio={1} 
          minWidth={150}
          isAvatar={true}
          isPost={false}
          isLogo={false}
        />
      )}

      {/* Banner Image Cropper Modal */}
      {showBannerCropper && (
        <ImageCropperModal
          updateAvatar={updateBanner}
          closeModal={() => setShowBannerCropper(false)}
          initialImage={selectedFile}
          aspectRatio={4} 
          minWidth={600}
          isAvatar={false}
          isPost={false}
          isLogo={false}
        />
      )}
    </div>
  );
};

export default ShopCreate;

