import axios from "axios";
import { server } from "../../server";

// Action Types
export const LOAD_THEME_REQUEST = "LOAD_THEME_REQUEST";
export const LOAD_THEME_SUCCESS = "LOAD_THEME_SUCCESS";
export const LOAD_THEME_FAIL = "LOAD_THEME_FAIL";

export const UPDATE_THEME_REQUEST = "UPDATE_THEME_REQUEST";
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS";
export const UPDATE_THEME_FAIL = "UPDATE_THEME_FAIL";

export const UPLOAD_THEME_LOGO_REQUEST = "UPLOAD_THEME_LOGO_REQUEST";
export const UPLOAD_THEME_LOGO_SUCCESS = "UPLOAD_THEME_LOGO_SUCCESS";
export const UPLOAD_THEME_LOGO_FAIL = "UPLOAD_THEME_LOGO_FAIL";

export const CLEAR_THEME_ERRORS = "CLEAR_THEME_ERRORS";
export const CLEAR_THEME_MESSAGES = "CLEAR_THEME_MESSAGES";


export const loadTheme = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_THEME_REQUEST });

    const { data } = await axios.get(`${server}/theme/get`, {
      withCredentials: true,
    });

    dispatch({ type: LOAD_THEME_SUCCESS, payload: data });

    // Apply the loaded theme to the CSS root variables
    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${toKebabCase(key)}`, value);
      });
    }
  } catch (error) {
    dispatch({
      type: LOAD_THEME_FAIL,
      payload:
        error.response?.data?.message ||
        "There was a problem loading the theme data. Please try again.",
    });
  }
};

// Helper function to format CSS variable names properly
const toKebabCase = (str) => str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();


export const updateTheme = (themeData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_THEME_REQUEST });

    await axios.patch(`${server}/theme/update`, themeData, {
      withCredentials: true,
    });

    dispatch({ type: UPDATE_THEME_SUCCESS, payload: themeData });

    // Apply the updated theme to the CSS root variables
    if (themeData) {
      Object.entries(themeData).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${toKebabCase(key)}`, value);
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_THEME_FAIL,
      payload:
        error.response?.data?.message ||
        "There was a problem updating the theme. Please try again.",
    });
  }
};


export const uploadThemeLogo = (file) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_THEME_LOGO_REQUEST });

    const formData = new FormData();
    formData.append("logo", file);

    const { data } = await axios.post(`${server}/theme/upload-logo`, formData, {
      withCredentials: true,
    });

    if (!data.logoUrl) {
      throw new Error("Upload response is missing the logo URL.");
    }

    dispatch({ type: UPLOAD_THEME_LOGO_SUCCESS, payload: data.logoUrl });

    // Update the CSS variable for site logo
    document.documentElement.style.setProperty("--site-logo-url", `url(${data.logoUrl})`);

    return data.logoUrl;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      error.message ||
      "There was a problem uploading the logo. Please try again.";

    console.error("Error uploading theme logo:", {
      message: errorMessage,
      response: error.response?.data,
      status: error.response?.status,
      stack: error.stack,
    });

    dispatch({ type: UPLOAD_THEME_LOGO_FAIL, payload: errorMessage });
    throw error;
  }
};

  

// Clear theme-specific errors
export const clearThemeErrors = () => ({ type: CLEAR_THEME_ERRORS });

// Clear theme-specific messages
export const clearThemeMessages = () => ({ type: CLEAR_THEME_MESSAGES });

