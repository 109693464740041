import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AgeVerificationPage,
  LoginPage,
  SignupPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  CategoryProductsPage,
  BestSellingPage,
  EventsPage,
  FAQPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ProductDetailsPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  OrderDetailsPage,
  TrackOrderPage,
  UserInbox,
  LandingPage,
  WholesaleProductsPage,
  LandingPageTest
} from "./routes/Routes.js";
import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopAllProducts,
  ShopCreateEvents,
  ShopAllEvents,
  ShopAllCoupons,
  ShopPreviewPage,
  ShopAllOrders,
  ShopOrderDetails,
  ShopAllRefunds,
  ShopSettingsPage,
  ShopWithDrawMoneyPage,
  ShopInboxPage,
  ShopCreateAdvertisement,
  ShopHomePage,
  BrandPage,
  ShopAllPurchases,
} from "./routes/ShopRoutes";
import {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEvents,
  AdminDashboardWithdraw
} from "./routes/AdminRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import { loadSeller, loadUser } from "./redux/actions/user";
import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import { getAllProducts } from "./redux/actions/product";
import { getAllEvents } from "./redux/actions/event";
import axios from "axios";
import { server } from "./server";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import AgeVerificationWrapper from './components/AgeVerificationWrapper';
import PublicProfilePage from "./pages/PublicProfilePage";
import CookieConsent from "./components/CookieConsent";
import { useCookies } from 'react-cookie';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PrivateProfilePage from "./pages/PrivatePublicProfilePage";
import MapPage from "./pages/Map";
import SponsoredPostsAdmin from "./components/Admin/SponsoredPostsAdmin";
import AdminDashboardAds from "./pages/AdminDashboardAds";
import LoginPageOauth from "./pages/LoginOauth";
import SignupPageOauth from "./pages/SignupPageOauth";
import ActivationPageOauth from "./pages/ActivationPageOauth.jsx";
import OwnedBrand from "./components/Brands/OwnedBrand.jsx";
import BrandManagementPage from "./pages/BrandManagementPage.jsx";
import ForgotPasswordPage from "./pages/ForgotPasswordPage.jsx";
import ForgotPasswordResetPassword from "./components/Login/ForgotPasswordReset.jsx";
import SalesEventsPage from "./pages/SalesEventsPage.jsx";
import EventDetailsPage from "./pages/EventDetailsPage.jsx";
import ShopByBrandPage from "./pages/ShopByBrandPage.jsx";
import ShopShipping from "./pages/Shop/ShopShipping.jsx";
import SubscriptionPage from "./pages/SubscriptionPage.jsx";
import SubscriptionCheckoutPage from "./pages/SubscriptionCheckoutPage.jsx";
import SubscriptionProtectedRoute from "./routes/SubscriptionProtectedRoute.js";
import ShopSubscriptionPage from "./pages/Shop/ShopSubscriptionPage.jsx";
import ShopSubscriptionCheckoutPage from "./pages/Shop/ShopSubscriptionCheckoutPage.jsx";
import ShopSubscriptionsOverview from "./pages/Shop/ShopSubscriptions.jsx";
import ShopProtectedRoute from "./routes/ShopSubscriptionProtectedRoute.js";
import ShopPaymentSettingsPage from "./pages/Shop/ShopPaymentSettingsPage.jsx";
import TempPage from "./pages/temppage.jsx";
import AdminTagManager from "./pages/AdminTagManager.jsx";
import StripePaymentDashboard from "./components/Payment/StripePaymentDashboard.jsx";
import OrderSuccess from "./components/Orders/OrderConfirmation.jsx";
import SellerOrderDetails from "./components/SellerOrderDetails.jsx";
import HashtagPosts from "./components/Hashtags/HashtagPosts.jsx";
import MembershipProcess from "./components/Membership/MembershipProcess.jsx";
import AdminQrScanner from "./components/Membership/AdminQrScanner.jsx";
import MembershipSuccess from "./components/Membership/MembershipSuccess.jsx";
import StudioAdminDashboard from "./components/Membership/StudioAdminDashboard.jsx";
import ReportedPosts from "./components/Compliance/ReportedPosts.jsx";
import EventsPageContainer from "./pages/EventsPageContainer.jsx";
import CasinoLandingPage from "./pages/GamblingPage.jsx";
import RoomDetailsPage from "./pages/RoomDetailsPage.jsx";
import MarketplacePage from "./pages/MarketplacePage.jsx";
import AdminDashboardSubscriptions from "./pages/AdminDashboardSubscriptions.jsx";
import ShopPaymentMethodPage from "./pages/Shop/ShopPaymentMethodPage.jsx";
import PageNotFound from "./pages/PageNotFound.jsx";
import ShopChangePaymentMethodPage from "./pages/Shop/ShopChangePaymentMethodPage.jsx";
import CreateProductDoc from "./pages/Documentation/CreateProductDocs.jsx";
import SKUExplanation from "./pages/Documentation/SKUExplanationDocs.jsx";
import HelpContactPage from "./pages/Documentation/HelpContactPage.jsx";
import VariantsComponentGuide from "./components/Documentation/VariantsComponentGuide.jsx";
import VariantProductDocs from "./pages/Documentation/VariantProductDocs.jsx";
import InventoryManagementDocs from "./pages/Documentation/InventoryManagementDocs.jsx";
import SKUExplanationPage from "./pages/Documentation/SKUExplanationDocs.jsx";
import CampaignManagementDocs from "./pages/Documentation/CampaignManagementDocs.jsx";
import BrandCreateDocPage from "./pages/Documentation/BrandCreationDocs.jsx";
import BrandCatalogItemDocPage from "./pages/Documentation/BrandCatalogItemDocs.jsx";
import TagSelectionDocs from "./pages/Documentation/TagSelectionDocs.jsx";
import TaxCodeDocs from "./pages/Documentation/TaxCodeDocs.jsx";
import StreamPage from "./pages/StreamPage.jsx";
import ExplorePage from "./pages/ExplorePage.jsx";
import MainPage from "./pages/MainPage.jsx";
import ShopBySellerProds from "./components/Shop/ShopBySellerProds.jsx";
import ShopsPage from "./pages/Shop/ShopsPage.jsx";
import ShopOwnerProtectedRoute from "./routes/ShopOwnerProtectedRoute.js";
import UserProtectedRoute from "./routes/UserProtectedRoute.js";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage.jsx";
import VendorTermsAndConditions from "./components/Admin/VendorTermsAndConditions.jsx";
import BuyerProtectionPage from "./pages/BuyerProtectionPage.jsx";
import ShippingInformationPage from "./pages/ShippingInformationPage.jsx";
import VerificationProcess from "./components/Verification/VerificationProcess.jsx";
import AdminDashboardTheme from "./pages/AdminDashboardTheme.jsx";




const App = () => {

  const [stripeApikey, setStripeApiKey] = useState("");
  const [cookies] = useCookies(["cookieConsent"]);

  async function getStripeApikey() {
    const { data } = await axios.get(`${server}/payment/stripeapikey`);
    setStripeApiKey(data.stripeApikey);
  }
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
    Store.dispatch(getAllEvents());
    getStripeApikey();
  }, []);


  

  return (
    <BrowserRouter>
    <Routes>
      {stripeApikey && (
          <Route
            path="/payment"
            element={
              <Elements stripe={loadStripe(stripeApikey)}>
                <ProtectedRoute>
                  <PaymentPage />
                </ProtectedRoute>
              </Elements>
            }
          />
        )}
        {stripeApikey && (
          <Route
            path="/dashboard-payment-setup"
            element={
              <Elements stripe={loadStripe(stripeApikey)}>
                <ShopPaymentSettingsPage />
              </Elements>
            }
          />
        )}
        {stripeApikey && (
          <Route
            path="/onboarding"
            element={
              <Elements stripe={loadStripe(stripeApikey)}>
                <ShopPaymentSettingsPage />
              </Elements>
            }
          />
        )}
        {stripeApikey && (
          <Route
            path="/payment-method-setup"
            element={
              <Elements stripe={loadStripe(stripeApikey)}>
                <ShopPaymentMethodPage />
              </Elements>
            }
          />
        )}
        {stripeApikey && (
          <Route
            path="/payment-method"
            element={
              <Elements stripe={loadStripe(stripeApikey)}>
                <ShopChangePaymentMethodPage/>
              </Elements>
            }
          />
        )}
        {stripeApikey && (
          <Route
            path="/dashboard-payments"
            element={
              <Elements stripe={loadStripe(stripeApikey)}>
                <StripePaymentDashboard />
              </Elements>
            }
          />
        )}
        {stripeApikey && (
          <Route
            path="/subscription-checkout/:priceId"
            element={
              <Elements stripe={loadStripe(stripeApikey)}>
                <ProtectedRoute>
                  <SubscriptionCheckoutPage />
                </ProtectedRoute>
              </Elements>
            }
          />
        )}
        {stripeApikey && (
          <Route
            path="/shop-subscription-checkout/:priceId"
            element={
              <Elements stripe={loadStripe(stripeApikey)}>
                <ProtectedRoute>
                  <ShopSubscriptionCheckoutPage />
                </ProtectedRoute>
              </Elements>
            }
          />
        )}
        {stripeApikey && (
          <Route
            path="/membership-sign-up"
            element={
              <Elements stripe={loadStripe(stripeApikey)}>
                <MembershipProcess />
              </Elements>
            }
          />
        )}
      
      
      <Route path="/admin-studio-qr-scanner" element={<AdminQrScanner />} />
      <Route path="/admin-studio-dashboard" element={<StudioAdminDashboard />} />
      <Route path="/membership-success" element={<MembershipSuccess />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/ageverificationpage" element={<AgeVerificationPage />} />
        
        <Route
          path="/activation/:activation_token"
          element={<ActivationPage />}
        />
        <Route
          path="/seller/activation/:activation_token"
          element={<SellerActivationPage />}
        />   
        <Route path="/home-page" element={<HomePage />} />
        <Route path="/" element={<ExplorePage />} />
        <Route path="/home" element={<LandingPageTest />} />
        <Route path="/test" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/oauth" element={<LoginPageOauth />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ForgotPasswordResetPassword />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/sign-up/oauth" element={<SignupPageOauth />} />
        <Route path="/activation" element={<ActivationPageOauth />} />
        
        <Route path="/marketplace" element={<MarketplacePage />} />
        <Route path="/wholesale/products" element={<WholesaleProductsPage />} />
        
        <Route path="/products/:category?/:subCategory?" element={<CategoryProductsPage />} />
        <Route path="/new-arrivals" element={<CategoryProductsPage specialFilter="new-arrivals" />} />
        <Route path="/top-rated" element={<CategoryProductsPage specialFilter="top-rated" />} />
        <Route path="/trending" element={<CategoryProductsPage specialFilter="trending" />} />

        <Route path="/product/:id" element={<ProductDetailsPage />} />
        <Route path="/best-selling" element={<BestSellingPage />} />
        <Route path="/shops" element={<ShopsPage />} />
        <Route path="/sales" element={<SalesEventsPage />} />
        <Route path="/events" element={  <EventsPageContainer /> } />
        <Route path="/events-sub" element={ <SubscriptionProtectedRoute requiredTiers={['Super OG']}> <EventsPage /> </SubscriptionProtectedRoute>} />
        <Route path="/brand/:brandName" element={<ShopByBrandPage />} />
        <Route path="/events/:eventId" element={<EventDetailsPage />} />
        <Route path="/subscriptions" element={<SubscriptionPage />} />
        <Route path="/shop-subscriptions" element={<ShopSubscriptionPage/>} />
        <Route path="/hashtag/:hashtag" element={<HashtagPosts/>} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/explore" element={<ExplorePage />} />

        <Route path="/verification" element={<VerificationProcess />} />

        <Route path="/terms-of-service" element={<TermsAndConditionsPage/>} />
        <Route path="/vendor-terms-and-conditions" element={<VendorTermsAndConditions />} />
        <Route path="/buyer-protection" element={<BuyerProtectionPage/>} />
        <Route path="/shipping-information" element={<ShippingInformationPage/>} />

        <Route path="/docs/create-a-product" element={<CreateProductDoc />} />
        <Route path="/docs/what-is-a-sku" element={<SKUExplanationPage/>} />
        <Route path="/docs/product-variants" element={<VariantProductDocs/>} />
        <Route path="/docs/inventory" element={<InventoryManagementDocs/>} />
        <Route path="/docs/tags" element={<TagSelectionDocs/>} />
        <Route path="/docs/tax-codes" element={<TaxCodeDocs/>} />
        <Route path="/docs/campaign-creation" element={<CampaignManagementDocs/>} />
        <Route path="/docs/brand-creation" element={<BrandCreateDocPage/>} />
        <Route path="/docs/brand-catalog" element={<BrandCatalogItemDocPage/>} />
        <Route path="/contact-us" element={<HelpContactPage/>} />

        <Route
          path="/checkout"
          element={
            
              <CheckoutPage />
            
          }
        />
        <Route path="/order/" element={<OrderSuccess />} />
        <Route path="/casino-demo" element={<CasinoLandingPage />} />

        <Route path="/profile/:handle" element={<PublicProfilePage />} />
        <Route
          path="/my-profile/:handle"
          element={
            <UserProtectedRoute>
              <PrivateProfilePage />
            </UserProtectedRoute>
          }
        />


        <Route path="/rooms/:handle" element={<RoomDetailsPage />} />
        <Route path="/stream" element={<StreamPage />} />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inbox"
          element={
            <ProtectedRoute>
              <UserInbox />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/order/:id"
          element={
            <ProtectedRoute>
              <OrderDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/track/order/:id"
          element={
            <ProtectedRoute>
              <TrackOrderPage />
            </ProtectedRoute>
          }
        />
        <Route path="/shop/:handle" element={<ShopPreviewPage />} />
        {/* shop Routes */}
        <Route path="/shop-create" element={<ShopCreatePage />} />
        <Route path="/shop-login" element={<ShopLoginPage />} />
        <Route
          path="/shop/preview/:handle"
          element={
            <ShopOwnerProtectedRoute>
              <ShopHomePage />
              </ShopOwnerProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <SellerProtectedRoute>
              <ShopSettingsPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/:handle"
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-product"
          element={
            <SellerProtectedRoute>
              <ShopCreateProduct />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-brand"
          element={
            <SellerProtectedRoute>
              <BrandPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/brands/:brandId"
          element={
            
            <BrandManagementPage />
            
          }
        />
        <Route
          path="/dashboard-shipping"
          element={
            <SellerProtectedRoute>
            <ShopShipping />
            </SellerProtectedRoute>
          }
        />
        
        <Route
          path="/dashboard-orders"
          element={
            <SellerProtectedRoute>
              <ShopAllOrders />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-purchases"
          element={
            <SellerProtectedRoute>
              <ShopAllPurchases />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-refunds"
          element={
            <SellerProtectedRoute>
              <ShopAllRefunds />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/order/:id"
          element={
            <SellerProtectedRoute>
              <ShopOrderDetails />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/purchase/:id"
          element={
            <SellerProtectedRoute>
              <SellerOrderDetails />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-products"
          element={
            <SellerProtectedRoute>
              <ShopAllProducts />
            </SellerProtectedRoute>
          }
        />
       <Route
          path="/dashboard-create-event"
          element={
            <SellerProtectedRoute>
            
              <ShopCreateEvents />
            
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-event-subscription"
          element={
            <SellerProtectedRoute>
            <ShopProtectedRoute requiredTiers={['Super OG Shop']}>
              <ShopCreateEvents />
            </ShopProtectedRoute>
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/dashboard-create-ad"
          element={
            <SellerProtectedRoute>
              <ShopCreateAdvertisement />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-events"
          element={
            <SellerProtectedRoute>
              <ShopAllEvents />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-coupons"
          element={
            <SellerProtectedRoute>
              <ShopAllCoupons />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-withdraw-money"
          element={
            <SellerProtectedRoute>
              <ShopWithDrawMoneyPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-messages"
          element={
            <SellerProtectedRoute>
              <ShopInboxPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-subscription"
          element={
            <SellerProtectedRoute>
              <ShopSubscriptionsOverview />
            </SellerProtectedRoute>
          }
        />

        
        {/* Admin Routes */}
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/theme"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardTheme />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-users"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardUsers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-sellers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardSellers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-orders"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardOrders />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-products"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardProducts />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-events"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardEvents />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-ads"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardAds/>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-tags"
          element={
            <ProtectedAdminRoute>
              <AdminTagManager/>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-reports"
          element={
            <ProtectedAdminRoute>
              <ReportedPosts/>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-subscriptions"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardSubscriptions/>
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-withdraw-request"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardWithdraw />
            </ProtectedAdminRoute>
          }
        />
        
            
          
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {!cookies.cookieConsent && <CookieConsent />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;
