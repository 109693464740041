import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../server"; 
import PostsWidgetRedux from "../widgets/PostsWidgetRedux";
import AboutUser from "../components/AboutUser";
import { DocumentTextIcon, UserIcon, UsersIcon, PhotoIcon, CalendarIcon } from '@heroicons/react/24/outline';
import OtherConnectionsWidget from "./Connections/OtherConnectionsWidget";
import EventsParentContainer from "./Events/EventsParentContainer";
import MyImagesProfile from "./Content/MyImagesProfile";

const UserProfileData = () => {
  const { handle } = useParams();
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("Posts");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserByHandle = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${server}/user/user-info/handle/${handle}`);
        setUser(response.data.user); 
      } catch (error) {
        console.error("Error fetching user by handle:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserByHandle();
  }, [handle]);

  if (isLoading) {
    return <div>Loading user data...</div>;
  }

  if (!user) {
    return <div>User not found.</div>;
  }
  const userId = user._id;

  // Define your tabs
  const tabs = [
    { name: "Posts", icon: DocumentTextIcon, current: activeTab === "Posts" },
    { name: "Photos", icon: PhotoIcon, current: activeTab === "Images" },
    { name: "Connections", icon: UsersIcon, current: activeTab === "Connections" },
    { name: "Events", icon: CalendarIcon, current: activeTab === "Events" },
    { name: "About", icon: UserIcon, current: activeTab === "About" },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="user-profile-data-content-container">
      {/* Tab navigation */}
      <div className="user-profile-data-tab-navigation">
        <nav className="user-profile-data-tabs" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={classNames(
                tab.current
                  ? "user-profile-data-tab-active"
                  : "user-profile-data-tab-inactive",
                "user-profile-data-tab-button"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <tab.icon className="user-profile-data-tab-icon" />
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      {/* Tab Content */}
      {activeTab === "Posts" && (
        <div className="user-profile-data-tab-content">
          <PostsWidgetRedux ownerId={userId} profileType="User" isProfile={true} />
        </div>
      )}

      {activeTab === "About" && (
        <div className="user-profile-data-tab-content">
          <AboutUser userId={userId} />
        </div>
      )}

      {activeTab === "Connections" && (
        <div className="user-profile-data-tab-content">
          <OtherConnectionsWidget userId={userId} profileType="User" />
        </div>
      )}

      {activeTab === "Photos" && (
        <div className="user-profile-data-tab-content">
          <MyImagesProfile userId={userId} />
        </div>
      )}

      {activeTab === "Events" && (
        <div className="user-profile-data-tab-content">
          <EventsParentContainer ownerId={userId} />
        </div>
      )}
    </div>
  );
};

export default UserProfileData;
