import React, { useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import Sidebar from "../components/Layout/Sidebar";
import ExploreBentoGrid from "../components/Marketplace/ExploreBentoGrid";
import Loader from "../components/Layout/Loader";
import axios from "axios";
import { adsServer } from "../server";

const ExplorePage = () => {
  const [products, setProducts] = useState([]);
  const [shops, setShops] = useState([]);
  const [posts, setPosts] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  
  // Ad States
  const [productAds, setProductAds] = useState([]);
  const [shopAds, setShopAds] = useState([]);
  const [brandAds, setBrandAds] = useState([]);
  const [customAds, setCustomAds] = useState([]);

  // Fetch products, shops, and posts from /explore
  useEffect(() => {
    const fetchExploreData = async () => {
      try {
        const response = await axios.get(`${adsServer}/explore/explore`, {
          withCredentials: true,
          headers: {
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          }
        });
        const { products, shops, posts } = response.data;
        setProducts(products);
        setShops(shops);
        setPosts(posts); // Set posts
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching explore data:", error);
        setIsLoading(false);
      }
    };

    fetchExploreData();
  }, []);

  // Fetch sponsored ads (products, shops, and brands)
  useEffect(() => {
    const fetchSponsoredAds = async () => {
      try {
        const response = await axios.get(`${adsServer}/sponsored/sponsored-sidebar-ads-test`, {
          withCredentials: true,
          headers: {
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          }
        });
        const allAds = response.data;

        // Filter ads by type
        const productAds = allAds.filter((ad) => ad.type === 'product');
        const shopAds = allAds.filter((ad) => ad.type === 'shop');
        const brandAds = allAds.filter((ad) => ad.type === 'brand');
        const customAds = allAds.filter((ad) => ad.type === 'custom');

        setProductAds(productAds);
        setShopAds(shopAds);
        setBrandAds(brandAds);
        setCustomAds(customAds);
      } catch (error) {
        console.error("Error fetching sponsored ads:", error);
      }
    };

    fetchSponsoredAds();
  }, []);

  return (
    <div>
      <Header />
      <Sidebar />
      
      {isLoading ? (
        <Loader />
      ) : (
        <ExploreBentoGrid 
          shops={shops} 
          products={products} 
          posts={posts} 
          productAds={productAds}
          shopAds={shopAds}
          brandAds={brandAds}
          customAds={customAds}
        />
      )}
    </div>
  );
};

export default ExplorePage;



