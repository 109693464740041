import React, { useState } from 'react';
import VerificationStart from './VerificationStart';
import VerificationPersonalData from './VerificationPersonalData'; 
import VerificationCrossReference from './VerificationCrossReference';
import VerificationTOS from './VerificationTOS';
import VerificationFinish from './VerificationFinish';
import ProgressIndicator from '../Membership/ProgressIndicator';

const VerificationProcess = () => {
  const [step, setStep] = useState(1);

  // Define all the form data in one place
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    photo: '',
    instagram: '',
    twitter: '',
    facebook: '',
    linkedin: '',
    tiktok: '',
    youtube: '',
  });

  // Handle moving to the next step
  const nextStep = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  // Handle moving to the previous step
  const prevStep = () => {
    setStep(step - 1);
  };

  // Handle field changes
  const handleChange = (input) => (e) => {
    const value = e.target ? e.target.value : e;
    setFormData({ ...formData, [input]: value });
  };

  // Optionally define the total steps so your progress indicator can use it
  const totalSteps = 5; 

  return (
    <div>
      {/* Step 1: The "start" or introduction */}
      {step === 1 && (
        <VerificationStart
          nextStep={nextStep}
        />
      )}

      {/* Step 2: Collect personal details & photo */}
      {step === 2 && (
        <VerificationPersonalData
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          formData={formData}
        />
      )}

      {/* Step 3: Cross-reference social media profiles */}
      {step === 3 && (
        <VerificationCrossReference
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          formData={formData}
        />
      )}

      {/* Step 4: Terms of Service (or disclaimers) */}
      {step === 4 && (
        <VerificationTOS
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          formData={formData}
        />
      )}

      {/* Step 5: Finish or confirmation screen */}
      {step === 5 && (
        <VerificationFinish
          prevStep={prevStep}
          formData={formData}
        />
      )}

      {/* If you want a progress bar: */}
      <ProgressIndicator currentStep={step} totalSteps={totalSteps} />
    </div>
  );
};

export default VerificationProcess;
