import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider, useDispatch, useSelector } from "react-redux";
import Store from "./redux/store";
import { loadTheme } from "./redux/actions/theme";
import 'font-awesome/css/font-awesome.min.css';

const ThemeLoader = ({ children }) => {
  const dispatch = useDispatch();
  const { theme, loading } = useSelector((state) => state.theme);

  useEffect(() => {
    dispatch(loadTheme());
  }, [dispatch]);

  useEffect(() => {
    if (theme) {
      Object.entries(theme).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${key}`, value);
      });

      // Set site logo URL in CSS
      if (theme.siteLogoUrl) {
        document.documentElement.style.setProperty("--site-logo-url", `url(${theme.siteLogoUrl})`);
      }
    }
  }, [theme]);

  return children;
};

ReactDOM.render(
  <Provider store={Store}>
    <ThemeLoader>
      <App />
    </ThemeLoader>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();

