import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { server } from '../server'; 
import UserProfileData from '../components/UserProfileData';
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import UserBanner from '../components/Shop/UserBanner';
import MyImages from '../components/Content/MyImages';
import MyEvents from '../components/Events/MyEvents';
import MyContentCarousel from '../components/Profile/MyContentCarousel';
import RoomsProfile from '../components/Rooms/RoomsProfile';
import FollowersDisplay from '../components/Connections/FollowersDisplay';
import MyPostWidgetRedux from '../widgets/MyPostWidgetRedux';
import MixedSponsoredAds from '../components/Sponsored/MixedSponsoredAds';
import FollowingDisplay from '../components/Connections/FollowingDisplay';
import UserAvatar from '../components/Layout/UserAvatar';
import ImageCropperModal from '../components/Image/ImageCropperModal';
import { useDispatch } from 'react-redux';
import { loadUser } from '../redux/actions/user';
import { toast } from 'react-toastify';
import Loader from '../components/Layout/Loader';
import EventsParentContainer from '../components/Events/EventsParentContainer';

const PrivateProfilePage = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAvatarCropper, setShowAvatarCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);

  // 1) Renamed `id` to `handle`:
  const { handle } = useParams();
  const dispatch = useDispatch();
  const currentProfile = sessionStorage.getItem("currentProfile");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // 2) Fetch user by handle instead of by _id:
        const response = await axios.get(`${server}/user/user-info/handle/${handle}`);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [handle]);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setShowAvatarCropper(true);
    }
  };

  const updateAvatar = (croppedFile) => {
    setCroppedFile(croppedFile); 
    const previewUrl = URL.createObjectURL(croppedFile);
    setUser((prevUser) => ({ ...prevUser, avatar: { url: previewUrl } }));
    setShowSaveButton(true);
  };

  const handleSaveAvatar = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("avatar", croppedFile);

      await axios.put(`${server}/user/update-avatar`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          "Current-Profile": currentProfile,
        },
      });
      dispatch(loadUser());
      toast.success("Avatar updated successfully!");
      setShowSaveButton(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update avatar");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!user) {
    return <div>User data not available</div>;
  }

  // 3) We'll pass the user's _id to child components that require the MongoDB _id:
  const userId = user._id;

  return (
    <div className="profile-page">
      <Header />
      <Sidebar />
      <div className="profile-banner-wrapper">
        <UserBanner isUser={true} user={user} setUser={setUser} />
        <div className="profile-info-container">
          <div className="profile-avatar-wrapper">
            <UserAvatar
              isUser={true}
              user={user}
              setUser={setUser}
              handleAvatarChange={handleAvatarChange}
              handleSaveAvatar={handleSaveAvatar}
              showSaveButton={showSaveButton}
              croppedFile={croppedFile}
            />
            <div className="profile-info">
              <div className="profile-name-wrapper">
                <h3 className="profile-name">{user.name}</h3>
                <h3 className="profile-handle">@{user.handle}</h3>
                <div style={{ height: "50px" }}></div>
                <div className="profile-friends">
                  {/* Pass userId (the Mongo _id) here */}
                  <FollowersDisplay userId={userId} profileType="User" />
                  <FollowingDisplay userId={userId} profileType="User" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="profile-main-content">
        <div className="profile-sidebar-left">
          <RoomsProfile profileId={userId}  profileType="User" />
          <MyImages userId={userId} />
          <EventsParentContainer ownerId={userId} />
        </div>
        
        <main className="profile-main-area">
          <MyPostWidgetRedux />
          <UserProfileData />
        </main>

        <aside className="profile-sidebar-right">
          <MyContentCarousel ownerId={userId}  profileType="User"/>
          <MixedSponsoredAds />
        </aside>
      </div>

      {/* Avatar Image Cropper Modal */}
      {showAvatarCropper && (
        <ImageCropperModal
          updateAvatar={updateAvatar}
          closeModal={() => setShowAvatarCropper(false)}
          initialImage={selectedFile}
          aspectRatio={1}
          minWidth={200}
          isAvatar={true}
          isPost={false}
          isLogo={false}
        />
      )}
    </div>
  );
};

export default PrivateProfilePage;


