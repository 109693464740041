// ExploreBentoGrid.jsx
import React, { useMemo, useState, useEffect, Suspense } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
import Masonry from "react-masonry-css";
import ItemLoader from "./ItemLoader";
import LazyLoadComponent from "../../hooks/LazyLoadComponent";

// Lazy load the card components for code splitting
const ShopCard = React.lazy(() => import("./ShopCard"));
const ProductCard = React.lazy(() => import("./ProductCard"));
const PostCard = React.lazy(() => import("./PostCard"));
const PlaceholderCard = React.lazy(() => import("./PlaceholderCard"));

// Import Ad Components
const DualProductAdCarouselProps = React.lazy(() =>
  import("../Sponsored/DualProductAdCarouselProps")
);
const QuadProductAdCarouselProps = React.lazy(() =>
  import("../Sponsored/QuadProductCarouselProps")
);
const SponsoredProductCarouselProps = React.lazy(() =>
  import("../Sponsored/SponsoredProductCarouselProps")
);
const SponsoredBrandCarouselProps = React.lazy(() =>
  import("../Sponsored/SponsoredBrandCarouselProps")
);
const SponsoredShopsCarouselProps = React.lazy(() =>
  import("../Sponsored/SponsoredShopsCarouselProps")
);
const TopBannerAdProps = React.lazy(() => import("../Sponsored/TopBannerAdProps"));
const BannerAdProps = React.lazy(() => import("../Sponsored/BannerAdProps"));
// Import additional ad components as needed

const ITEMS_PER_LOAD = 10; 
const AD_INTERVAL = 10; // Insert an ad after every 10 items

const ExploreBentoGrid = ({
  shops,
  products,
  posts,
  productAds,
  shopAds,
  brandAds,
  customAds,
}) => {
  // STEP 1: Convert each data type to an array of {type, data}
  const shopItems = useMemo(
    () => shops.map((s) => ({ type: "shop", data: s })),
    [shops]
  );
  const productItems = useMemo(
    () => products.map((p) => ({ type: "product", data: p })),
    [products]
  );
  const postItems = useMemo(
    () => posts.map((po) => ({ type: "post", data: po })),
    [posts]
  );

  // STEP 2: Shuffle & tag each array with a weight
  //    => shuffle ensures random order
  //    => weight ensures that if one item is "newer," it has a higher chance of being chosen
  const shuffledShops = useMemo(() => shuffleAndWeight(shopItems), [shopItems]);
  const shuffledProducts = useMemo(() => shuffleAndWeight(productItems), [productItems]);
  const shuffledPosts = useMemo(() => shuffleAndWeight(postItems), [postItems]);

  // STEP 3: Interleave the 3 arrays. 
  //    => At each step, pick from the "front" item of each array with probability proportional to its 'weight'
  //    => This preserves weaving but still favors newer items.
  const combinedItems = useMemo(() => {
    const allItems = [];
    const shopsCopy = [...shuffledShops];
    const productsCopy = [...shuffledProducts];
    const postsCopy = [...shuffledPosts];

    while (shopsCopy.length || productsCopy.length || postsCopy.length) {
      // Get the front item from each array (if any)
      const shopFront = shopsCopy[0];
      const productFront = productsCopy[0];
      const postFront = postsCopy[0];

      // Calculate each front's weight (0 if front doesn't exist)
      const shopWeight = shopFront ? shopFront.weight : 0;
      const productWeight = productFront ? productFront.weight : 0;
      const postWeight = postFront ? postFront.weight : 0;

      const totalWeight = shopWeight + productWeight + postWeight;

      if (totalWeight === 0) {
        // If everything is 0 (or no items left?), just pick randomly from what remains
        const availableTypes = [];
        if (shopsCopy.length) availableTypes.push("shop");
        if (productsCopy.length) availableTypes.push("product");
        if (postsCopy.length) availableTypes.push("post");
        if (!availableTypes.length) break; // no more items at all

        const randomType =
          availableTypes[Math.floor(Math.random() * availableTypes.length)];
        if (randomType === "shop") {
          allItems.push(shopsCopy.shift());
        } else if (randomType === "product") {
          allItems.push(productsCopy.shift());
        } else {
          allItems.push(postsCopy.shift());
        }
      } else {
        // Weighted pick
        const r = Math.random() * totalWeight;
        if (r < shopWeight) {
          allItems.push(shopsCopy.shift());
        } else if (r < shopWeight + productWeight) {
          allItems.push(productsCopy.shift());
        } else {
          allItems.push(postsCopy.shift());
        }
      }
    }

    return allItems;
  }, [shuffledShops, shuffledProducts, shuffledPosts]);

  // State for infinite scrolling
  const [displayedItems, setDisplayedItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadMoreItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load next batch of items
  const loadMoreItems = () => {
    const currentLength = displayedItems.length;
    let nextItems = combinedItems.slice(currentLength, currentLength + ITEMS_PER_LOAD);

    // Possibly fill with placeholders if not enough
    if (nextItems.length < ITEMS_PER_LOAD) {
      const placeholdersNeeded = ITEMS_PER_LOAD - nextItems.length;
      const placeholders = generatePlaceholders(placeholdersNeeded);
      nextItems = nextItems.concat(placeholders);
      setHasMore(false);
    }

    if (nextItems.length > 0) {
      const totalItems = currentLength + nextItems.length;
      const shouldInsertAd =
        totalItems % AD_INTERVAL === 0 &&
        (productAds.length > 0 ||
          shopAds.length > 0 ||
          brandAds.length > 0 ||
          customAds.length > 0);

      if (shouldInsertAd) {
        // Insert an ad
        const adType = determineAdType(totalItems);
        const adComponent = getAdComponent(adType);
        if (adComponent) {
          nextItems.push({ type: "ad", component: adComponent });
        }
      }

      setDisplayedItems([...displayedItems, ...nextItems]);
    }
  };

  // Choose ad type in a round-robin fashion
  const determineAdType = (totalItems) => {
    const adTypes = [
      "dualProduct",
      "quadProduct",
      "sponsoredProduct",
      "sponsoredBrand",
      "sponsoredShops",
      "topBanner",
      "banner",
    ];
    const index = Math.floor(totalItems / AD_INTERVAL) % adTypes.length;
    return adTypes[index];
  };

  // Return the corresponding ad component
  const getAdComponent = (adType) => {
    switch (adType) {
      case "dualProduct":
        return <DualProductAdCarouselProps ads={productAds.slice(0, 2)} />;
      case "quadProduct":
        return <QuadProductAdCarouselProps ads={productAds.slice(0, 4)} />;
      case "sponsoredProduct":
        return <SponsoredProductCarouselProps ads={productAds} />;
      case "sponsoredBrand":
        return <SponsoredBrandCarouselProps ads={brandAds} />;
      case "sponsoredShops":
        return <SponsoredShopsCarouselProps ads={shopAds} />;
      case "topBanner":
        return <TopBannerAdProps ad={productAds[0]} />;
      case "banner":
        return <BannerAdProps ad={productAds[1]} />;
      default:
        return null;
    }
  };

  // Generate placeholder items
  const generatePlaceholders = (count) => {
    const placeholders = [];
    for (let i = 0; i < count; i++) {
      placeholders.push({ type: "placeholder", data: {} });
    }
    return placeholders;
  };

  return (
    <section className="explore-bento-section">
      <h2 className="explore-title">Explore</h2>
      <div className="explore-bento-container">
        <h2 className="explore-bento-title">Explore</h2>
        <InfiniteScroll
          dataLength={displayedItems.length}
          next={loadMoreItems}
          hasMore={hasMore}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>No more items to display.</b>
            </p>
          }
        >
          <Suspense
            fallback={
              <div>
                <ItemLoader />
              </div>
            }
          >
            <Masonry
              breakpointCols={{
                default: 3,
                1200: 3,
                992: 3,
                768: 3,
                576: 2,
                480: 2,
              }}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {displayedItems.map((item, index) => {
                if (item.type === "ad") {
                  return (
                    <div
                      key={`ad-${index}`}
                      className="explore-bento-grid-item ad-item"
                    >
                      <LazyLoadComponent>
                        {item.component}
                      </LazyLoadComponent>
                    </div>
                  );
                }

                return (
                  <div
                    key={index}
                    className={`explore-bento-grid-item ${
                      item.type === "product" ? "product-bento-item" : ""
                    } ${item.type === "post" ? "post-item" : ""} ${
                      item.type === "shop" ? "shop-item" : ""
                    } ${item.type === "placeholder" ? "placeholder-item" : ""}`}
                  >
                    <LazyLoadComponent>
                      {item.type === "shop" ? (
                        <ShopCard shop={item.data} />
                      ) : item.type === "product" ? (
                        <ProductCard product={item.data} />
                      ) : item.type === "post" ? (
                        <PostCard post={item.data} />
                      ) : (
                        <PlaceholderCard />
                      )}
                    </LazyLoadComponent>
                  </div>
                );
              })}
            </Masonry>
          </Suspense>
        </InfiniteScroll>
      </div>
    </section>
  );
};

function shuffleAndWeight(arr) {
  const shuffled = shuffleArray([...arr]);
  const currentTime = Date.now();
  const maxClampDays = 21; // older than 21 days all become "21 days old"
  const randomBaselineRange = [0.1, 0.3];

  return shuffled.map((item) => {
    const createdAt = new Date(item.data.createdAt || currentTime).getTime();
    let daysDiff = (currentTime - createdAt) / (1000 * 60 * 60 * 24);

    // clamp the days to maxClampDays
    daysDiff = Math.min(daysDiff, maxClampDays);

    // simple timeWeight
    const timeWeight = 1 / (1 + daysDiff);

    // random baseline
    const rBase = 
      randomBaselineRange[0] + Math.random() * (randomBaselineRange[1] - randomBaselineRange[0]);

    // final weight
    const weight = timeWeight + rBase; 
    return { ...item, weight };
  });
}



/**
 * Plain old random shuffle (Fisher-Yates)
 */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

// PropTypes
ExploreBentoGrid.propTypes = {
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      handle: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        url: PropTypes.string,
      }),
      banner: PropTypes.shape({
        url: PropTypes.string,
      }),
      description: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        })
      ),
      originalPrice: PropTypes.number.isRequired,
      discountPrice: PropTypes.number,
      description: PropTypes.string,
      createdAt: PropTypes.string,
    })
  ).isRequired,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      owner: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
          handle: PropTypes.string,
          avatar: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      ]),
      profileType: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        })
      ),
      videos: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        })
      ),
      link: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
      }),
      createdAt: PropTypes.string,
    })
  ).isRequired,
  productAds: PropTypes.array.isRequired,
  shopAds: PropTypes.array.isRequired,
  brandAds: PropTypes.array.isRequired,
  customAds: PropTypes.array.isRequired,
};

export default ExploreBentoGrid;










