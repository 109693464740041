import React from 'react';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';

const VerificationCrossReference = ({ nextStep, prevStep, handleChange, formData }) => {
  const handleNext = () => {
    // If you have validations, do them here
    nextStep();
  };

  return (
    <>
      <Header />
      <Sidebar />
    <div className="membership-personal-data-container">
     {/* New section for social media handles */}
     <h3 className="membership-personal-data-subtitle">
          What are your other social profiles?
        </h3>
        <div className="membership-personal-data-field">
          <label>Instagram</label>
          <input
            type="text"
            placeholder="@yourInstagramHandle"
            value={formData.instagram || ''}
            onChange={(e) => handleChange('instagram')(e)}
          />
        </div>

        <div className="membership-personal-data-field">
          <label>Twitter</label>
          <input
            type="text"
            placeholder="@yourTwitterHandle"
            value={formData.twitter || ''}
            onChange={(e) => handleChange('twitter')(e)}
          />
        </div>

        <div className="membership-personal-data-field">
          <label>LinkedIn</label>
          <input
            type="text"
            placeholder="LinkedIn Profile URL"
            value={formData.linkedin || ''}
            onChange={(e) => handleChange('linkedin')(e)}
          />
        </div>

        <div className="membership-personal-data-field">
          <label>Facebook</label>
          <input
            type="text"
            placeholder="Facebook Profile URL"
            value={formData.facebook || ''}
            onChange={(e) => handleChange('facebook')(e)}
          />
        </div>

        <div className="membership-personal-data-field">
          <label>TikTok</label>
          <input
            type="text"
            placeholder="@yourTikTokHandle"
            value={formData.tiktok || ''}
            onChange={(e) => handleChange('tiktok')(e)}
          />
        </div>

        <div className="membership-personal-data-field">
          <label>YouTube</label>
          <input
            type="text"
            placeholder="YouTube Channel URL"
            value={formData.youtube || ''}
            onChange={(e) => handleChange('youtube')(e)}
          />
        </div>

      <div className="verification-cross-reference-buttons">
        <button onClick={prevStep}>Back</button>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default VerificationCrossReference;
