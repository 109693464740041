// themeReducer.js
import { createReducer } from "@reduxjs/toolkit";
import {
  LOAD_THEME_REQUEST,
  LOAD_THEME_SUCCESS,
  LOAD_THEME_FAIL,
  UPDATE_THEME_REQUEST,
  UPDATE_THEME_SUCCESS,
  UPDATE_THEME_FAIL,
  UPLOAD_THEME_LOGO_REQUEST,
  UPLOAD_THEME_LOGO_SUCCESS,
  UPLOAD_THEME_LOGO_FAIL,
  CLEAR_THEME_ERRORS,
  CLEAR_THEME_MESSAGES,
} from "../actions/theme";

const initialState = {
  theme: {},
  loading: false,
  error: null,
  successMessage: null,
  logoUploadLoading: false,
};

export const themeReducer = createReducer(initialState, {
  // Load Theme
  [LOAD_THEME_REQUEST]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [LOAD_THEME_SUCCESS]: (state, action) => {
    state.loading = false;
    state.theme = action.payload;
    state.error = null;
  },
  [LOAD_THEME_FAIL]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  // Update Theme
  [UPDATE_THEME_REQUEST]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [UPDATE_THEME_SUCCESS]: (state, action) => {
    state.loading = false;
    state.theme = { ...state.theme, ...action.payload };
    state.successMessage = "Theme updated successfully!";
  },
  [UPDATE_THEME_FAIL]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  // Upload Theme Logo
  [UPLOAD_THEME_LOGO_REQUEST]: (state) => {
    state.logoUploadLoading = true;
    state.error = null;
  },
  [UPLOAD_THEME_LOGO_SUCCESS]: (state, action) => {
    state.logoUploadLoading = false;
    // Not necessarily updating state.theme.siteLogoUrl here,
    // because we might do that after `updateTheme`. But you can set it if you like:
    // state.theme.siteLogoUrl = action.payload;
  },
  [UPLOAD_THEME_LOGO_FAIL]: (state, action) => {
    state.logoUploadLoading = false;
    state.error = action.payload;
  },

  // Clears
  [CLEAR_THEME_ERRORS]: (state) => {
    state.error = null;
  },
  [CLEAR_THEME_MESSAGES]: (state) => {
    state.successMessage = null;
  },
});

