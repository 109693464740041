import React, { useState, useEffect, useRef } from "react";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { ecommerceServer } from "../../server";
import { toast } from "react-toastify";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import MiniLoader from "../Layout/MiniLoader";
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const profileType = sessionStorage.getItem("currentProfile") || "Guest";
  const userType = profileType === "User" ? "User" : "Shop";
  const { user } = useSelector((state) => state.user);
  const seller = useSelector((state) => state.seller.activeSeller);
  const userId = userType === "User" ? user?._id : seller?._id;
  const userEmail = userType === "User" ? user?.email : seller?.email;
  const cartItems = useSelector((state) => state.cart.carts[profileType] || []);
  const [guestName, setGuestName] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [guestPhone, setGuestPhone] = useState("");
  const [clientSecret, setClientSecret] = useState('');
  const [shippingRates, setShippingRates] = useState([]);
  const [country, setCountry] = useState("US");
  const [city, setCity] = useState("");
  const [stateValue, setStateValue] = useState(null);
  const [userInfo, setUserInfo] = useState(false);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [price, setPrice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCalculatingShipping, setIsCalculatingShipping] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [calculatedRates, setCalculatedRates] = useState([]);
  const [selectedRate, setSelectedRate] = useState(null);
  const [agreedToTOS, setAgreedToTOS] = useState(false);

  const navigate = useNavigate();

  // Simple scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Subtotal
  const subTotalPrice = cartItems.reduce((acc, item) => acc + item.qty * item.price, 0);

  // Handy check for shipping info
  const isShippingInfoComplete = () => country && stateValue && city && zipCode && address1;

  // -------------- Shipping Rate Calculation --------------
  const calculateShippingRates = async () => {
    if (!isShippingInfoComplete() || cartItems.length === 0) {
      toast.error("Please complete and validate your shipping info before calculating rates!");
      return;
    }
    setIsCalculatingShipping(true);

    const primaryShopId = cartItems[0]?.shop?._id;
    if (!primaryShopId) {
      toast.error("No shop found for these items.");
      setIsCalculatingShipping(false);
      return;
    }

    const isGuest = (profileType === "Guest");

    const shippingAddress = { address1, address2, city, state: stateValue, zipCode, country };
    const requestBody = {
      cartItems: cartItems.map(i => ({ productId: i._id, qty: i.qty })),
      shippingAddress,
      shopId: primaryShopId,
      guestName: isGuest ? guestName : undefined,
    };

    try {
      // Build appropriate headers (no token if guest)
      const headers = {
        'current-profile': profileType
      };
      if (profileType !== "Guest") {
        headers.Authorization = `Bearer ${user?.token || seller?.token}`;
      }

      const response = await axios.post(`${ecommerceServer}/shipping/calculate-shipping-checkout`, requestBody, {
        withCredentials: true,
        headers
      });

      setCalculatedRates(response.data.rates);
      toast.success("Shipping rates updated!");
    } catch (error) {
      console.error('Failed to fetch shipping rates:', error);
      toast.error("Failed to load shipping rates.");
    } finally {
      setIsCalculatingShipping(false);
    }
  };

  // Helper to compute shipping if needed
  const determineShippingRate = (cartItems, zipCode, state) => {
    const applicableRatesByRegion = shippingRates.filter(rate => {
      const matchesZip = rate.region.postalCodes.includes(zipCode);
      const matchesState = rate.region.states.includes(state);
      return matchesZip || matchesState;
    });

    const shippingCosts = cartItems.map(item => {
      const applicableRates = applicableRatesByRegion.filter(rate => {
        const weightInRange =
          item?.weight >= rate?.weightRange?.min &&
          (rate?.weightRange?.max === null || item?.weight <= rate?.weightRange?.max);

        const dimensionsInRange =
          item?.dimensions?.length <= rate?.dimensions?.length &&
          item?.dimensions?.width <= rate?.dimensions?.width &&
          item?.dimensions?.height <= rate?.dimensions?.height;

        return weightInRange && dimensionsInRange;
      });

      if (applicableRates.length > 0) {
        applicableRates.sort((a, b) => a.price - b.price);
        return applicableRates[0].price;
      }
      return 0;
    });

    return shippingCosts.reduce((acc, cost) => acc + cost, 0);
  };

  // -------------- Payment Submit --------------
  const paymentSubmit = async () => {
    if (!isShippingInfoComplete()) {
      toast.error("Please complete and validate your shipping address!");
      return;
    }
    if (!selectedRate) {
      toast.error("Please select a shipping option!");
      return;
    }
    if (address1 === "" || zipCode === "" || country === "" || city === "" || stateValue === null) {
      toast.error("Please complete your shipping address!");
      return;
    }

    // Calculate shipping cost
    const shippingCost = selectedRate.price || determineShippingRate(cartItems, zipCode, stateValue);
    const totalCost = subTotalPrice + shippingCost - (price || 0);

    const shippingAddress = {
      address1,
      address2,
      zipCode,
      country,
      city,
      state: stateValue
    };

    // Decide if we are a guest or not
    const isGuest = (profileType === "Guest");
    // Final contact info
    const finalEmail = isGuest ? guestEmail : userEmail;
    const finalName = isGuest ? guestName : (user?.name || seller?.name || "Guest");
    const finalPhone = isGuest ? guestPhone : (user?.phoneNumber || "000-000-0000");

    console.log("Guest Email:", guestEmail); // Debug: Check the value of guestEmail
    console.log("Final Email Sent:", finalEmail);

    // Prepare order data
    const orderData = {
      cartItems: cartItems.map(item => ({
        productId: item._id,
        shopId: item.shop._id,
        productName: item.name,
        description: item.description,
        images: item.image,
        quantity: item.qty,
        unitAmount: item.price * 100,
        connectedAccountId: item.shop.stripeAccountId,
        taxCode: item.taxCode,
        sku: item.SKU,
        variant: item.variant,
      })),
      currency: 'usd',
      returnUrl: 'https://buzzvibe.com/checkout-success',
      userId: isGuest ? null : userId,
      guestEmail: finalEmail,
      guestName: isGuest ? finalName : undefined,  
      guestPhone: isGuest ? finalPhone : undefined, 
      shippingAddress,
      totalPrice: totalCost,
      userType,
      currentProfile: profileType,
      couponCode: couponCodeData ? couponCodeData.name : null,
      selectedRateId: selectedRate.rate_id,
      shippingOptions: calculatedRates.map(rate => ({
        rate_id: rate.rate_id,
        carrier: rate.carrier,
        service: rate.service,
        price: rate.price,
        delivery_days: rate.delivery_days
      }))
    };

    try {
      // Build headers
      const headers = {
        'current-profile': profileType
      };
      if (profileType !== "Guest") {
        headers.Authorization = `Bearer ${user?.token || seller?.token}`;
      }

      const response = await axios.post(`${ecommerceServer}/payment/create-checkout-session`, orderData, {
        withCredentials: true,
        headers
      });

      if (response.data.clientSecret) {
        setClientSecret(response.data.clientSecret);
        openModal();
      } else {
        throw new Error('Client secret not provided.');
      }
    } catch (error) {
      console.error('Error during payment submission:', error);
      toast.error("Failed to initiate payment process.");
    }
  };

  // -------------- Fetch Shipping Rates on Load --------------
  useEffect(() => {
    const fetchShippingRates = async (shopId) => {
      try {
        const headers = { 'current-profile': profileType };
        if (profileType !== "Guest") {
          headers.Authorization = `Bearer ${user?.token || seller?.token}`;
        }
        const response = await axios.get(
          `${ecommerceServer}/shippingrule/checkout/shipping-rules`,
          {
            params: { shopId },
            withCredentials: true,
            headers
          }
        );
        return response.data;
      } catch (error) {
        console.error('Failed to fetch shipping rates:', error);
        return [];
      }
    };

    const fetchAllShippingRates = async () => {
      const allRates = [];
      for (const item of cartItems) {
        const rates = await fetchShippingRates(item.shop._id);
        allRates.push(...rates);
      }
      setShippingRates(allRates);
    };

    if (cartItems.length > 0) {
      fetchAllShippingRates();
    }
  }, [cartItems, profileType, user, seller]);

  // -------------- Coupon Submit --------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = couponCode.trim();
    if (!name) return;

    try {
      const response = await axios.get(`${ecommerceServer}/coupon/get-coupon-value/${name}`);
      const coupon = response.data.couponCode;
      if (!coupon) {
        toast.error("Coupon code doesn't exist!");
        setCouponCode("");
        return;
      }
      const shopId = coupon?.shopId;
      const couponCodeValue = coupon?.value;

      const isCouponValidForShop = cartItems.some((item) => item.shopId === shopId);
      const isCouponValidForProducts =
        coupon.shopWide ||
        coupon.selectedProducts.length === 0 ||
        cartItems.some((item) => coupon.selectedProducts.includes(item._id));

      if (!isCouponValidForShop) {
        toast.error("Coupon code is not valid for this shop");
        setCouponCode("");
      } else if (!isCouponValidForProducts) {
        toast.error("Coupon code is not valid for the products in your cart");
        setCouponCode("");
      } else {
        const eligibleItems = cartItems.filter(
          (item) =>
            coupon.shopWide || coupon.selectedProducts.includes(item._id)
        );
        const eligiblePrice = eligibleItems.reduce((acc, item) => acc + item.qty * item.price, 0);
        const discountPrice = (eligiblePrice * couponCodeValue) / 100;
        setPrice(discountPrice);
        setCouponCodeData(coupon);
        setCouponCode("");
      }
    } catch (error) {
      console.error("Failed to fetch coupon:", error);
      toast.error("Invalid coupon code.");
      setCouponCode("");
    }
  };

  // -------------- Render UI --------------
  return (
    <div className="checkout-component-wrapper">
      <div className="checkout-component-content">
        <div className="checkout-component-left">
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={['places']}
          >
            <ShippingInfo
              // Pass in user data, plus guest states
              user={user}
              seller={seller}
              isGuest={profileType === "Guest"}
              guestName={guestName}
              setGuestName={setGuestName}
              guestEmail={guestEmail}
              setGuestEmail={setGuestEmail}
              guestPhone={guestPhone}
              setGuestPhone={setGuestPhone}
              country={country}
              setCountry={setCountry}
              city={city}
              setCity={setCity}
              stateValue={stateValue}
              setStateValue={setStateValue}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              address1={address1}
              setAddress1={setAddress1}
              address2={address2}
              setAddress2={setAddress2}
              zipCode={zipCode}
              setZipCode={setZipCode}
              autocomplete={autocomplete}
              setAutocomplete={setAutocomplete}
            />
          </LoadScript>
          {isShippingInfoComplete() && (
            <button
              className="shipping-checkout-button"
              onClick={calculateShippingRates}
              disabled={isCalculatingShipping}
            >
              {isCalculatingShipping ? <MiniLoader /> : "Calculate Shipping"}
            </button>
          )}
          {calculatedRates.length > 0 && (
            <div className="shipping-checkout-options">
              <h3 className="shipping-checkout-title">Select a shipping method:</h3>
              <div className="shipping-checkout-options-group">
                {calculatedRates.map((rate, index) => (
                  <label key={index} className="shipping-checkout-option">
                    <input
                      type="radio"
                      name="shippingRate"
                      value={rate.rate_id}
                      checked={selectedRate && selectedRate.rate_id === rate.rate_id}
                      onChange={() => setSelectedRate(rate)}
                      className="shipping-checkout-radio"
                    />
                    <div className="shipping-checkout-option-details">
                      <span className="shipping-checkout-option-carrier">{rate.carrier}</span>
                      <span className="shipping-checkout-option-service">{rate.service}</span>
                      <span className="shipping-checkout-option-price">${rate.price}</span>
                      <span className="shipping-checkout-option-delivery">
                        Estimated: {rate.delivery_days} days
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="checkout-component-right">
          <CartData
            handleSubmit={handleSubmit}
            cartItems={cartItems}
            shippingRates={shippingRates}
            zipCode={zipCode}
            state={stateValue}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            price={price}
            couponCodeData={couponCodeData}
            selectedRate={selectedRate}
          />
          <div className="checkout-tos-container">
            <label className="checkout-tos-label">
              <input
                type="checkbox"
                checked={agreedToTOS}
                onChange={(e) => setAgreedToTOS(e.target.checked)}
                className="checkout-tos-checkbox"
              />
              <span className="checkout-tos-text">
                I agree to the{" "}
                <a
                  href="/terms-of-service"
                  className="checkout-tos-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Purchase
                </a>
              </span>
            </label>
          </div>
          <div className="checkout-component-button-container">
            <button
              className="checkout-component-button"
              onClick={paymentSubmit}
              disabled={!agreedToTOS}
            >
              <h5 className="checkout-component-button-text">Go to Payment</h5>
            </button>
          </div>
        </div>
      </div>

      <div className={`checkout-modal ${isModalOpen ? "open" : ""}`}>
        <div className="checkout-modal-content">
          <span className="close-checkout-modal-button" onClick={closeModal}>
            &times;
          </span>
          {clientSecret && (
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}
        </div>
      </div>
    </div>
  );
};

// ======================= ShippingInfo Component ======================
const ShippingInfo = ({
  user,
  seller,
  isGuest,
  guestName,
  setGuestName,
  guestEmail,
  setGuestEmail,
  guestPhone,
  setGuestPhone,
  country,
  setCountry,
  city,
  setCity,
  stateValue,
  setStateValue,
  userInfo,
  setUserInfo,
  address1,
  setAddress1,
  address2,
  setAddress2,
  zipCode,
  setZipCode,
  autocomplete,
  setAutocomplete,
}) => {
  const autocompleteRef = useRef(null);

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        const address = parseAddressComponents(place.address_components);
        setAddress1(address.streetAddress);
        setCity(address.city);
        setStateValue(address.state);
        setZipCode(address.zipCode);
        setCountry(address.country);
      } else {
        toast.error("No address details available.");
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const parseAddressComponents = (components) => {
    let streetAddress = "";
    let city = "";
    let state = "";
    let zipCode = "";
    let country = "";

    components.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number")) {
        streetAddress = component.long_name + " ";
      }
      if (types.includes("route")) {
        streetAddress += component.long_name;
      }
      if (types.includes("locality")) {
        city = component.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        state = component.short_name;
      }
      if (types.includes("postal_code")) {
        zipCode = component.long_name;
      }
      if (types.includes("country")) {
        country = component.short_name;
      }
    });

    return { streetAddress, city, state, zipCode, country };
  };

  // If not a guest, let's assume the user's data
  const defaultName = user ? user.name : seller?.name;
  const defaultEmail = user ? user.email : seller?.email;
  const defaultPhone = user ? user.phoneNumber : "";

  return (
    <div className="checkout-component-shipping-info">
      <h5 className="checkout-component-section-title">Shipping Address</h5>
      <br />
      <form>
        {/* Full Name */}
        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Full Name</label>
            <input
              type="text"
              required
              className="checkout-component-input"
              value={isGuest ? guestName : (defaultName || "")}
              onChange={(e) => {
                if (isGuest) setGuestName(e.target.value);
              }}
            />
          </div>
        </div>
        {/* Email */}
        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Email Address</label>
            <input
              type="email"
              required
              className="checkout-component-input"
              value={isGuest ? guestEmail : (defaultEmail || "")}
              onChange={(e) => {
                if (isGuest) setGuestEmail(e.target.value);
              }}
            />
          </div>
        </div>
        {/* Phone */}
        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Phone Number</label>
            {user ? (
              // If user is logged in, show a read-only input with the user's phoneNumber
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                required
                value={user.phoneNumber || ""}
                className="checkout-component-input"
              />
            ) : (
              // Otherwise (guest), show a blank input that can be typed into
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                required
                value={guestPhone}
                onChange={(e) => setGuestPhone(e.target.value)}
                className="checkout-component-input"
              />
            )}

          </div>
          {/* Zip Code */}
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Zip Code</label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              required
              className="checkout-component-input"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div>
        </div>
        {/* Country & State */}
        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Country</label>
            <input
              type="text"
              className="checkout-component-input"
              value="United States"
              readOnly
            />
          </div>
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">State</label>
            <select
              className="checkout-component-select"
              value={stateValue}
              onChange={(e) => setStateValue(e.target.value)}
            >
              <option value="">Choose your state</option>
              {State &&
                State.getStatesOfCountry("US").map((item) => {
                  const stateCode = item.isoCode.includes("-")
                    ? item.isoCode.split("-")[1]
                    : item.isoCode;
                  return (
                    <option key={item.isoCode} value={stateCode}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        {/* City */}
        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">City</label>
            <input
              type="text"
              className="checkout-component-input"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>
        </div>
        {/* Address1 & Address2 */}
        <div className="checkout-component-form-row">
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Address1</label>
            <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceChanged}>
              <input
                type="text"
                required
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                className="checkout-component-input"
                placeholder="Start typing your address..."
              />
            </Autocomplete>
          </div>
          <div className="checkout-component-form-group">
            <label className="checkout-component-label">Address2</label>
            <input
              type="text"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              className="checkout-component-input"
              placeholder="Apt, Suite, etc. (optional)"
            />
          </div>
        </div>
      </form>
      {/* Option to pick from user saved addresses (if logged in) */}
      {(user || seller) && (
        <>
          <h5
            className="checkout-component-saved-address-title"
            onClick={() => setUserInfo(!userInfo)}
          >
            Choose From saved address
          </h5>
          {userInfo && user && user.addresses && (
            <div>
              {user.addresses.map((item, index) => (
                <div key={index} className="checkout-component-saved-address-item">
                  <input
                    type="radio"
                    name="savedAddress"
                    className="checkout-component-checkbox"
                    onClick={() => {
                      setAddress1(item.address1);
                      setAddress2(item.address2);
                      setZipCode(item.zipCode);
                      setCountry(item.country);
                      setCity(item.city);
                      setStateValue(item.state);
                    }}
                  />
                  <div className="checkout-component-saved-address-details">
                    <h4 className="checkout-component-saved-address-name">{item.name}</h4>
                    <p>
                      {item.address1}
                      {item.address2 && `, ${item.address2}`}, {item.city}, {item.state}{" "}
                      {item.zipCode}
                    </p>
                    <p>{item.country}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

// ======================= CartData Component ======================
const CartData = ({
  handleSubmit,
  cartItems,
  shippingRates,
  zipCode,
  state,
  couponCode,
  setCouponCode,
  price,
  couponCodeData,
  selectedRate,
}) => {
  // Same determineShippingRate helper used in main
  const determineShippingRate = (cartItems, zipCode, state) => {
    const applicableRatesByRegion = shippingRates.filter(rate => {
      const matchesZipCode = rate.region.postalCodes.includes(zipCode);
      const matchesState = rate.region.states.includes(state);
      return matchesZipCode || matchesState;
    });

    const shippingCosts = cartItems.map(item => {
      const applicableRates = applicableRatesByRegion.filter(rate => {
        const weightInRange =
          item?.weight >= rate?.weightRange?.min &&
          (rate?.weightRange?.max === null || item?.weight <= rate?.weightRange?.max);

        const dimensionsInRange =
          item?.dimensions?.length <= rate?.dimensions?.length &&
          item?.dimensions?.width <= rate?.dimensions?.width &&
          item?.dimensions?.height <= rate?.dimensions?.height;

        return weightInRange && dimensionsInRange;
      });

      if (applicableRates.length > 0) {
        applicableRates.sort((a, b) => a.price - b.price);
        return applicableRates[0].price;
      }

      return 0;
    });

    return shippingCosts.reduce((acc, cost) => acc + cost, 0);
  };

  // Possibly we can calculate shipping
  const canCalculateShipping = zipCode || state;
  const shipping = selectedRate
    ? selectedRate.price
    : canCalculateShipping
    ? determineShippingRate(cartItems, zipCode, state)
    : null;

  // Subtotal
  const subTotalPrice = cartItems.reduce((acc, item) => acc + item.qty * item.price, 0);

  // Final total
  const totalPrice = price
    ? (subTotalPrice + (shipping || 0) - price).toFixed(2)
    : (subTotalPrice + (shipping || 0)).toFixed(2);

  return (
    <div className="checkout-component-cart-data">
      <h2 className="checkout-component-heading">Your Cart</h2>
      <ul className="checkout-component-cart-list">
        {cartItems.map((item) => (
          <li key={item.id} className="checkout-component-cart-list-item">
            <img
              src={item.image}
              alt={item.name}
              className="checkout-component-cart-item-image"
            />
            <div className="checkout-component-cart-item-details">
              <h4 className="checkout-component-cart-item-name">{item.name}</h4>
              <p className="checkout-component-cart-item-qty">
                Quantity: {item.qty}
              </p>
              <p className="checkout-component-cart-item-price">
                Price: ${(item.price * item.qty).toFixed(2)}
              </p>
            </div>
          </li>
        ))}
      </ul>

      <div className="checkout-component-cart-summary">
        <div className="checkout-component-cart-item">
          <h3 className="checkout-component-cart-label">Subtotal:</h3>
          <h4 className="checkout-component-cart-value">${subTotalPrice.toFixed(2)}</h4>
        </div>
        <div className="checkout-component-cart-item">
          <h3 className="checkout-component-cart-label">Shipping:</h3>
          <h4 className="checkout-component-cart-value">
            {canCalculateShipping
              ? `$${(shipping || 0).toFixed(2)}`
              : "Please enter and validate your address to determine shipping price"}
          </h4>
        </div>
        <div className="checkout-component-cart-item">
          <h3 className="checkout-component-cart-label">Discount:</h3>
          <h4 className="checkout-component-cart-value">
            - {price ? `$${price.toFixed(2)}` : "N/A"}
          </h4>
        </div>
        {couponCodeData && (
          <div className="checkout-component-cart-item">
            <h3 className="checkout-component-cart-label">Active Coupon:</h3>
            <h4 className="checkout-component-cart-value">{couponCodeData.name}</h4>
          </div>
        )}
        <h4 className="checkout-component-total-price">Total: ${totalPrice}</h4>
      </div>

      <form onSubmit={handleSubmit} className="checkout-component-coupon-form">
        <input
          type="text"
          className="checkout-component-input"
          placeholder="Coupon code"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          required
        />
        <input className="checkout-component-apply-code-button" value="Apply code" type="submit" />
      </form>
    </div>
  );
};

export default Checkout;



