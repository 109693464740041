import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineClose,
  AiOutlinePlus,
  AiOutlineUnorderedList,
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineSearch,
  AiOutlineClose as AiOutlineClear,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import EditProduct from "../Products/EditProducts";
import axios from "axios";
import { getAllProductsShop } from "../../redux/actions/product";
import { ecommerceServer } from "../../server";
import AddInventory from "./AddInventory";
import InventoryLog from "./InventoryLog";
import {
  FunnelIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import ProductDetailsModal from "../Products/ProductDetailsModal";
import CreateProductPrompt from "../Products/CreateProductPrompt";

const AllProducts = () => {
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [addInventoryModalOpen, setAddInventoryModalOpen] = useState(false);
  const [inventoryLogModalOpen, setInventoryLogModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBrandType, setSelectedBrandType] = useState("");
  const [availableForPickup, setAvailableForPickup] = useState(false);
  const [availableForDelivery, setAvailableForDelivery] = useState(false);
  const [isEdible, setIsEdible] = useState(false);
  const [isOnSale, setIsOnSale] = useState(false);
  const [inStockOnly, setInStockOnly] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [mobileDetailModalOpen, setMobileDetailModalOpen] = useState(false);
  const [expandedProducts, setExpandedProducts] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (seller && seller._id) {
      dispatch(getAllProductsShop(seller._id));
    }
  }, [dispatch, seller]);

  const toggleExpandProduct = (productId) => {
    setExpandedProducts((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  const clearFilters = () => {
    setSelectedProductType("");
    setSelectedCategory("");
    setSelectedBrandType("");
    setAvailableForPickup(false);
    setAvailableForDelivery(false);
    setIsEdible(false);
    setIsOnSale(false);
    setInStockOnly(false);
  };

  const openDeleteModal = (id) => {
    setDeleteProductId(id);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${ecommerceServer}/product/delete-shop-product/${deleteProductId}`,
        {
          withCredentials: true,
          headers: {
            "current-profile":
              sessionStorage.getItem("currentProfile") || "defaultProfile",
          },
        }
      );
      dispatch(getAllProductsShop(seller._id));
      setDeleteModalOpen(false);
    } catch (error) {
      setErrorMessage(
        "Failed to delete product. Please try again later."
      );
      console.error("Error deleting product:", error.response.data);
    }
  };

  const handleEdit = (productId) => {
    setSelectedProductId(productId);
    setEditModalOpen(true);
  };
  const handleAddInventory = (productId) => {
    setSelectedProductId(productId);
    setAddInventoryModalOpen(true);
  };
  const handleViewInventoryLog = (productId) => {
    setSelectedProductId(productId);
    setInventoryLogModalOpen(true);
  };

  const onInventoryAdded = () => {
    dispatch(getAllProductsShop(seller._id));
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const handleViewProductDetails = (productId) => {
    setSelectedProductId(productId);
    setMobileDetailModalOpen(true);
  };

  const closeMobileDetailModal = () => {
    setMobileDetailModalOpen(false);
    setSelectedProductId(null);
  };

  // Helper functions to handle price and stock display
  const getPriceDisplay = (product) => {
    if (product.discountPrice !== null && product.discountPrice !== undefined) {
      return `US$ ${product.discountPrice.toFixed(2)}`;
    } else if (Array.isArray(product.variants) && product.variants.length > 0) {
      const variantPrices = product.variants
        .map((v) => v.price)
        .filter((price) => price !== null && price !== undefined);

      if (variantPrices.length > 0) {
        const minPrice = Math.min(...variantPrices);
        const maxPrice = Math.max(...variantPrices);
        if (minPrice === maxPrice) {
          return `US$ ${minPrice.toFixed(2)}`;
        } else {
          return `US$ ${minPrice.toFixed(2)} - US$ ${maxPrice.toFixed(2)}`;
        }
      } else {
        return 'No Price Available';
      }
    } else {
      return 'No Price Available';
    }
  };

  const getStockDisplay = (product) => {
    if (product.stock !== null && product.stock !== undefined) {
      return product.stock;
    } else if (Array.isArray(product.variants) && product.variants.length > 0) {
      return product.variants.reduce(
        (total, variant) => total + (variant.stock || 0),
        0
      );
    } else {
      return 0;
    }
  };

  const rows = Array.isArray(products)
    ? products
        .filter(
          (product) =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (selectedProductType
              ? product.productType.includes(selectedProductType)
              : true) &&
            (selectedCategory ? product.category === selectedCategory : true) &&
            (selectedBrandType
              ? product.brandTypeSelection === selectedBrandType
              : true) &&
            (!inStockOnly || getStockDisplay(product) > 0) &&
            (!availableForPickup || product.availableForPickup) &&
            (!availableForDelivery || product.availableForDelivery) &&
            (!isEdible || product.isEdible) &&
            (!isOnSale || product.isOnSale)
        )
        .map((product) => ({
          id: product._id,
          name: product.name,
          productType: Array.isArray(product.productType)
            ? product.productType.join(", ")
            : product.productType,
          price: getPriceDisplay(product),
          stock: getStockDisplay(product),
          sold: product.sold_out,
          image: product.images?.[0]?.url || "defaultProductImageUrl",
          SKU: product.SKU || (product.variants?.[0]?.sku || "Varies"),
          category: product.category,
          variants: product.variants || [],
        }))
    : [];

  const mobileRows = Array.isArray(products)
    ? products
        .filter(
          (product) =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (selectedProductType
              ? product.productType.includes(selectedProductType)
              : true) &&
            (selectedCategory ? product.category === selectedCategory : true) &&
            (selectedBrandType
              ? product.brandTypeSelection === selectedBrandType
              : true) &&
            (!inStockOnly || getStockDisplay(product) > 0) &&
            (!availableForPickup || product.availableForPickup) &&
            (!availableForDelivery || product.availableForDelivery) &&
            (!isEdible || product.isEdible) &&
            (!isOnSale || product.isOnSale)
        )
        .map((product) => ({
          id: product._id,
          name: product.name,
          productType: Array.isArray(product.productType)
            ? product.productType.join(", ")
            : product.productType,
          price: getPriceDisplay(product),
          stock: getStockDisplay(product),
          sold: product.sold_out,
          image: product.images?.[0]?.url || "defaultProductImageUrl",
          SKU: product.SKU || (product.variants?.[0]?.sku || "Varies"),
          category: product.category,
        }))
    : [];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="all-products-component">
          {rows.length === 0 ? (
            <CreateProductPrompt />
          ) : (
            <>
              <div className="all-products-component-header">
                <h1>All Products</h1>
                <div className="all-products-component-header-box">
                  <button
                    className="all-products-component-create-product-button"
                    onClick={() => navigate('/dashboard-create-product')}
                  >
                    Create New Product
                  </button>
                  <div className="all-products-search-box">
                    <input
                      type="text"
                      placeholder="Search products..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="all-products-search-input"
                    />
                    <AiOutlineSearch size={20} className="all-products-search-icon" />
                    {searchTerm && (
                      <AiOutlineClear
                        size={18}
                        className="all-products-search-clear-icon"
                        onClick={clearSearch}
                      />
                    )}
                  </div>
                  <button
                    onClick={() => setSidebarOpen(true)}
                    className="all-products-component-mobile-filter-button"
                  >
                    <FunnelIcon className="all-products-filter-icon" />
                  </button>
                </div>
              </div>

              {/* Sidebar for Mobile Filters */}
              <div
                className={`all-products-component-sidebar ${
                  sidebarOpen ? "open" : ""
                }`}
              >
                <div className="all-products-component-sidebar-header">
                  <h2>Filters</h2>
                  <button
                    className="all-products-component-sidebar-close"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <AiOutlineClose />
                  </button>
                </div>
                <div className="all-products-component-sidebar-content">
                  <button
                    onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
                    className="all-products-component-desktop-filter-button"
                  >
                    <FunnelIcon className="all-products-filter-icon" />
                  </button>
                  <div className="all-products-component-filters">
                    {/* Filters */}
                    <select
                      value={selectedProductType}
                      onChange={(e) => setSelectedProductType(e.target.value)}
                      className="all-products-component-filter-select"
                    >
                      <option value="">All Product Types</option>
                      {Array.isArray(products) &&
                        Array.from(
                          new Set(
                            products.flatMap((product) =>
                              product.productType ? product.productType : []
                            )
                          )
                        )
                          .filter(Boolean)
                          .map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                    </select>

                    <select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                      className="all-products-component-filter-select"
                    >
                      <option value="">All Categories</option>
                      {Array.isArray(products) &&
                        Array.from(
                          new Set(products.map((product) => product.category))
                        )
                          .filter(Boolean)
                          .map((category, index) => (
                            <option key={index} value={category}>
                              {category}
                            </option>
                          ))}
                    </select>

                    <select
                      value={selectedBrandType}
                      onChange={(e) => setSelectedBrandType(e.target.value)}
                      className="all-products-component-filter-select"
                    >
                      <option value="">All Brand Types</option>
                      <option value="branded">Branded</option>
                      <option value="custom">Custom</option>
                    </select>

                    <div className="all-products-filter-toggle-mobile-list">
                      <div className="all-products-filter-toggle-mobile">
                        <label className="all-products-filter-toggle-label-mobile">
                          <input
                            type="checkbox"
                            className="all-products-filter-toggle-input"
                            checked={inStockOnly}
                            onChange={(e) => setInStockOnly(e.target.checked)}
                          />
                          <span className="all-products-filter-toggle-slider"></span>
                          <span className="all-products-filter-toggle-text">
                            In Stock Only
                          </span>
                        </label>
                      </div>

                      <div className="all-products-filter-toggle-mobile">
                        <label className="all-products-filter-toggle-label-mobile">
                          <input
                            type="checkbox"
                            className="all-products-filter-toggle-input"
                            checked={availableForPickup}
                            onChange={(e) =>
                              setAvailableForPickup(e.target.checked)
                            }
                          />
                          <span className="all-products-filter-toggle-slider"></span>
                          <span className="all-products-filter-toggle-text">
                            Available for Pickup
                          </span>
                        </label>
                      </div>

                      <div className="all-products-filter-toggle-mobile">
                        <label className="all-products-filter-toggle-label-mobile">
                          <input
                            type="checkbox"
                            className="all-products-filter-toggle-input"
                            checked={availableForDelivery}
                            onChange={(e) =>
                              setAvailableForDelivery(e.target.checked)
                            }
                          />
                          <span className="all-products-filter-toggle-slider"></span>
                          <span className="all-products-filter-toggle-text">
                            Available for Delivery
                          </span>
                        </label>
                      </div>

                      <div className="all-products-filter-toggle-mobile">
                        <label className="all-products-filter-toggle-label-mobile">
                          <input
                            type="checkbox"
                            className="all-products-filter-toggle-input"
                            checked={isEdible}
                            onChange={(e) => setIsEdible(e.target.checked)}
                          />
                          <span className="all-products-filter-toggle-slider"></span>
                          <span className="all-products-filter-toggle-text">
                            Edible
                          </span>
                        </label>
                      </div>

                      <div className="all-products-filter-toggle-mobile">
                        <label className="all-products-filter-toggle-label-mobile">
                          <input
                            type="checkbox"
                            className="all-products-filter-toggle-input"
                            checked={isOnSale}
                            onChange={(e) => setIsOnSale(e.target.checked)}
                          />
                          <span className="all-products-filter-toggle-slider"></span>
                          <span className="all-products-filter-toggle-text">
                            On Sale
                          </span>
                        </label>
                      </div>

                      <button
                        onClick={clearFilters}
                        className="all-products-component-clear-filters-button"
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Dropdown for Desktop Filters */}
              <div
                className={`all-products-component-filter-dropdown ${
                  filterDropdownOpen ? "open" : ""
                }`}
              >
                <div className="all-products-component-filters-list">
                  <div className="all-products-filter-toggle">
                    <label className="all-products-filter-toggle-label">
                      <input
                        type="checkbox"
                        className="all-products-filter-toggle-input"
                        checked={inStockOnly}
                        onChange={(e) => setInStockOnly(e.target.checked)}
                      />
                      <span className="all-products-filter-toggle-slider"></span>
                      <span className="all-products-filter-toggle-text">
                        In Stock Only
                      </span>
                    </label>
                  </div>

                  <div className="all-products-filter-toggle">
                    <label className="all-products-filter-toggle-label">
                      <input
                        type="checkbox"
                        className="all-products-filter-toggle-input"
                        checked={availableForPickup}
                        onChange={(e) => setAvailableForPickup(e.target.checked)}
                      />
                      <span className="all-products-filter-toggle-slider"></span>
                      <span className="all-products-filter-toggle-text">
                        Available for Pickup
                      </span>
                    </label>
                  </div>

                  <div className="all-products-filter-toggle">
                    <label className="all-products-filter-toggle-label">
                      <input
                        type="checkbox"
                        className="all-products-filter-toggle-input"
                        checked={availableForDelivery}
                        onChange={(e) =>
                          setAvailableForDelivery(e.target.checked)
                        }
                      />
                      <span className="all-products-filter-toggle-slider"></span>
                      <span className="all-products-filter-toggle-text">
                        Available for Delivery
                      </span>
                    </label>
                  </div>

                  <div className="all-products-filter-toggle">
                    <label className="all-products-filter-toggle-label">
                      <input
                        type="checkbox"
                        className="all-products-filter-toggle-input"
                        checked={isEdible}
                        onChange={(e) => setIsEdible(e.target.checked)}
                      />
                      <span className="all-products-filter-toggle-slider"></span>
                      <span className="all-products-filter-toggle-text">
                        Edible
                      </span>
                    </label>
                  </div>

                  <div className="all-products-filter-toggle">
                    <label className="all-products-filter-toggle-label">
                      <input
                        type="checkbox"
                        className="all-products-filter-toggle-input"
                        checked={isOnSale}
                        onChange={(e) => setIsOnSale(e.target.checked)}
                      />
                      <span className="all-products-filter-toggle-slider"></span>
                      <span className="all-products-filter-toggle-text">
                        On Sale
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="all-products-component-data-grid-container desktop-only">
                <table>
                  <thead className="all-products-component-header">
                    <tr>
                      <th>Variations</th>
                      <th>Image</th>
                      <th>Product ID</th>
                      <th>Name</th>
                      <th>Product Type</th>
                      <th>Price</th>
                      <th>Stock</th>
                      <th>SKU</th>
                      <th>Category</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row) => {
                      const hasVariants = Array.isArray(row.variants) && row.variants.length > 0;

                      return (
                        <React.Fragment key={row.id}>
                          <tr className="product-row">
                            <td>
                              {hasVariants ? (
                                <button
                                  onClick={() => toggleExpandProduct(row.id)}
                                  className="expander-button"
                                >
                                  {expandedProducts[row.id] ? (
                                    <AiOutlineUp size={20} />
                                  ) : (
                                    <AiOutlineDown size={20} />
                                  )}
                                </button>
                              ) : (
                                /* Spacer if no variants */
                                <div style={{ width: "20px" }} />
                              )}
                            </td>
                            <td>
                              <img
                                src={row.image}
                                alt={row.name}
                                className="all-products-image"
                              />
                            </td>
                            <td>{row.id}</td>
                            <td>{row.name}</td>
                            <td>{row.productType}</td>
                            <td>{row.price}</td>
                            <td>{row.stock}</td>
                            <td>{row.SKU}</td>
                            <td>{row.category}</td>
                            <td className="actions">
                              <Link
                                to={`/product/${row.id}`}
                                style={{ marginRight: "2px" }}
                              >
                                <AiOutlineEye size={20} title="Preview" />
                              </Link>
                              <button
                                onClick={() => handleEdit(row.id)}
                                style={{ color: "#4B5563", marginRight: "2px" }}
                              >
                                <AiOutlineEdit size={20} title="Edit" />
                              </button>
                              <button
                                onClick={() => openDeleteModal(row.id)}
                                style={{ color: "#EF4444" }}
                              >
                                <AiOutlineDelete size={20} title="Delete" />
                              </button>
                              <button
                                onClick={() => handleAddInventory(row.id)}
                                style={{ color: "#4CAF50" }}
                              >
                                <AiOutlinePlus size={20} title="Add Inventory" />
                              </button>
                              <button
                                onClick={() => handleViewInventoryLog(row.id)}
                                style={{ color: "#FFC107" }}
                              >
                                <AiOutlineUnorderedList
                                  size={20}
                                  title="View Inventory Log"
                                />
                              </button>
                            </td>
                          </tr>

                          {/* Expandable variant rows */}
                          {expandedProducts[row.id] && hasVariants && (
                            <tr className="variant-row">
                              <td colSpan="10">
                                <table className="variant-table">
                                  <thead>
                                    <tr>
                                      <th>Image</th>
                                      <th>Variant SKU</th>
                                      <th>Variant Price</th>
                                      <th>Variant Stock</th>
                                      <th>Color</th>
                                      <th>Size</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {row.variants.map((variant) => (
                                      <tr key={variant._id}>
                                        <td>
                                          <img
                                            src={variant.image?.url || row.image}
                                            alt={variant.sku}
                                            className="variant-image"
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </td>
                                        <td>{variant.sku}</td>
                                        <td>US$ {variant.price.toFixed(2)}</td>
                                        <td>{variant.stock}</td>
                                        <td>{variant.color || "N/A"}</td>
                                        <td>{variant.size || "N/A"}</td> 
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}

                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {/* Mobile List */}
              <ul role="list" className="all-products-table-mobile-list mobile-only">
                {mobileRows.map((product) => (
                  <li
                    key={product.id}
                    className="all-products-table-mobile-list-item"
                    onClick={() => handleViewProductDetails(product.id)}
                  >
                    <div className="all-products-table-mobile-content">
                      <div className="all-products-table-mobile-header">
                        <img
                          src={product.image}
                          alt={product.name}
                          className="all-products-table-mobile-image"
                        />
                        <div className="all-products-table-mobile-info">
                          <div className="all-products-table-mobile-name">
                            {product.name}
                          </div>
                          <div className="all-products-table-mobile-price">
                            {product.price}
                          </div>
                        </div>
                        <div className="all-products-table-mobile-details">
                          <p className="all-products-table-mobile-status">
                            Stock: {product.stock}
                          </p>
                        </div>
                      </div>
                    </div>
                    <ChevronRightIcon
                      aria-hidden="true"
                      className="all-products-table-mobile-chevron"
                    />
                  </li>
                ))}
              </ul>

              {/* Mobile Product Details Modal */}
              {mobileDetailModalOpen && (
                <ProductDetailsModal
                  product={products.find(
                    (product) =>
                      product._id === selectedProductId ||
                      product.id === selectedProductId
                  )}
                  onClose={closeMobileDetailModal}
                  handleEdit={handleEdit}
                  openDeleteModal={openDeleteModal}
                  handleAddInventory={handleAddInventory}
                  handleViewInventoryLog={handleViewInventoryLog}
                />
              )}
            </>
          )}
        </div>
      )}

      {editModalOpen && (
        <>
          <div
            className="all-products-component-modal-overlay"
            onClick={() => setEditModalOpen(false)}
          />
          <div className="all-products-component-modal-style">
            <AiOutlineClose
              className="all-products-component-close-icon-style"
              onClick={() => setEditModalOpen(false)}
            />
            {selectedProductId && (
              <EditProduct
                productId={selectedProductId}
                onClose={() => setEditModalOpen(false)}
              />
            )}
          </div>
        </>
      )}
      {deleteModalOpen && (
        <>
          <div
            className="all-products-component-modal-overlay"
            onClick={() => setDeleteModalOpen(false)}
          />
          <div className="all-products-component-delete-modal-style">
            <h2>Are you sure you want to delete this product?</h2>
            <button
              onClick={handleDelete}
              className="all-products-component-delete-button"
            >
              Yes
            </button>
            <button
              onClick={() => setDeleteModalOpen(false)}
              className="all-products-component-cancel-button"
            >
              No
            </button>
          </div>
        </>
      )}
      {addInventoryModalOpen && (
        <>
          <div
            className="all-products-component-modal-overlay"
            onClick={() => setAddInventoryModalOpen(false)}
          />
          <div className="all-products-component-modal-style">
            <AiOutlineClose
              className="all-products-component-close-icon-style"
              onClick={() => setAddInventoryModalOpen(false)}
            />
            {selectedProductId && (
              <AddInventory
                productId={selectedProductId}
                onClose={() => setAddInventoryModalOpen(false)}
                onInventoryAdded={onInventoryAdded}
              />
            )}
          </div>
        </>
      )}
      {inventoryLogModalOpen && (
        <>
          <div
            className="all-products-component-modal-overlay"
            onClick={() => setInventoryLogModalOpen(false)}
          />
          <div className="all-products-component-modal-style">
            <AiOutlineClose
              className="all-products-component-close-icon-style"
              onClick={() => setInventoryLogModalOpen(false)}
            />
            {selectedProductId && <InventoryLog productId={selectedProductId} />}
          </div>
        </>
      )}
    </>
  );
};

export default AllProducts;




