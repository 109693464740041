import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import AuthenticationPrompt from "../../Profile/AuthenticationPrompt";

const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const isLoggedIn = Boolean(user?._id || seller?._id);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState(
    data?.variants ? data.variants[0] : null
  );
  const [selectedColor, setSelectedColor] = useState(
    data?.variants ? data.variants[0]?.color : null
  );
  const [selectedSize, setSelectedSize] = useState(
    data?.variants ? data.variants[0]?.size : null
  );
  const [selectedCustomValue, setSelectedCustomValue] = useState(
    data?.variants ? data.variants[0]?.customValue : null
  );
  const dispatch = useDispatch();
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }

    // Set default variant on load
    if (data?.variants?.length > 0) {
      const defaultVariant = data.variants[0];
      setSelectedVariant(defaultVariant);
      setSelectedColor(defaultVariant.color);
      setSelectedSize(defaultVariant.size);
      setSelectedCustomValue(defaultVariant.customValue);
    } else {
      // If no variants exist, treat the general product as the selected "variant"
      setSelectedVariant(data);
    }
  }, [wishlist, data]);

  const requireLogin = (action) => {
    if (!isLoggedIn) {
      setShowAuthPrompt(true);
      return;
    }
    action();
  };

  const handleQuantityChange = (event, amount) => {
    event.stopPropagation();
    if (amount < 0) {
      setQuantity(Math.max(1, quantity + amount));
    } else {
      setQuantity(Math.min(selectedVariant?.stock || data.stock, quantity + amount));
    }
  };

  const removeFromWishlistHandler = (item) => {
    setClick(false);
    dispatch(removeFromWishlist(item));
  };

  const addToWishlistHandler = (item) => {
    setClick(true);
    dispatch(addToWishlist(item));
  };

  const handleVariantChange = (variant) => {
    setSelectedVariant(variant);
    setSelectedColor(variant.color);
    setSelectedSize(variant.size);
    setSelectedCustomValue(variant.customValue);
    setQuantity(1); // Reset quantity when variant changes
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    const matchingVariant = data.variants.find(
      (variant) =>
        variant.color === color &&
        variant.size === selectedSize &&
        variant.customValue === selectedCustomValue
    );
    if (matchingVariant) {
      handleVariantChange(matchingVariant);
    } else {
      setSelectedVariant(null);
      setQuantity(1);
      toast.error("Selected variant not available.");
    }
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    const matchingVariant = data.variants.find(
      (variant) =>
        variant.size === size &&
        variant.color === selectedColor &&
        variant.customValue === selectedCustomValue
    );
    if (matchingVariant) {
      handleVariantChange(matchingVariant);
    } else {
      setSelectedVariant(null);
      setQuantity(1);
      toast.error("Selected variant not available.");
    }
  };

  const handleCustomValueChange = (customValue) => {
    setSelectedCustomValue(customValue);
    const matchingVariant = data.variants.find(
      (variant) =>
        variant.customValue === customValue &&
        variant.color === selectedColor &&
        variant.size === selectedSize
    );
    if (matchingVariant) {
      handleVariantChange(matchingVariant);
    } else {
      setSelectedVariant(null);
      setQuantity(1);
      toast.error("Selected variant not available.");
    }
  };

  const handleAddToCart = () => {
    // Check if the product has variants
    if (data.variants?.length > 0 && !selectedVariant) {
      toast.error("Please select a valid variant before adding to cart!");
      return;
    }

    // Identify if the item is already in the cart
    const isItemExists =
      cart &&
      cart.find(
        (item) =>
          item._id === data._id &&
          (!item.variant || item.variant?._id === selectedVariant?._id)
      );

    if (isItemExists) {
      toast.error("This product is already in the cart!");
    } else {
      // Check stock
      const availableStock = data.variants?.length > 0
        ? selectedVariant?.stock
        : data.stock;

      if (availableStock < 1) {
        toast.error("Product is out of stock!");
      } else {
        // Prepare the data to be sent to the cart
        const cartData = {
          _id: data._id,
          name: data.name,
          brand: data.brand,
          SKU: selectedVariant?.sku || data.SKU,
          category: data.category,
          brandTypeSelection: data.brandTypeSelection,
          description: selectedVariant?.description || data.description,
          price: selectedVariant?.price ?? data.discountPrice ?? data.price,
          stock: selectedVariant?.stock ?? data.stock,
          weight: selectedVariant?.weight ?? data.weight,
          weightUnitOfMeasurement:
            selectedVariant?.weightUnitOfMeasurement || data.weightUnitOfMeasurement,
          dimensions: selectedVariant?.dimensions || data.dimensions,
          dimensionUnitOfMeasurement:
            selectedVariant?.dimensionUnitOfMeasurement ||
            data.dimensionUnitOfMeasurement,
          image:
            selectedVariant?.image?.url || data.images[0]?.url || "/placeholder-image.jpg",
          qty: quantity,
          variant: selectedVariant || null,
          availableForDelivery: data.availableForDelivery,
          availableForPickup: data.availableForPickup,
          barcodeString: data.barcodeString,
          isEdible: data.isEdible,
          isOnSale: data.isOnSale,
          taxCode: data.taxCode,
          shopId: data.shopId,
          shop: data.shop,
        };

        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const handleColorClick = (color) => {
    handleColorChange(color);
  };

  const isSoldOut = selectedVariant
    ? selectedVariant.stock <= 0
    : data.stock <= 0;

  // -- Render the Auth Prompt Modal if user/seller is not logged in --
  const authPromptModal = showAuthPrompt
    ? ReactDOM.createPortal(
        <div className="auth-modal-backdrop" onClick={() => setShowAuthPrompt(false)}>
          <div
            className="auth-modal-content"
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
          >
            <AuthenticationPrompt />
          </div>
        </div>,
        document.body
      )
    : null;

  return (
    <>
      <div className="product-card-component-container">
        <Link
          to={
            isEvent === true
              ? `/product/${data._id}?isEvent=true`
              : `/product/${data._id}`
          }
        >
          <img
            src={`${
              selectedVariant?.image?.url ||
              data.images?.[0]?.url ||
              "/placeholder-image.jpg"
            }`}
            alt={data.name}
            className="product-card-component-image"
          />
          {isSoldOut && (
            <div className="product-card-component-sold-out-overlay">
              <span className="product-card-component-sold-out-text">
                Sold Out
              </span>
            </div>
          )}
          <div className="product-card-component-info-container">
            <h4 className="product-card-component-product-name">
              {data.name.length > 40
                ? `${data.name.slice(0, 40)}...`
                : data.name}
            </h4>
            <div className="product-card-component-shop">
              <img
                className="product-card-component-shop-avatar"
                src={`${data.shop?.avatar?.url || "/default-shop-avatar.png"}`}
                alt={`${data.shop?.name}'s avatar`}
              />
              <h5 className="product-card-component-shop-name">
                {data.shop?.name}
              </h5>
            </div>

            {data?.ratings ? (
              <div className="product-card-component-ratings">
                <Ratings rating={data.ratings} />
              </div>
            ) : null}

            <div className="product-card-component-price-section">
              <div className="product-card-component-price">
                {data.isOnSale ? (
                  <>
                    {/* Discounted price if the product is on sale */}
                    <h5 className="product-card-component-discount-price">
                      ${selectedVariant?.price || data.discountPrice}
                    </h5>
                    <h4 className="product-card-component-original-price">
                      ${selectedVariant?.originalPrice || data.originalPrice}
                    </h4>
                  </>
                ) : (
                  <>
                    {/* Show original price only if not on sale */}
                    <h5 className="product-card-component-regular-price">
                      ${selectedVariant?.originalPrice || data.originalPrice}
                    </h5>
                  </>
                )}
              </div>
            </div>
          </div>
        </Link>

        {/* Variant Selection: Color Swatches */}
        {data?.variants?.some((variant) => variant.color) && (
          <div className="product-card-component-color-swatches">
            {[
              ...new Map(
                data.variants
                  .filter((variant) => variant.color)
                  .map((variant) => [variant.color, variant])
              ).values(),
            ].map((variant, index) => (
              <div
                key={index}
                className={`product-card-component-color-swatch ${
                  selectedColor === variant.color ? "active" : ""
                }`}
                style={{ backgroundColor: variant.colorSwatch || "#000" }}
                onClick={() => handleColorClick(variant.color)}
              />
            ))}
          </div>
        )}

        {/* Variant Selection: Size Buttons */}
        {data?.variants?.some((variant) => variant.size) && (
          <div className="product-card-component-size-selection">
            <div className="size-buttons-container">
              {[
                ...new Map(
                  data.variants
                    .filter((variant) => variant.size)
                    .map((variant) => [variant.size, variant])
                ).values(),
              ].map((variant, index) => (
                <button
                  key={index}
                  className={`size-btn ${
                    selectedSize === variant.size ? "active" : ""
                  }`}
                  onClick={() => handleSizeChange(variant.size)}
                >
                  {variant.size}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Variant Selection: Custom Value Buttons */}
        {data?.variants?.some((variant) => variant.customValue) && (
          <div className="product-card-component-custom-value-selection">
            <h4>{data.variants[0]?.customTitle || "Customization"}</h4>
            <div className="custom-value-buttons-container">
              {[
                ...new Map(
                  data.variants
                    .filter((variant) => variant.customValue)
                    .map((variant) => [variant.customValue, variant])
                ).values(),
              ].map((variant, index) => (
                <button
                  key={index}
                  className={`custom-value-btn ${
                    selectedCustomValue === variant.customValue ? "active" : ""
                  }`}
                  onClick={() => handleCustomValueChange(variant.customValue)}
                >
                  {variant.customValue}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Quantity Selector and Add to Cart */}
        <div className="product-card-component-quantity-selector">
          <button
            onClick={(e) => handleQuantityChange(e, -1)}
            className="product-card-component-quantity-button"
          >
            -
          </button>
          <span className="product-card-component-quantity">{quantity}</span>
          <button
            onClick={(e) => handleQuantityChange(e, 1)}
            className="product-card-component-quantity-button"
            disabled={quantity >= (selectedVariant?.stock || data.stock)}
          >
            +
          </button>
          <AiOutlineShoppingCart
            size={25}
            className="product-card-component-cart-icon"
            onClick={(e) => {
              e.stopPropagation();
              requireLogin(() => handleAddToCart());
            }}
            color="#444"
            title="Add to cart"
          />
        </div>

        {/* Wishlist Icon */}
        <div className="product-card-component-wishlist-icon">
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer"
              onClick={() => {
                // Wrap remove logic in requireLogin if you also want to block removal until logged in
                requireLogin(() => removeFromWishlistHandler(data));
              }}
              color={click ? "red" : "#fff"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer"
              onClick={() => {
                requireLogin(() => addToWishlistHandler(data));
              }}
              color={click ? "red" : "#fff"}
              title="Add to wishlist"
            />
          )}
        </div>

        {open && <ProductDetailsCard setOpen={setOpen} data={data} />}

        {/* Brand Badge */}
        {data.brandTypeSelection === "branded" && (
          <div className="product-card-component-brand-badge">
            <img
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1710252613/feat_morfbr.png"
              alt="Featured Brand"
              className="product-card-component-brand-image"
            />
          </div>
        )}
      </div>

      {/* Render Auth Prompt Modal if user/seller is not logged in */}
      {authPromptModal}
    </>
  );
};

export default ProductCard;



