import React, { useState } from "react";
import axios from "axios";
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { AiOutlineCamera } from "react-icons/ai";
import { loadUser } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ImageCropperModal from "../Image/ImageCropperModal";

const UserBanner = ({ isUser, user, setUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userBanner, setUserBanner] = useState(user.userBanner?.url || null);
  const [showBannerCropper, setShowBannerCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const currentProfile = sessionStorage.getItem("currentProfile");
  const dispatch = useDispatch();

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate the file type
      const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      if (!allowedTypes.includes(file.type)) {
        toast.error("Please select a valid image file (JPEG, PNG, GIF, WebP).");
        return;
      }

      setSelectedFile(file);
      setShowBannerCropper(true); // Show cropper modal when banner image is selected
    }
  };

  const updateBanner = (croppedFile) => {
    setCroppedFile(croppedFile); // Store the cropped banner file
    const previewUrl = URL.createObjectURL(croppedFile);
    setUserBanner(previewUrl);
    setShowSaveButton(true); // Show save button when a new image is uploaded
  };

  const handleSaveBanner = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("userBanner", croppedFile);

      await axios.put(`${server}/user/update-banner`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          "Current-Profile": currentProfile,
        },
      });
      dispatch(loadUser());
      toast.success("Banner updated successfully!");
      setShowSaveButton(false); // Hide save button after saving
      setUser({ ...user, userBanner: { url: userBanner } });
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update banner");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="user-banner-container">
      <img
        src={userBanner || "default-banner.png"}
        alt="User Banner"
        className="user-banner-image"
      />
      <div className="banner-overlay"></div>
      {isUser && (
        <div className="user-banner-controls">
          <div className="user-banner-edit-icon">
            <input
              type="file"
              id="banner"
              className="user-banner-file-input"
              accept="image/jpeg,image/png,image/gif,image/webp" 
              onChange={handleBannerChange}
            />
            <label htmlFor="banner">
              <AiOutlineCamera className="user-banner-camera-icon" />
            </label>
          </div>
          {showSaveButton && (
            <button
              className="user-banner-save-button"
              onClick={handleSaveBanner}
              disabled={!croppedFile || isLoading}
            >
              Save
            </button>
          )}
        </div>
      )}

      {/* Banner Image Cropper Modal */}
      {showBannerCropper && (
        <ImageCropperModal
          updateAvatar={updateBanner}
          closeModal={() => setShowBannerCropper(false)}
          initialImage={selectedFile}
          aspectRatio={4}
          minWidth={500}
          isAvatar={false}
          isPost={false}
          isLogo={false}
        />
      )}
    </div>
  );
};

export default UserBanner;
