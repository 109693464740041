import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';

const VerificationPersonalData = ({ nextStep, handleChange, formData }) => {
  const [error, setError] = useState('');
  const [takingPhoto, setTakingPhoto] = useState(false);
  const webcamRef = useRef(null);

  // Simple email validation (same as in your existing component)
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNext = () => {
    // Basic checks to ensure required fields are filled out
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.dob) {
      setError('Please fill out all required fields.');
    } else if (!isValidEmail(formData.email)) {
      setError('Please enter a valid email address.');
    } else {
      setError('');
      nextStep();
    }
  };

  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    handleChange('photo')({ target: { value: imageSrc } });
    setTakingPhoto(false);
  };

  return (
    <>
      <Header />
      <Sidebar />
    <div className="membership-personal-data-container">
      <div className="membership-personal-data-content">
        <h2 className="membership-personal-data-title">Verification Info</h2>
        
        {/* Existing fields */}
        <div className="membership-personal-data-field">
          <label>First Name *</label>
          <input
            type="text"
            placeholder="First Name"
            value={formData.firstName}
            onChange={(e) => handleChange('firstName')(e)}
          />
        </div>

        <div className="membership-personal-data-field">
          <label>Last Name *</label>
          <input
            type="text"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={(e) => handleChange('lastName')(e)}
          />
        </div>

        <div className="membership-personal-data-field">
          <label>Email *</label>
          <input
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={(e) => handleChange('email')(e)}
          />
        </div>

        <div className="membership-personal-data-field">
          <label>Date of Birth *</label>
          <input
            type="date"
            value={formData.dob}
            onChange={(e) => handleChange('dob')(e)}
          />
        </div>

        <div className="membership-personal-data-field">
          <label>Photo *</label>
          <button
            onClick={() => setTakingPhoto(true)}
            className="membership-personal-data-button"
          >
            {formData.photo ? 'Retake Selfie' : 'Take Selfie'}
          </button>
          {formData.photo && (
            <img 
              src={formData.photo} 
              alt="Selfie" 
              className="membership-personal-data-photo" 
            />
          )}
        </div>

        {takingPhoto && (
          <div className="membership-personal-data-webcam">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
            <button 
              onClick={capturePhoto} 
              className="membership-personal-data-button"
            >
              Capture
            </button>
          </div>
        )}

        
        
        {/* Display any validation error */}
        {error && <p className="membership-personal-data-error">{error}</p>}

        {/* Next button */}
        <div className="membership-personal-data-buttons">
          <button 
            onClick={handleNext} 
            className="membership-personal-data-button"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default VerificationPersonalData;
