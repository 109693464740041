import React from 'react';
import { AiOutlineGift } from 'react-icons/ai';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { FiPackage, FiShoppingBag } from 'react-icons/fi';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const AdminHeader = () => {
  const { user } = useSelector((state) => state.user);
  
  // 1) Fetch siteLogoUrl from Redux theme slice
  const siteLogoUrl = useSelector((state) => state.theme.theme.siteLogoUrl);
  
  // 2) Provide a fallback if it's not set
  const fallbackLogo = "https://res.cloudinary.com/dzlopmfj8/image/upload/v1706549678/Asset-1_yjnmot.png";
  const logoToUse = siteLogoUrl || fallbackLogo;

  return (
    <div className="admin-header-container">
      {/* Site Logo */}
      <div>
        <Link to="/">
          <img
            src={logoToUse}
            className="admin-header-site-logo"
            alt="Site Logo"
          />
        </Link>
      </div>

      {/* Navigation Icons */}
      <div className="admin-header-nav">
        <Link to="/dashboard-coupons" className="admin-header-nav-item">
          <AiOutlineGift size={30} />
        </Link>
        <Link to="/dashboard-events" className="admin-header-nav-item">
          <MdOutlineLocalOffer size={30} />
        </Link>
        <Link to="/dashboard-products" className="admin-header-nav-item">
          <FiShoppingBag size={30} />
        </Link>
        <Link to="/dashboard-orders" className="admin-header-nav-item">
          <FiPackage size={30} />
        </Link>
        <Link to="/dashboard-messages" className="admin-header-nav-item">
          <BiMessageSquareDetail size={30} />
        </Link>

        {/* User Avatar */}
        {user?.avatar?.url && (
          <img
            src={user.avatar.url}
            alt="Admin Avatar"
            className="admin-header-avatar"
          />
        )}
      </div>
    </div>
  );
};

export default AdminHeader;

