import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../server'; 
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import UserBanner from '../components/Shop/UserBanner';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import UserProfileData from '../components/UserProfileData';
import MyImages from '../components/Content/MyImages';
import MyEvents from '../components/Events/MyEvents';
import MyContentCarousel from '../components/Profile/MyContentCarousel';
import FollowButton from '../components/Connections/FollowButton';
import FollowersDisplay from '../components/Connections/FollowersDisplay';
import MyPostWidgetRedux from '../widgets/MyPostWidgetRedux';
import MixedSponsoredAds from '../components/Sponsored/MixedSponsoredAds';
import RoomsProfile from '../components/Rooms/RoomsProfile';
import UserAvatar from '../components/Layout/UserAvatar';
import FollowingDisplay from '../components/Connections/FollowingDisplay';
import Loader from '../components/Layout/Loader';
import EventsParentContainer from '../components/Events/EventsParentContainer';

const PublicProfilePage = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  // 1) Renamed `id` to `handle`:
  const { handle } = useParams();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // 2) Fetch user by handle instead of by id:
        const response = await axios.get(`${server}/user/user-info/handle/${handle}`);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [handle]);

  if (isLoading) {
    return <Loader />;
  }

  if (!user) {
    return <div>User data not available</div>;
  }

  // 3) Use `user._id` for child components
  const userId = user._id;

  return (
    <div className="profile-page">
      <Header />
      <Sidebar />
      <div className="profile-banner-wrapper">
        <UserBanner isUser={false} user={user} setUser={setUser} />
        <div className="profile-info-container">
          <div className="profile-avatar-wrapper">
            <UserAvatar isUser={false} user={user} setUser={setUser} />
            <div className="profile-info">
              <div className="profile-name-wrapper">
                <h3 className="profile-name">{user.name}</h3>
                <h3 className="profile-handle">@{user.handle}</h3>
                
                {/* Pass the actual Mongo _id to FollowButton */}
                <FollowButton
                  className="profile-followers-add-button"
                  connectionId={userId}
                  connectionType="User"
                />
                
                <div className="profile-friends">
                  {/* Same here: pass userId as `_id` */}
                  <FollowersDisplay userId={userId} profileType="User" />
                  <FollowingDisplay userId={userId} profileType="User" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      
      <div className="profile-main-content">
        <div className="profile-sidebar-left">
          <RoomsProfile profileId={userId}  profileType="User" />
          <MyImages userId={userId} />
          <EventsParentContainer ownerId={userId} />
        </div>
        
        <main className="profile-main-area">
          <MyPostWidgetRedux />
          <UserProfileData />
        </main>
        
        <aside className="profile-sidebar-right">
          <MyContentCarousel ownerId={userId}  profileType="User"/>
          <MixedSponsoredAds />
        </aside>
      </div>
    </div>
  );
};

export default PublicProfilePage;


