import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { server } from "../../server";
import ImageCropperModal from '../Image/ImageCropperModal';
import { Rnd } from 'react-rnd';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MemeCreator() {
  const [text, setText] = useState('Your Text Here');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State variables for styling
  const [fontSize, setFontSize] = useState(40);
  const [fontColor, setFontColor] = useState('#ffffff');
  const [fontFamily, setFontFamily] = useState('Impact');
  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });
  const [textSize, setTextSize] = useState({ width: 200, height: 100 });

  // New state variables for initial sizes
  const [initialTextSize, setInitialTextSize] = useState({ width: 200, height: 100 });
  const [initialFontSize, setInitialFontSize] = useState(fontSize);

  // References for the text div and image
  const textRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const { offsetHeight } = textRef.current;
      setTextSize((prevSize) => ({
        ...prevSize,
        height: offsetHeight,
      }));
    }
  }, [text, fontSize, fontFamily]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setShowImageCropper(true);
    }
  };

  const updateImage = (croppedFile) => {
    setCroppedImage(croppedFile);
    const previewUrl = URL.createObjectURL(croppedFile);
    setPreviewUrl(previewUrl);
  };

  const handleTextInput = () => {
    if (textRef.current) {
      const caretPos = window.getSelection().getRangeAt(0).startOffset;
      setText(textRef.current.innerText);

      // Restore caret position
      setTimeout(() => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.setStart(textRef.current.childNodes[0], caretPos);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }, 0);
    }
  };

  const handleSubmit = async () => {
    if (!croppedImage || !text) {
      setError('Please upload an image and add text.');
      return;
    }

    // Get image dimensions
    const imageWidth = imageRef.current ? imageRef.current.offsetWidth : 1;
    const imageHeight = imageRef.current ? imageRef.current.offsetHeight : 1;

    // Calculate percentages
    const textPositionXPercent = (textPosition.x / imageWidth) * 100;
    const textPositionYPercent = (textPosition.y / imageHeight) * 100;
    const textWidthPercent = (textSize.width / imageWidth) * 100;
    const textHeightPercent = (textSize.height / imageHeight) * 100;
    const fontSizePercent = (fontSize / imageWidth) * 100; // Adjust if needed

    const formData = new FormData();
    formData.append('image', croppedImage);
    formData.append('overlayText', text);
    formData.append('fontSize', fontSizePercent);
    formData.append('fontColor', fontColor);
    formData.append('fontFamily', fontFamily);
    formData.append('textPositionX', textPositionXPercent);
    formData.append('textPositionY', textPositionYPercent);
    formData.append('textWidth', textWidthPercent);
    formData.append('textHeight', textHeightPercent);

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${server}/meme/create-meme`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'current-profile': sessionStorage.getItem('currentProfile') || 'User',
        },
      });
      console.log('Meme created:', response.data);
      setLoading(false);
      toast.success('Meme created successfully!');
      setShowImageCropper(false)
    } catch (error) {
      console.error('Error creating meme:', error);
      setLoading(false);
      setError('Failed to create meme. Please try again.');
    }
  };

  return (
    <div className="meme-creator">
       <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
      <h2>Create Your Meme</h2>
      {error && <div className="meme-error-message">{error}</div>}
      <div className="meme-upload-section">
        <label htmlFor="image-upload" className="meme-upload-label">
          <FontAwesomeIcon icon={faUpload} /> Upload Image
        </label>
        <input
          id="image-upload"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: 'none' }}
        />
      </div>

      {/* Styling Controls */}
      <div className="meme-styling-controls">
        <label>
          Font Color:
          <input
            type="color"
            value={fontColor}
            onChange={(e) => setFontColor(e.target.value)}
          />
        </label>
        <label>
          Font Family:
          <select
            value={fontFamily}
            onChange={(e) => setFontFamily(e.target.value)}
          >
            <option value="Impact">Impact</option>
            <option value="Arial">Arial</option>
            <option value="Comic Sans MS">Comic Sans</option>
            <option value="Courier New">Courier New</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Georgia">Georgia</option>
            <option value="Verdana">Verdana</option>
            <option value="Tahoma">Tahoma</option>
            <option value="Trebuchet MS">Trebuchet MS</option>
            <option value="Lucida Console">Lucida Console</option>
            <option value="Gill Sans">Gill Sans</option>
            <option value="Roboto">Roboto</option>
          </select>
        </label>
      </div>

      {previewUrl && (
        <div className="meme-preview" style={{ position: 'relative' }}>
          <img
            ref={imageRef}
            src={previewUrl}
            alt="Meme Preview"
            className="meme-preview-image"
          />

          {/* Wrapping div to set bounds */}
          <div
            style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <Rnd
              size={{ width: textSize.width, height: textSize.height }}
              position={{ x: textPosition.x, y: textPosition.y }}
              onDragStop={(e, d) => {
                setTextPosition({ x: d.x, y: d.y });
              }}
              onResizeStart={(e, dir, ref) => {
                setInitialTextSize({
                  width: parseInt(ref.style.width),
                  height: parseInt(ref.style.height),
                });
                setInitialFontSize(fontSize);
              }}
              onResize={(e, direction, ref, delta, position) => {
                const newWidth = parseInt(ref.style.width);
                const newHeight = parseInt(ref.style.height);

                // Calculate the area ratio
                const areaRatio =
                  (newWidth * newHeight) /
                  (initialTextSize.width * initialTextSize.height);

                // Adjust font size based on area ratio
                const adjustedFontSize = initialFontSize * Math.sqrt(areaRatio);

                setFontSize(adjustedFontSize);
                setTextSize({
                  width: newWidth,
                  height: newHeight,
                });
                setTextPosition(position);
              }}
              bounds="parent"
              enableResizing={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: true,
                bottomRight: true,
                bottomLeft: true,
                topLeft: true,
              }}
              style={{
                boxSizing: 'border-box',
                padding: 0,
                margin: 0,
              }}
            >
              <div
                ref={textRef}
                contentEditable
                suppressContentEditableWarning
                onInput={handleTextInput}
                style={{
                  fontSize: `${fontSize}px`,
                  color: fontColor,
                  fontFamily: fontFamily,
                  lineHeight: '1',
                  padding: '0',
                  margin: '0',
                  boxSizing: 'border-box',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  border: '2px dotted var(--primary-color)',
                  cursor: 'move',
                  outline: 'none',
                }}
              >
                {text}
              </div>
            </Rnd>
          </div>
        </div>
      )}
      <button
        onClick={handleSubmit}
        disabled={loading}
        className="meme-submit-button"
      >
        {loading ? 'Creating Meme...' : 'Create Meme'}
      </button>

      {/* Image Cropper Modal */}
      {showImageCropper && (
        <ImageCropperModal
          updateAvatar={updateImage}
          closeModal={() => setShowImageCropper(false)}
          initialImage={selectedFile}
          aspectRatio={1}
          minWidth={200}
          isAvatar={false}
          isPost={false}
          isLogo={false}
        />
      )}
    </div>
  );
}

export default MemeCreator;
