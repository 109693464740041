import React from 'react';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { server } from "../../server";
import axios from "axios";

const VerificationFinish = ({ formData, prevStep }) => {
    const currentProfile = sessionStorage.getItem('currentProfile');
    const handleSubmit = async () => {
        try {
          const response = await axios.post(`${server}/verification`, {
            currentProfile,
            ...formData,
          }, {
            withCredentials: true,
            headers: {
              'current-profile': currentProfile, 
            }
          });
      
          console.log('Verification saved:', response.data);
          alert('Verification data submitted successfully!');
        } catch (error) {
          console.error('Error submitting verification:', error);
          alert('Failed to submit verification data.');
        }
      };

  return (
    <>
      <Header />
      <Sidebar />
    <div>
      <h2>Review and Submit</h2>
      <p>Please verify your information before submitting:</p>
      
      <ul>
        <li><b>Name:</b> {formData.firstName} {formData.lastName}</li>
        <li><b>Email:</b> {formData.email}</li>
        <li><b>DOB:</b> {formData.dob}</li>
        <li><b>Instagram:</b> {formData.instagram}</li>
        <li><b>Twitter:</b> {formData.twitter}</li>
        <li><b>Facebook:</b> {formData.facebook}</li>
        <li><b>LinkedIn:</b> {formData.linkedin}</li>
        <li><b>TikTok:</b> {formData.tiktok}</li>
        <li><b>YouTube:</b> {formData.youtube}</li>
        {/* Add additional fields as needed */}
      </ul>

      {formData.photo && (
        <div>
          <b>Selfie:</b>
          <img src={formData.photo} alt="Selfie" style={{ maxWidth: '200px', display: 'block' }} />
        </div>
      )}

      <div>
        <button onClick={prevStep}>Back</button>
        <button onClick={handleSubmit}>Submit Verification</button>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default VerificationFinish;
