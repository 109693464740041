import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import debounce from "lodash.debounce";
import axios from "axios";
import { server } from "../../server";

const MobileSearchBar = ({ onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [shops, setShops] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const searchBoxRef = useRef(null);
  const searchCache = useRef({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Stop scrolling
    return () => {
      document.body.style.overflow = "auto"; // Restore scrolling
    };
  }, []);

  const performSearch = useCallback(
    debounce(async (term) => {
      if (term.trim() === "") {
        setProducts([]);
        setUsers([]);
        setShops([]);
        setDropdownVisible(false);
        return;
      }

      if (searchCache.current[term]) {
        const cachedData = searchCache.current[term];
        setProducts(cachedData.products);
        setUsers(cachedData.users);
        setShops(cachedData.shops);
        setDropdownVisible(true);
        return;
      }

      try {
        const response = await axios.get(`${server}/search/search`, {
          params: { q: term },
        });

        if (response.data.success) {
          const { products, users, shops } = response.data;
          searchCache.current[term] = { products, users, shops };
          setProducts(products);
          setUsers(users);
          setShops(shops);
          setDropdownVisible(true);
        } else {
          setProducts([]);
          setUsers([]);
          setShops([]);
          setDropdownVisible(false);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        setProducts([]);
        setUsers([]);
        setShops([]);
        setDropdownVisible(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    performSearch(searchTerm);
    return performSearch.cancel;
  }, [searchTerm, performSearch]);

  useEffect(() => {
    clearSearch();
  }, [location.pathname]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setProducts([]);
    setUsers([]);
    setShops([]);
    setDropdownVisible(false);
  };

  const handleNavigate = (path) => {
    console.log("Navigating to:", path);
    clearSearch();
    navigate(path);
    if (onClose) onClose(); // Close the search bar on navigation
  };

  return (
    <div className="mobile-search-container">
      <div className="mobile-search-header">
        <div className="mobile-search-input-wrapper" ref={searchBoxRef}>
          <AiOutlineSearch size={20} className="mobile-search-icon" />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleChange}
            className="mobile-search-input"
            aria-label="Search Products, Users, Shops"
            onFocus={() => {
              if (
                searchTerm.trim() !== "" &&
                (products.length > 0 || users.length > 0 || shops.length > 0)
              ) {
                setDropdownVisible(true);
              }
            }}
          />
          {searchTerm && (
            <AiOutlineClose
              size={18}
              className="mobile-search-clear-icon"
              onClick={clearSearch}
            />
          )}
        </div>
      </div>

      {isDropdownVisible && (
        <div className="mobile-search-results">
          {products.length > 0 && (
            <div className="mobile-search-section">
              <h2 className="mobile-search-section-title">Products</h2>
              {products.map((product) => (
                <div
                  key={product._id}
                  className="mobile-search-item"
                  onClick={() => handleNavigate(`/product/${product._id}`)}
                >
                  <img
                    src={`${product.images[0]?.url}`}
                    alt={product.name}
                    className="mobile-search-item-img"
                    loading="lazy"
                  />
                  <div className="mobile-search-item-info">
                    <h1 className="mobile-search-item-title">{product.name}</h1>
                    <div className="mobile-search-item-details">
                      <span className="mobile-search-item-brand">
                        Brand: {product.brand}
                      </span>
                      <span className="mobile-search-item-shop">
                        Shop: {product.shop.name}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {users.length > 0 && (
            <div className="mobile-search-section">
              <h2 className="mobile-search-section-title">Users</h2>
              {users.map((user) => (
                <div
                  key={user._id}
                  className="mobile-search-item"
                  onClick={() => handleNavigate(`/profile/${user?.handle}`)}
                >
                  <img
                    src={user.avatar?.url || "https://via.placeholder.com/50"}
                    alt={user.name}
                    className="mobile-search-item-img"
                    loading="lazy"
                  />
                  <div className="mobile-search-item-info">
                    <h1 className="mobile-search-item-title">{user.name}</h1>
                  </div>
                </div>
              ))}
            </div>
          )}

          {shops.length > 0 && (
            <div className="mobile-search-section">
              <h2 className="mobile-search-section-title">Shops</h2>
              {shops.map((shop) => (
                <div
                  key={shop._id}
                  className="mobile-search-item"
                  onClick={() => handleNavigate(`/shop/${shop.handle}`)}
                >
                  <img
                    src={shop.avatar?.url || "https://via.placeholder.com/50"}
                    alt={shop.name}
                    className="mobile-search-item-img"
                    loading="lazy"
                  />
                  <div className="mobile-search-item-info">
                    <h1 className="mobile-search-item-title">{shop.name}</h1>
                  </div>
                </div>
              ))}
            </div>
          )}

          {products.length === 0 &&
            users.length === 0 &&
            shops.length === 0 &&
            searchTerm.trim() !== "" && (
              <div className="mobile-search-no-results">No results found.</div>
            )}
        </div>
      )}
    </div>
  );
};

export default MobileSearchBar;

