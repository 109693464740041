import React, { useState, useRef } from 'react';
import axios from 'axios';
import { server } from "../../server";
import { useSelector } from "react-redux";
import CustomCalendar from './CustomCalendar';
import ImageCropperModal from '../Image/ImageCropperModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

const EventCreationForm = () => {
  const currentProfile = useSelector((state) => state.user.currentProfile);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    image: null,
    date: new Date(),
    endDate: new Date(),
    location: '',
    startTime: '',
    endTime: '',
  });

  // State for Google Autocomplete
  const [autocomplete, setAutocomplete] = useState(null);

  // State variables for Image Cropper Modal
  const [selectedFile, setSelectedFile] = useState(null);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  // Loading and error handling
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /** Handle text/input changes */
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      const file = files[0];
      if (file) {
        setSelectedFile(file);
        setShowImageCropper(true);
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  /** Update the start date */
  const handleDateChange = (date) => {
    setFormData((prev) => ({ ...prev, date }));
  };

  /** Update the end date */
  const handleEndDateChange = (endDate) => {
    setFormData((prev) => ({ ...prev, endDate }));
  };

  /** Callback to receive cropped image */
  const updateCroppedImage = (croppedFile) => {
    setFormData((prev) => ({ ...prev, image: croppedFile }));
    setPreviewUrl(URL.createObjectURL(croppedFile));
    setShowImageCropper(false);
  };

  /** Google Autocomplete handlers **/
  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      if (!place || !place.address_components) {
        toast.error("No address details available.");
        return;
      }
      if (place.formatted_address) {
        setFormData((prev) => ({
          ...prev,
          location: place.formatted_address,
        }));
      }

    } else {
      toast.error("Autocomplete is not loaded yet!");
    }
  };

  /** Example parse function if you need more granular data (city, state, etc.) */
  const parseAddressComponents = (components) => {
    let streetAddress = "";
    let city = "";
    let state = "";
    let zipCode = "";
    let country = "";

    components.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number")) {
        streetAddress = component.long_name + " ";
      }
      if (types.includes("route")) {
        streetAddress += component.long_name;
      }
      if (types.includes("locality")) {
        city = component.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        state = component.short_name;
      }
      if (types.includes("postal_code")) {
        zipCode = component.long_name;
      }
      if (types.includes("country")) {
        country = component.short_name;
      }
    });

    return { streetAddress, city, state, zipCode, country };
  };

  /** Form submission handler */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Basic front-end validation
    if (
      !formData.title ||
      !formData.description ||
      !formData.image ||
      !formData.location ||
      !formData.startTime
    ) {
      setError('Please fill in all required fields.');
      setLoading(false);
      return;
    }

    // Ensure start date is before end date
    if (new Date(formData.endDate) < new Date(formData.date)) {
      setError('End date cannot be before start date.');
      setLoading(false);
      return;
    }

    const data = new FormData();
    data.append('title', formData.title);
    data.append('description', formData.description);
    data.append('image', formData.image);
    data.append('date', formData.date.toISOString());
    data.append('endDate', formData.endDate.toISOString());
    data.append('location', formData.location);
    data.append('startTime', formData.startTime);
    data.append('endTime', formData.endTime);
    data.append('currentProfile', currentProfile);

    try {
      const response = await axios.post(`${server}/event/create`, data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Event created successfully!');
      // Reset form
      setFormData({
        title: '',
        description: '',
        image: null,
        date: new Date(),
        endDate: new Date(),
        location: '',
        startTime: '',
        endTime: '',
      });
      setPreviewUrl(null);
    } catch (error) {
      console.error('Error creating event:', error);
      setError(
        error.response?.data?.message ||
          'Failed to create event. Please try again.'
      );
      toast.error(
        `Failed to create event: ${
          error.response?.data?.message || 'Unknown error'
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="event-creation-form-container">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />

      <form onSubmit={handleSubmit} className="event-creation-form-form">
        <div className="form-group">
          <label htmlFor="title" className="event-creation-form-label">
            Event Title <span className="required-marker">*</span>
          </label>
          <input
            type="text"
            name="title"
            id="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Event Title"
            required
            className="event-creation-form-input"
          />
        </div>

        <div className="form-group">
          <label htmlFor="description" className="event-creation-form-label">
            Event Description <span className="required-marker">*</span>
          </label>
          <textarea
            name="description"
            id="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Event Description"
            required
            className="event-creation-form-textarea"
          />
        </div>

        <div className="form-group">
          <label htmlFor="image" className="event-creation-form-label">
            Event Image <span className="required-marker">*</span>
          </label>
          <label htmlFor="image-upload" className="event-upload-label">
            <FontAwesomeIcon icon={faUpload} /> Upload Image
          </label>
          <input
            id="image-upload"
            type="file"
            name="image"
            accept="image/*"
            onChange={handleChange}
            style={{ display: 'none' }}
            required
          />
        </div>

        {/* Optional: Display Image Preview */}
        {previewUrl && (
          <div className="image-preview">
            <img src={previewUrl} alt="Cropped Preview" className="preview-image" />
          </div>
        )}

        <div className="form-group">
          <label htmlFor="location" className="event-creation-form-label">
            Event Location <span className="required-marker">*</span>
          </label>

          {/* Wrap Autocomplete inside LoadScript */}
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={['places']}
          >
            <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceChanged}>
              <input
                type="text"
                name="location"
                id="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="Type or select an address..."
                required
                className="event-creation-form-input"
              />
            </Autocomplete>
          </LoadScript>
        </div>

        <div className="event-creation-form-date-time-row">
          <div className="date-time-group">
            <label htmlFor="date" className="event-creation-form-label">
              Event Start Date <span className="required-marker">*</span>
            </label>
            <CustomCalendar 
              selectedDate={formData.date} 
              onDateChange={handleDateChange} 
            />

            <label htmlFor="startTime" className="event-creation-form-label">
              Event Start Time <span className="required-marker">*</span>
            </label>
            <input
              type="time"
              name="startTime"
              id="startTime"
              value={formData.startTime}
              onChange={handleChange}
              required
              className="event-creation-form-input time-input"
            />
          </div>

          <div className="date-time-group">
            <label htmlFor="endDate" className="event-creation-form-label">
              Event End Date
            </label>
            <CustomCalendar
              selectedDate={formData.endDate}
              onDateChange={handleEndDateChange}
            />

            <label htmlFor="endTime" className="event-creation-form-label">
              Event End Time
            </label>
            <input
              type="time"
              name="endTime"
              id="endTime"
              value={formData.endTime}
              onChange={handleChange}
              className="event-creation-form-input time-input"
            />
          </div>
        </div>

        {error && <div className="event-error-message">{error}</div>}

        <button
          type="submit"
          className="event-creation-form-button"
          disabled={loading}
        >
          {loading ? 'Creating Event...' : 'Create Event'}
        </button>
      </form>

      {/* Image Cropper Modal */}
      {showImageCropper && (
        <ImageCropperModal
          updateAvatar={updateCroppedImage}
          closeModal={() => setShowImageCropper(false)}
          initialImage={selectedFile}
          aspectRatio={1}
          minWidth={320}
          isAvatar={false}
          isPost={false}
          isLogo={false}
        />
      )}
    </div>
  );
};

export default EventCreationForm;





