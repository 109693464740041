import React from "react";
import { FiShoppingBag } from "react-icons/fi";
import { GrWorkshop } from "react-icons/gr";
import { RxDashboard } from "react-icons/rx";
import { CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";
import { HiOutlineUserGroup, HiOutlineExclamationCircle } from "react-icons/hi";
import { BsHandbag } from "react-icons/bs";
import { MdOutlineLocalOffer, MdOutlineAttachMoney, MdOutlineSubscriptions, MdOutlineAdsClick, MdLabelOutline } from "react-icons/md";
import { AiOutlineSetting, AiOutlineBgColors } from "react-icons/ai";

const AdminSideBar = ({ active }) => {
  return (
    <div className="admin-sidebar-container">
      <div className="admin-sidebar-menu">
        <SidebarItem to="/admin/dashboard" icon={<RxDashboard size={30} />} text="Dashboard" active={active === 1} />
        <SidebarItem to="/admin-orders" icon={<FiShoppingBag size={30} />} text="All Orders" active={active === 2} />
        <SidebarItem to="/admin-sellers" icon={<GrWorkshop size={30} />} text="All Sellers" active={active === 3} />
        <SidebarItem to="/admin-users" icon={<HiOutlineUserGroup size={30} />} text="All Users" active={active === 4} />
        <SidebarItem to="/admin-products" icon={<BsHandbag size={30} />} text="All Products" active={active === 5} />
        <SidebarItem to="/admin-events" icon={<MdOutlineLocalOffer size={30} />} text="All Events" active={active === 6} />
        <SidebarItem to="/admin-withdraw-request" icon={<MdOutlineAttachMoney size={30} />} text="Withdraw Request" active={active === 7} />
        <SidebarItem to="/profile" icon={<AiOutlineSetting size={30} />} text="Settings" active={active === 8} />
        <SidebarItem to="/admin-ads" icon={<MdOutlineAdsClick size={30} />} text="AD Center" active={active === 9} />
        <SidebarItem to="/admin-tags" icon={<MdLabelOutline size={30} />} text="Tag Manager" active={active === 10} />
        <SidebarItem to="/admin-reports" icon={<HiOutlineExclamationCircle size={30} />} text="Post Report Manager" active={active === 11} />
        <SidebarItem to="/admin-subscriptions" icon={<MdOutlineSubscriptions size={30} />} text="Subscriptions" active={active === 12} />
        <SidebarItem to="/admin/theme" icon={<AiOutlineBgColors size={30} />} text="Theme Manager" active={active === 13} />
      </div>
    </div>
  );
};

// Reusable Sidebar Item Component
const SidebarItem = ({ to, icon, text, active }) => {
  return (
    <div className={`admin-sidebar-item ${active ? "admin-sidebar-active" : ""}`}>
      <Link to={to} className="admin-sidebar-link">
        {icon}
        <h5 className="admin-sidebar-text">{text}</h5>
      </Link>
    </div>
  );
};

export default AdminSideBar;

